import React from "react";
// import useStyles from "./styles";
import { Route, Routes } from "react-router";
import NotFound from "../NotFound/NotFound";
import BoothAdd from "./components/BoothAdd/BoothAdd";
import BoothsList from "./components/BoothsList/BoothsList";
import Chat from "./components/Chat/Chat";

export default function Booths(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<BoothsList {...props} />} />
      <Route path={"add"} element={<BoothAdd {...props} />} />
      <Route path={"edit/:id"} element={<BoothAdd {...props} />} />
      <Route path={"chat/:id"} element={<Chat {...props} />} />
    </Routes>
  );
}

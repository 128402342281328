import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  menuBtn: {
    width: 200,
    paddingRight: "38px !important",
    [theme.breakpoints.down("lg")]: {
      width: 100,
      paddingRight: "0 !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "auto",
      paddingRight: "0 !important",
    },
  },
  nameWrapper: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: 14,
    [theme.breakpoints.down("lg")]: {
      "& svg": {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  icon: {
    position: "absolute",
    right: 8,
    left: "unset !important",
    top: "calc(50% - 12px)",
  },
  menu: {
    boxShadow: "none !important",
    borderRadius: "0 0 10px 10px !important",
    backgroundColor: "#FFFFFF99 !important",
    backdropFilter: "blur(10px)",
    top: "54px !important",
    width: 200,
    [theme.breakpoints.down("lg")]: {
      width: 100,
    },
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

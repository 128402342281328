import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loadingSlice from "../LoadingSlice";
import AuthSlice from "../AuthSlice";
import ProgressBarSlice from "../ProgressBarSlice";
import PersianDatepickerSlice from "../PersianDatepicker";
import AlertsSlice from "../AlertsSlice";
import ConstantsSlice from "../ConstantsSlice";
import ThemeSlice from "../ThemeSlice";
import DirSlice from "../DirSlice";
import ToolbarSlice from "../ToolbarSlice";
import TableSlice from "../TableSlice";
import ExhibitionsSlice from "../ExhibitionsSlice";
import ModalSlice from "../ModalSlice";
import SelectedFileSlice from "../SelectedFileSlice";
import Roles from "../RolesSlice";
import Chat from "../ChatSlice";
import DrawerMenu from "../DrawerMenuSlice";

const reducer = combineReducers({
  loading: loadingSlice,
  auth: AuthSlice,
  progressBar: ProgressBarSlice,
  persianDatepicker: PersianDatepickerSlice,
  alerts: AlertsSlice,
  constants: ConstantsSlice,
  theme: ThemeSlice,
  dir: DirSlice,
  toolbar: ToolbarSlice,
  table: TableSlice,
  exhibitions: ExhibitionsSlice,
  modal: ModalSlice,
  file: SelectedFileSlice,
  roles: Roles,
  chat: Chat,
  drawerMenu: DrawerMenu,
});
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === "development",
});

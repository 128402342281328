import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  mapWrapper: {
    display: "flex",
    gap: 75,
    paddingTop: 20,
    flexGrow: 1,
    flexDirection: "column",
    minWidth: 300,
  },
  mapInfo: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    gap: 60,
    [theme.breakpoints.down("lg")]: {
      gap: 6,
      flexDirection: "column",
    },
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  infoColor: {
    width: 18,
    height: 18,
    borderRadius: 20,
  },
  infoTitle: {
    fontSize: 16,
  },
  map: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    gap: 30,
  },
  mapIntro: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    gap: 18,
  },
  Card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    padding: "0 10px",
    width: 110,
    height: 120,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down("lg")]: {
      width: 50,
      borderRadius: 8,
      height: 70,
    },
  },
  mapBody: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    flexDirection: "column",
    gap: 30,
    flexWrap: "wrap",
    [theme.breakpoints.down("lg")]: {
      gap: 8,
    },
  },
  mapSection: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
    gap: 85,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      gap: 14,
    },
    [theme.breakpoints.down("sm")]: {
      gap: 1,
    },
  },
  mapCell: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: 250,
    height: 270,
    gap: 30,
    [theme.breakpoints.down("lg")]: {
      gap: 8,
      width: 108,
      height: 148,
    },
    [theme.breakpoints.down("sm")]: {
      gap: 1,
      width: 113,
      height: 148,
    },
  },
  noDataWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: 6,
  },
  title: {
    fontSize: 18,
    color: theme.palette.description.main,
    textAlign: "center",
  },
}));

import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({

    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.tableBgColor.main,
        flexGrow: 1,
        padding: "15px 20px",
        borderRadius: 20,
        position: "relative",
        gap: 15,
        maxWidth: 334,
        minHeight:260,
        [theme.breakpoints.down("lg")]: {
            maxWidth:'100%'
        }
    },
    addIconWrapper: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    addIcon: {
        width: 48,
        height: 48,
        "& > path": {
            stroke: theme.palette.description.main,
        }
    },
    addTitle: {
        color: theme.palette.description.main,
        fontSize: 14
    },
    active: {
        display: "flex",
        flexDirection: "row",
        gap: 5,
        alignItems: "center",
    },
    describeWrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: 5,
        justifyContent: "space-between",
    },
    describe: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "space-between",
    },
    describeTitle: {
        fontSize: 14,
        color: theme.palette.description.main
    },
    activeDot: {
        borderRadius: "100%",
        backgroundColor: theme.palette.success.main,
        width: 10,
        height: 10
    },
    notActiveDot: {
        borderRadius: "100%",
        backgroundColor: theme.palette.error.main,
        width: 10,
        height: 10
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    name: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "center",
        fontWeight: 600,
        fontSize: 16,
        maxWidth: 252
    },
    btnWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
    },
    btnLink: {
        display: "flex",
        flexDirection: "column",
    },
      disabled:{
        backgroundColor: `${theme.palette.description.main} !important`,
        color: `${theme.palette.description.contrastText} !important`,
      }
}));

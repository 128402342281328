import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    overflow: 'auto',
    "&>img": {
      width: "95%",
      margin: "auto",
      display: "block",
      height: "100%",
      minWidth: 800,
    }
  },
}));

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.tableBgColor.main,
    borderRadius: "20px !important",
    marginBottom: 3,
  },
  cell: {
    border: "none !important",
    outline: "none !important",
  },
  tabelWrapper: {
    height: "calc(100vh - 193px)",
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      width: "95vw",
      overflow: "scroll",
    },
    [theme.breakpoints.down("md")]: {
      width: "90vw",
    },
  },
  root: {
    border: "none !important",
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
    {
      outline: "none",
    },

    [theme.breakpoints.down("lg")]: {
      width: "991px",
    },
  },
  columnHeaders: {
    backgroundColor: theme.palette.tableBgColor.main,
    borderRadius: "15px !important",
  },
  columnHeader: {
    outline: "none !important",
  },
  virtualScroller: {
    marginTop: "70px !important",
    overflow: "scroll",
  },
  paginationWrapper: {
    width: "100%",
    paddingTop: 10,
  },
  footerContainer: {
    display: "none !important",
  },
  cellBtnWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  cellBtn: {
    alignItems: "center",
    gap: 20,
    justifyContent: "center !important",
  },
  search: {
    "&::before": {
      backgroundColor: "#E4E7EC",
      width: 1,
      content: "''",
      height: "100%",
      position: "absolute",
      zIndex: 2,
      left: 0,
    },
  },
  searchRtl: {
    "&::before": {
      backgroundColor: "#E4E7EC",
      width: 1,
      content: "''",
      height: "100%",
      position: "absolute",
      zIndex: 2,
      right: 0,
    },
  },
  columnSeparator: {
    display: "none !important",
  },
  deleteDialog: {
    alignItems: "center",
  },
  content: {
    "& > span ": {
      backgroundColor: theme.palette.tableBgColor.main,
      borderRadius: 15,
    },
  },
  columnHeaderTitle: {
    display: "none",
  },
  columnHeaderTitleContainer: {
    "& > div > button": {
      display: "none",
    },
  },
  selectedMenu: {
    height: 12,
    display: "flex",
    width: "fit-content",
    position: "absolute",
    minHeight: 56,
    maxHeight: 56,
    left: 66,
    fontSize: 14,
    alignItems: "center",
    gap: 12,
    zIndex: 10,
  },
  selectedMenuRtl: {
    left: "unset",
    right: 66
  },
  selectedDelete: {
    color: "#E31B54",
    fontSize: 14,
  },
  selectedNum: {
    fontSize: 13,
    color: "#475467CC",
  },
}));

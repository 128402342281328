import React, { useEffect } from "react";
import FileManager from "../../components/FileManager/FileManager";
import { setToolbar } from "../../redux/ToolbarSlice";
import { useDispatch } from "react-redux";
import useCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";

export default function Files(props) {
  const dispatch = useDispatch();
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Files",
        title: { name: tv("files") },
        leftElements: [
          { type: "AdvanceSearch" },
          {
            type: "UploadBtn",
            name: tv("filesBtn"),
          },
        ],
        breadcrumbs: [{ name: tvCrumb('home'), href: "/" }, { name: tvCrumb('files') }],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  return <FileManager />;
}

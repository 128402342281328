import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../../assets/icons/Icons";
import { showModal } from "../../../redux/ModalSlice";
import useStyles from "./styles";
import Image from "../../../assets/images/default.jpg";
// import Viza from "../../../assets/images/payment/Visa_Inc._logo 1.png";
// import Master from "../../../assets/images/payment/MasterCard_Logo 1.png";
// import PayPal from "../../../assets/images/payment/2560px-PayPal_logo 1.png";
import Zarinpal from "../../../assets/images/payment/zarinpal.png";
import Stripe from "../../../assets/images/payment/stripe.png";
import { MainUrl } from "../../../services/Api";
import useBooth from "../../../customHooks/hookRepositories/useBooths";
import { useTranslation } from "react-i18next";
import UseCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import getSymbolFromCurrency from "currency-symbol-map";

export default function BoothBuyModal(props) {
  //region--------------- states
  var classes = useStyles();
  const tvp = UseCustomTranslate("pages");
  const trv = UseCustomTranslate("BoothBuy");
  const [selectedPay, setSelectedPay] = useState("zarinpal");
  const { open, info: { booth: { price, _id } = {}, design, currency } = {} } =
    useSelector((state) => state.modal);
  const { catalogue, index, photoBoard, cover, stand, tv } = design
    ? design
    : {};
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(showModal({ open: false }));
  };
  //endregion-------------- handlers
  const [payment, setPayment] = useState([]);
  useEffect(() => {
    if (currency) {
      if (currency === "IRR") {
        setPayment([{ id: "zarinpal", src: Zarinpal }]);
        setSelectedPay("zarinpal");
      } else {
        setPayment([{ id: "stripe", src: Stripe }]);
        setSelectedPay("stripe");
      }
    }
  }, [currency]);
  const { buyBooth } = useBooth();
  const payHandler = async () => {
    await buyBooth(_id, selectedPay);
  };
  const { t } = useTranslation();
  const isRtl = t("dir") === "rtl";
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open === "BoothBuyModal"}
        onClose={handleClose}
        PaperComponent={PaperComponent}
      >
        <div className={classes.modalWrapper}>
          <div
            className={`${classes.descriptionWrapper} ${isRtl ? classes.descriptionWrapperRtl : ""
              }`}
          >
            <div className={classes.imageWrapper}>
              <div className={classes.image}>
                <img src={cover ? MainUrl + cover : Image} alt="" />
              </div>
            </div>
            <div className={classes.description}>
              <div className={classes.des}>
                <div className={classes.desTitle}>{trv("catalog")}:</div>
                <div className={classes.desValue}>
                  {catalogue} {trv("catalogs")}
                </div>
              </div>
              <div className={classes.des}>
                <div className={classes.desTitle}>{trv("boothTV")}:</div>
                <div className={classes.desValue}>
                  {tv ? `${trv("yes")} - ${tv} ${trv("tvs")}` : trv("no")}
                </div>
              </div>
              <div className={classes.des}>
                <div className={classes.desTitle}>{trv("standBanner")}:</div>
                <div className={classes.desValue}>
                  {stand
                    ? `${trv("yes")} - ${stand} ${trv("standBanners")}`
                    : trv("no")}
                </div>
              </div>
              <div className={classes.des}>
                <div className={classes.desTitle}>{trv("photoBoard")}:</div>
                <div className={classes.desValue}>
                  {photoBoard
                    ? `${trv("yes")} - ${photoBoard} ${trv("photoBoards")}`
                    : trv("no")}
                </div>
              </div>
              <div className={classes.des}>
                <div className={classes.desTitle}>{trv("brochure")}:</div>
                <div className={classes.desValue}>{trv("unlimited")}</div>
              </div>
            </div>
          </div>
          <div className={classes.payWrapper}>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {trv("payment")}
              <Icons.Close onClick={handleClose} className={classes.close} />
            </DialogTitle>
            <DialogContent
              sx={{ pt: 40, display: "flex", flexDirection: "column" }}
            >
              <div className={classes.nameWrapper}>
                <span className={classes.name}>{trv("boothNumber")}:</span>
                <span className={classes.nameValue}>
                  {trv("booth")} {index}
                </span>
              </div>
              <div className={classes.priceWrapper}>
                <span className={classes.priceTitle}>{trv("price")}:</span>
                <span className={classes.price}>
                  <span className={classes.currencySymbol}>
                    {price ? getSymbolFromCurrency(currency) : ""}
                  </span>{" "}
                  {price ? price?.toLocaleString() : tvp("free")}
                </span>
              </div>
              {price ? (
                <>
                  <div>{trv("selectPaymentMethod")}:</div>
                  <div className={classes.paymentWrapper}>
                    {payment.map(({ id, src }) => (
                      <div
                        key={id}
                        className={classes.payment}
                        active={`${selectedPay === id}`}
                        onClick={() => setSelectedPay(id)}
                      >
                        <img src={src} alt="" />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={payHandler}
                  sx={{
                    mt: "auto",
                    p: "11px 20px",
                    fontSize: 18,
                    width: "100%",
                  }}
                >
                  {trv("pay")}
                </Button>
              </div>
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </>
  );
}

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

// const payment = [
// { id: "Viza", src: Viza },
// { id: "Master", src: Master },
// { id: "PayPal", src: PayPal },
// { id: "zarinpal", src: Zarinpal },
// { id: "stripe", src: Stripe },
// ];

import React, { useEffect, useState } from "react";
import FiltersEnum from "../../configs/FiltersEnum";
import useFiles from "../../customHooks/hookRepositories/useFiles";
import ListLayout from "../Layouts/ListLayout/ListLayout";
import FilesCatTabUi from "../BaseElement/FilesCatTabUi/FilesCatTabUi";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import useCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";

export default function FileManager(props) {
  const [{ itemsList, paginator: { pageCount, currentPage } = {} }, setData] =
    useState({});
  const { modal, height, classNameCard, className, type } = props;
  const [query, setQuery] = useState({ page: 1 });
  const { getAll } = useFiles();
  const tv = useCustomTranslate("pages");
  const TabelHeader = {
    sort: {
      type: "sort",
      children: FiltersEnum.Sort,
    },
    type: !type && [
      {
        type: "file_type",
        children: [
          { value: "None", name: tv('fileTypeAll') },
          { value: "image", name: tv('fileTypeImage') },
          { value: "video", name: tv('fileTypeVideo') },
          { value: "audio", name: tv('fileTypeAudio') },
          { value: "document", name: tv('fileTypeDocument') },
        ],
      },
    ],
  };

  useEffect(() => {
    if (type) {
      onQueryCheng(type, "file_type");
    } else {
      getData({ page: 1 });
    }
    // eslint-disable-next-line
  }, []);
  const { open } = useSelector((state) => state.modal);
  useEffect(() => {
    if (!open && !modal) {
      getData({ page: 1 });
    }
    // eslint-disable-next-line
  }, [open]);

  const getData = async (value) => {
    await getAll({ ...query, ...value }).then((data) => {
      data && setData(data);
    });
  };

  const onQueryCheng = (e, type) => {
    const newQ = {};
    newQ[type] = e.target ? e.target.value : e;
    setQuery({ ...query, ...newQ });
    getData(newQ);
  };

  const onPageChange = (e, page) => {
    const newQ = {};
    newQ.page = page;
    setQuery({ ...query, ...newQ });
    getData(newQ);
  };
  return (
    <>
      <ListLayout
        pagination={{
          count: pageCount,
          page: currentPage ? currentPage : 1,
          onChange: onPageChange,
        }}
        TabelHeader={{
          ...TabelHeader,
          onChange: onQueryCheng,
          value: query,
          modal: modal,
        }}
        hasData
        height={height}
      >
        {itemsList ? (
          <Grid item display={"flex"} flexDirection={"column"} flexGrow={1}>
            <FilesCatTabUi
              getData={() =>
                getData({
                  ...query,
                  page:
                    itemsList.length <= 1 && currentPage === pageCount
                      ? currentPage - 1
                      : currentPage,
                })
              }
              classNameCard={classNameCard}
              className={className}
              modal={modal}
              itemsList={itemsList}
              uploadUserChanged={(val) => {
                onQueryCheng(val, "uploaded_by");
              }}
            />
          </Grid>
        ) : (
          ""
        )}
      </ListLayout>
    </>
  );
}

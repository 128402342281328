import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 21,
  },
  imageWrapper: {
    position: "relative",
    width: 85,
    height: 85,
  },
  image: {
    cursor: "pointer",
    width: 85,
    border: "1px solid #98A2B366",
    height: 85,
    display: "flex",
    alignItems: "center",
    borderRadius: 100,
    position: "relative",
    overflow: "hidden",
    "& > img": {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
  },
  addCircle: {
    position: "absolute",
    bottom: -5,
    right: -5,
    cursor: "pointer",
  },
  edit: {
    transition: "opacity .25s ease-out",
    position: "absolute",
    bottom: 2,
    right: 2,
    cursor: "pointer",
    width: 20,
    height: 20,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      width: 16,
      height: 16,
      "& > path": {
        fill: "#fff",
        fillOpacity: 1,
        "&:first-child": {
          display: "none",
        },
      },
    },
  },
  hoverWrapper: {
    "&:hover": {
      "&>div > div": {
        opacity: 1,
      },
      "&>span": {
        opacity: 0,
      },
    },
  },
  hoverIcon: {
    transition: "opacity .25s ease-out",
    display: "flex",
    opacity: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#101828B2",

    "&>svg": {
      width: 36,
      height: 36,
      "&>path": {
        fillOpacity: 1,
        fill: "#fff",
      },
    },
  },
  title: {
    fontSize: 12,
  },
}));

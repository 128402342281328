import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.boxUiBackground.main,
    borderRadius: 12,
    flexGrow: 1,
    maxWidth: 197.5,
  },
  eventsBody: {
    padding: "8px 12px 14px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  eventsImage: {
    height: 130,
    overflow: "hidden",
    borderRadius: "12px 12px 0 0",
    "& > img": {
      minWidth: "100%",
      maxHeight: 130,
    },
  },
  eventsTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    marginBottom: 7,
    fontWeight: 500,
  },
  eventsName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginBottom: 7,
    fontWeight: 500,
    color: "#98A2B3",
    fontSize: 14,
  },
  eventsDate: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginBottom: 7,
    color: "#98A2B3",
    fontSize: 14,
  },
}));

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../BaseElement/Modal/Modal";
import useStyles from "./styles";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { showModal } from "../../../redux/ModalSlice";
import { MainUrl } from "../../../services/Api";
import "swiper/css/navigation";
import UseCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import Icons from "../../../assets/icons/Icons";

export default function PreviewModal(props) {
  const classes = useStyles();
  const tv = UseCustomTranslate("forms");
  const dispatch = useDispatch();
  const { open, preview } = useSelector((state) => state.modal);
  const handleClose = () => {
    dispatch(showModal({ open: false }));
  };
  return (
    <Modal
      open={open === "previewModal"}
      onClose={handleClose}
      title={tv("preview")}
    >
      <div className={classes.wrapper}>
        <Swiper
        className={classes.swiper}
          modules={[Navigation]}
          spaceBetween={50}
          navigation={{
            nextEl: `.${classes.nextEl}`,
            prevEl: `.${classes.prevEl}`,
            disabledClass: classes.disabled,
          }}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {preview?.map((x, i) => (
            <SwiperSlide key={i}>
              <div className={classes.imageWrapper}>
                <img className={classes.image} alt="" src={MainUrl + x} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.nextEl}>
          <Icons.ArrowDown className={classes.icon} />
        </div>
        <div className={classes.prevEl}>
          <Icons.ArrowDown className={classes.icon} />
        </div>
      </div>
    </Modal>
  );
}

import { Breadcrumbs } from "@mui/material";
import MuiLink from "@mui/material/Link";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import useStyles from "./styles";

export default function BreadcrumbsUi(props) {
  const { breadcrumbs } = useSelector((s) => s.toolbar);
  // const classes = useStyles();
  return breadcrumbs ? (
    <Breadcrumbs sx={{ mb: 2.5 }}>
      {breadcrumbs.map(({ name, href }) =>
        href ? (
          <MuiLink key={name} underline="hover" color="inherit" component={"div"} >
            <Link to={href}>{name}</Link>
          </MuiLink>
        ) : (
          <div key={name} >{name}</div>
        ),
      )}
    </Breadcrumbs>
  ) : (
    ""
  );
}

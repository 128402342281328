import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  exportText: {
    color: "#185C37",
    fontSize: 14,
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
  },
}));

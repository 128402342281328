import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  form: {
    marginBottom: "30px ",
  },
  logoWrapper: {
    marginBottom: "47px",
    marginTop: "6px",
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  formButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loginIcon: {
    margin: "0 5px",
  },
  forget: {
    textTransform: "none",
    margin: "auto",
    width: "fit-content",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  boothRegisterWrapper: {
    padding: "16px 30px ",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  boothRegister: {
    width: " 100%",
    height: "100%",
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
    },
  },
  forgotLink: {
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },
  registerBtnLink: {
    display: "flex",
    flexDirection: "column",
  },
  registerBtn: {
    margin: " 0 !important",
    borderRadius: "30px !important",
    color: `${theme.palette.buttonColor.main} !important`,
    padding: "11.75px 22px !important",
    fontSize: "16px !important",
  },
  error: {
    marginBottom: 10,
  },
}));

import { BaseUrl, ResponseStatus } from "../../services/Api";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, signOut } from "../../redux/AuthSlice";
import { showAlert } from "../../redux/AlertsSlice";
import useGet from "../useGet";
import { setRole } from "../../redux/RolesSlice";
import Roles from "../../configs/RolesConfigs/Roles";
import useChat from "./useChat";
// ###########################################################

const useProfile = () => {
  const get = useGet();
  const { startWs } = useChat();
  const dispatch = useDispatch();
  let { token } = useSelector((s) => s.auth);
  // organizers, admins, exhibitors
  const getUserInfoUrl = `${BaseUrl}/admins/profile`;
  const getUserInfo = async (tokenId) => {
    const newToken = tokenId ? tokenId : token;
    if (newToken && newToken !== "") {
      try {
        let res = await get(getUserInfoUrl, newToken);
        if (res.status === ResponseStatus.Success) {
          dispatch(setUserInfo({ info: res.data }));
          dispatch(setRole(Roles[res.data.type]));
          startWs(newToken);
        } else {
          if (
            res.message === "unauthorized" ||
            res.message === "Access denied"
          ) {
            dispatch(signOut());
          }
          dispatch(
            showAlert({
              notify: { message: res.message, type: "danger" },
            }),
          );
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  };

  return { getUserInfo };
};

export default useProfile;

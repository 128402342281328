import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import useStyles from "./styles";
import TableUi from "../../../../components/BaseElement/TableUi";
import useUsers from "../../../../customHooks/hookRepositories/useUsers";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import RouteEnum from "../../../../navigation/RouteEnum";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
export default function VisitorList(props) {
  // var classes = useStyles();
  const dispatch = useDispatch();
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const linkName = "visitors";
  useEffect(() => {
    const linkName = "Visitors";
    dispatch(
      setToolbar({
        id: linkName,
        title: { name: tv("visitors") },
        leftElements: [
          { name: "AdvanceSearch", type: "AdvanceSearch" },
          {
            name: `${tv("add")} ${tv("visitors")}`,
            type: "ButtonUi",
            Link: RouteEnum.user[linkName] + "/add",
          },
        ],
        rightElements: [{ type: "Excel", name: linkName }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("users") },
          { name: tvCrumb("visitors") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [linkName, dispatch, tv]);
  const { getAll, deleteUser } = useUsers();
  useEffect(() => {
    getAll({ type: linkName });
    // eslint-disable-next-line
  }, [linkName]);

  const { itemsList, type } = useSelector((s) => s.table);
  const [tabelData, setTabelData] = useState({});
  const [q, setQ] = useState("");
  useEffect(() => {
    if (type === linkName) {
      const rows = itemsList.map(({ _id, email, fullName, phoneNumber }) => ({
        id: _id,
        Name: fullName,
        Email: email,
        PhoneNumber: phoneNumber,
      }));
      const columns = [
        { field: "Name", headerName: tv("name"), flex: 2 },
        { field: "Email", headerName: tv("email"), flex: 2 },
        { field: "PhoneNumber", headerName: tv("phoneNumber"), flex: 2 },
      ];
      setTabelData({ rows, columns });
    }
    // eslint-disable-next-line
  }, [itemsList, type, tv("name")]);

  const onPageChange = (e, page) => {
    getAll({ type: linkName, page, q });
  };
  const onDelete = async (ids) => {
    deleteUser({ type: linkName, ids, q });
  };
  const onChange = (q) => {
    getAll({ type: linkName, q });
    setQ(q);
  };
  return (
    <div>
      {tabelData.columns?.length > 0 && (
        <TableUi
          onPageChange={onPageChange}
          onDelete={onDelete}
          {...tabelData}
          onChange={onChange}
        />
      )}
    </div>
  );
}

import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.tableBgColor.main,
        flexGrow: 1,
        padding: "15px 20px",
        borderRadius: 20,
        position: "relative",
        gap: 15,
        maxWidth: 334,
        [theme.breakpoints.down("lg")]: {
            maxWidth:'100%'
        }
    },
    active: {
        display: "flex",
        flexDirection: "row",
        gap: 5,
        alignItems: "center",
    },
    describeWrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: 5,
        justifyContent: "space-between",
    },
    describe: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "space-between",
    },
    describeTitle: {
        fontSize: 14,
        color: theme.palette.description.main,
    },
    activeDot: {
        borderRadius: "100%",
        backgroundColor: theme.palette.success.main,
        width: 10,
        height: 10,
    },
    notActiveDot: {
        borderRadius: "100%",
        backgroundColor: theme.palette.error.main,
        width: 10,
        height: 10,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        gap: 10,
        [theme.breakpoints.down("lg")]: {
            flexDirection:'column',
            alignItems:'center',
            textAlign:'center'
        }
    },
    icon: {
        display: "flex",
        width: 56,
        height: 56,
        borderRadius: 100,
        border: "1px solid #98A2B366",
        alignItems: "center",
        overflow: "hidden",
        "& > img": {
            maxWidth: 56,
            maxHeight: 56,
            margin: "auto",
        },
    },
    headerText: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    name: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 140,
        fontWeight: 600,
        fontSize: 16,
    },
    description: {
        color: theme.palette.description.main,
        fontSize: 12,
        fontWeight: 400,
        overflow: "hidden",
        maxWidth: 140,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    exhibitionName: {
        color: theme.palette.primary.main,
        fontSize: 12,
        fontWeight: 400,
        overflow: "hidden",
        maxWidth: 140,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    btnWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
    },
    btnLink: {
        display: "flex",
        flexDirection: "column",
    },
    messages: {
        boxShadow: "none !important",
    },
    rtl: {
        "& .MuiButton-startIcon": {
          marginLeft:5
        }
    }
}));

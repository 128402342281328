import React from "react";
import { Route, Routes } from "react-router";
import Layout from "../../components/Layout/Layout";
import RouteEnum from "../../navigation/RouteEnum";
import Comments from "../Comments/Comments";
import Files from "../Files/Files";
import Dashboard from "../Dashboard";
import ExhibitorSettings from "./pages/ExhibitorSettings/ExhibitorSettings";
import MyBooths from "./pages/MyBooths/MyBooths";
import NotFound from "../NotFound/NotFound";

export default function Exhibitor(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path={RouteEnum.MyBooths + "/*"} element={<MyBooths />} />
        <Route path={RouteEnum.Comments} element={<Comments />} />
        <Route path={RouteEnum.Files} element={<Files />} />
        <Route path={RouteEnum.Settings} element={<ExhibitorSettings />} />
      </Route>
    </Routes>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    gap: 20,
    textAlign: "center",
    flexDirection: "column",
  },
  headerIcon: {
    "&> svg": {
      width: 60,
      height: 60,
    },
  },
  tick: {
    "&> path": {
      strokeWidth: 1,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
}));

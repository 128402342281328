import {createSlice} from "@reduxjs/toolkit";

const selectedFileSlice = createSlice({
    name: 'selectedFile',
    initialState: {
        type:'',
        url:''
    },
    reducers: {
        setFile(state,action){
            return action.payload;
        }
    }
})

export const {setFile} = selectedFileSlice.actions;
export default selectedFileSlice.reducer

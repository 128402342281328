import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Grid, Tab, Tabs } from "@mui/material";
import Modal from "../../BaseElement/Modal/Modal";
import UploadUi from "../../BaseElement/UploadUi/UploadUi";
import UploadPreviewUi from "../../BaseElement/UploadPreviewUi/UploadPreviewUi";
import FileManager from "../../FileManager/FileManager";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../redux/ModalSlice";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container spacing={2}>
          {children}
        </Grid>
      )}
    </div>
  );
}

export default function FilesModal(props) {
  const tv = useCustomTranslate('modal')
  //region--------------- states
  var classes = useStyles();
  const [value, setValue] = useState(0);
  const [files, setFiles] = useState();
  const { open, type } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  useEffect(() => {
    if (open === "FilesModal" && value !== 0) {
      setValue(0);
    }
    // eslint-disable-next-line
  }, [open]);
  const handleClose = () => {
    setFiles("");
    dispatch(showModal({ open: false }));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //endregion-------------- handlers

  return (
    <>
      <Modal
        title={tv('openFile')}
        open={open === "FilesModal"}
        onClose={handleClose}
      >
        <Tabs
          className={classes.root}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#755EFC",
              zIndex: 0,
              borderRadius: 20,
              height: 4,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="full width tabs example"
        >
          <Tab disableRipple label={tv('tabFiles')} />
          <Tab disableRipple label={tv('tabUpload')} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid item xs={12}>
            <FileManager
              classNameCard={classes.itemCard}
              className={classes.catItem}
              modal
              type={type}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} gap={5}>
          {files && files[0] ? (
            <Grid item xs={12} className={classes.upload}>
              <UploadPreviewUi files={files} onCancel={() => setFiles("")} />
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.upload}>
              <UploadUi fileManager onDrop={(v) => setFiles(v)} type={type} />
            </Grid>
          )}
        </TabPanel>
      </Modal>
    </>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import EventsList from "./components/EventsList";
import NewEvent from "./components/NewEvent";
import EditEvent from "./components/EditEvent";
import NotFound from "../NotFound/NotFound";

export default function Events(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<EventsList />} />
      <Route path={"/new"} element={<NewEvent />} />
      <Route path={"/edit/:id"} element={<EditEvent />} />
    </Routes>
  );
}

import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Key } from "./key.svg";
import { ReactComponent as Login } from "./login.svg";
import { ReactComponent as Sms } from "./sms.svg";
import { ReactComponent as BackSquare } from "./back-square.svg";
import { ReactComponent as PasswordCheck } from "./password-check.svg";
import { ReactComponent as Bubble } from "./bubble.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as BubbleSidebar } from "./bubble-sidebar.svg";
import { ReactComponent as Profile2User } from "./profile-2user.svg";
import { ReactComponent as Signpost } from "./signpost.svg";
import { ReactComponent as Calendar2 } from "./calendar-2.svg";
import { ReactComponent as Messages2 } from "./messages-2.svg";
import { ReactComponent as FolderOpen } from "./folder-open.svg";
import { ReactComponent as Chart } from "./chart.svg";
import { ReactComponent as DollarSquare } from "./dollar-square.svg";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as Excel } from "./Excel.svg";
import { ReactComponent as Add } from "./add.svg";
import { ReactComponent as Search } from "./search-normal.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Edit2 } from "./edit-2.svg";
import { ReactComponent as AddCircle } from "./add-circle.svg";
import { ReactComponent as SendSquare } from "./send-square.svg";
import { ReactComponent as Edit2S } from "./edit-2-stroke.svg";
import { ReactComponent as SmsNotification } from "./sms-notification.svg";
import { ReactComponent as SendStrok } from "./send-strok.svg";
import { ReactComponent as Send } from "./send.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as Setting } from "./setting.svg";
import { ReactComponent as ReplySolid } from "./reply-solid.svg";
import { ReactComponent as CloseCircle } from "./close-circle.svg";
import { ReactComponent as TickCircle } from "./tick-circle.svg";
import { ReactComponent as PlayCircle } from "./play-circle.svg";
import { ReactComponent as InfoCircle } from "./info-circle.svg";
import { ReactComponent as ShopAdd } from "./shop-add.svg";
import { ReactComponent as Frame } from "./frame.svg";
import { ReactComponent as Language } from "./language.svg";

const Icons = {
  Language,
  ShopAdd,
  Frame,
  User,
  Key,
  Login,
  Logout,
  Sms,
  BackSquare,
  PasswordCheck,
  Bubble,
  Lock,
  BubbleSidebar,
  Profile2User,
  Signpost,
  Calendar2,
  Messages2,
  FolderOpen,
  Chart,
  DollarSquare,
  ArrowDown,
  Excel,
  Add,
  Trash,
  Search,
  Edit,
  Edit2,
  Edit2S,
  AddCircle,
  SmsNotification,
  SendSquare,
  SendStrok,
  Send,
  Close,
  Setting,
  ReplySolid,
  TickCircle,
  CloseCircle,
  PlayCircle,
  InfoCircle,
};
export default Icons;

import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Icons from "../../../assets/icons/Icons";
import RouteEnum from "../../../navigation/RouteEnum";
import { showModal } from "../../../redux/ModalSlice";
import Modal from "../../BaseElement/Modal/Modal";
import useStyles from "./styles";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import { Trans } from "react-i18next";

export default function BuyResponseModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const tv = useCustomTranslate("BuyResponseModal");
  const { open, isSuccess } = useSelector((state) => state.modal);
  const handleClose = () => {
    dispatch(showModal({ open: false }));
  };
  return (
    <Modal
      open={open === "BuyResponseModal"}
      onClose={handleClose}
      DialogProps={{ maxWidth: "sm" }}
    >
      <div className={classes.wrapper}>
        {open === "BuyResponseModal" &&
          (isSuccess ? (
            <>
              <div className={classes.headerIcon}>
                <Icons.TickCircle className={classes.tick} />
              </div>
              <div
                style={{ color: theme.palette.success.main }}
                className={classes.title}
              >
                {tv("paymentSuccessful")}
              </div>
              <div>
                <Trans i18nKey="translations.BuyResponseModal.paymentSuccessfulMassage">
                  Your payment was successful and <strong> Booth </strong>
                  submitted for you. You can access that booth from
                  <strong> My Booths </strong>
                  section in menu.
                </Trans>
              </div>
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Link
                  to={RouteEnum.MyBooths}
                  style={{ flexGrow: 1, display: "flex" }}
                  onClick={handleClose}
                >
                  <Button
                    sx={{ flexGrow: 1, p: "11px 20px", fontSize: 18 }}
                    variant="contained"
                    color="primary"
                  >
                    {tv("goToMyBooths")}
                  </Button>
                </Link>
              </Box>
            </>
          ) : (
            <>
              <div className={classes.headerIcon}>
                <Icons.CloseCircle />
              </div>
              <div
                style={{ color: theme.palette.error.main }}
                className={classes.title}
              >
                {tv("unsuccessfulPayment")}
              </div>
              <div>{tv("unsuccessfulPaymentMassage")}</div>
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Button
                  sx={{ flexGrow: 1, p: "11px 20px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  {tv("tryAgain")}
                </Button>
              </Box>
            </>
          ))}
      </div>
    </Modal>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    minHeight: "calc(100vh - 90px)",
    left: 0,
  },
  layout: {
    backgroundColor: theme.palette.background.main,
    minHeight: "100vh",
    height: "100%",
  },
  formContainer: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // [theme.breakpoints.down("sm")]: {
    //   width: "50%",
    // },
  },
  goBackWrapper: {
    width: "100%",
    margin: "30px 0px",
  },
  goBack: {
    width: "100%",
    textAlign:"center",
    margin: "auto",
  },
  goBackLink: {
    color: "#667085 !important",
    "& svg": {
      position: "relative",
      top: "5px",
    },
  },
}));

import React from "react";
import useStyles from "./styles";
// components
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import InputUi from "../BaseElement/InputUi/InputUi";
import { IconButton, InputAdornment } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";

export default function PersianDatepicker(props) {
  const { value, label, className, error, helperText } = props;
  const dateValue = new Date(value);
  var classes = useStyles();
  const handleChange = (e) => {};
  return (
    <DatePicker
      {...props}
      containerClassName={`${classes.inputContainer} ${
        className ? className : ""
      }`}
      value={dateValue}
      style={{ flexGrow: 1 }}
      calendar={persian}
      locale={persian_fa}
      calendarPosition="bottom-right"
      render={(value, openCalendar) => {
        return (
          <InputUi
            fullWidth={true}
            autoComplete={"off"}
            className={classes.input}
            value={value}
            label={label}
            onClick={openCalendar}
            onChange={handleChange}
            helperText={helperText}
            error={error}
            variant="outlined"
            endadornment={
              <InputAdornment position="end">
                <IconButton onClick={openCalendar}>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        );
      }}
    />
  );
}

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import ExhibitionsCard from "../ExhibitionsCard";
import useExhibitions from "../../../../customHooks/hookRepositories/useExhibitions";
import { setExhibitions } from "../../../../redux/ExhibitionsSlice";
import FiltersEnum from "../../../../configs/FiltersEnum";
import ListLayout from "../../../../components/Layouts/ListLayout";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function ExhibitionsList(props) {
  const linkName = "exhibitions";
  const { getAll } = useExhibitions();
  const dispatch = useDispatch();
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Exhibitions",
        title: { name: tv("exhibitions") },
        leftElements: [
          { type: "AdvanceSearch" },
          {
            type: "ButtonUi",
            name: tv("exhibitionsBtn"),
            Link: "Exhibitions/add",
          },
        ],
        rightElements: [{ type: "Excel", name: "exhibitions" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tv("exhibitions") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  useEffect(() => {
    dispatch(setExhibitions({ exhibitions: {} }));
    getAll({ name: linkName });
    // eslint-disable-next-line
  }, []);
  const {
    exhibitions: { itemsList, paginator: { currentPage, pageCount } = {} },
  } = useSelector((s) => s.exhibitions);

  const [tabelData, setTabelData] = useState([]);
  useEffect(() => {
    if (itemsList) {
      setTabelData(itemsList);
    }
  }, [itemsList]);
  const [query, setQuery] = useState({});
  const onQueryCheng = (e, type) => {
    const newQ = { ...query };
    newQ[type] = e.target ? e.target.value : e;

    getAll({ name: linkName, query: newQ });
    setQuery(newQ);
  };
  const TabelHeader = {
    sort: {
      type: "sort",
      children: FiltersEnum.Sort,
    },
    filter: [
      {
        type: "is_active",
        children: FiltersEnum.Active,
      },
    ],
  };
  const onPageChange = (e, page) => {
    getAll({ name: linkName, query: { ...query, page } });
  };
  return (
    <ListLayout
      pagination={{
        count: pageCount,
        page: currentPage ? currentPage : 1,
        onChange: onPageChange,
      }}
      TabelHeader={{
        ...TabelHeader,
        onChange: onQueryCheng,
        value: query,
      }}
      hasData={tabelData?.length > 0}
    >
      {/* <Grid item xs={3} sx={{ display: "flex" }}>
            <div className={classes.cardWrapper}>
              <Link className={classes.addIconWrapper} to="add">
                <Icons.Add className={classes.addIcon} />
                <div className={classes.addTitle}>Add New Exhibition</div>
              </Link>
            </div>
          </Grid> */}
      {tabelData.map((x) => (
        <Grid key={x._id} item xs={12} md={6} lg={3} sx={{ display: "flex" }}>
          <ExhibitionsCard {...x} query={query} />
        </Grid>
      ))}
    </ListLayout>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddForm from "../../../../components/AddForm/AddForm";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";
import useUsers from "../../../../customHooks/hookRepositories/useUsers";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import RouteEnum from "../../../../navigation/RouteEnum";
// import useStyles from "./styles";

export default function AddExhibitors(props) {
  // var classes = useStyles();
  const tv = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Exhibitors",
        title: { name: `${tv("new")}  ${tv("exhibitor")}` },
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("users") },
          { name: tv("exhibitor"), href: RouteEnum.user.Exhibitors },
          { name: `${tv("new")}  ${tv("exhibitor")}` },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);

  const [formData, setFormData] = useState({});
  const inputHandler = (value, type) => {
    const newValue = {};
    newValue[type] = value;
    setFormData({ ...formData, ...newValue });
  };

  const { isValid } = useAuth();
  const { addUser } = useUsers();
  const submitHandler = (error) => {
    if (
      !isValid({
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      }) ||
      error
    ) {
      return;
    }
    addUser({ type: "exhibitors", body: formData });
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "name",
            title: tv("firstName"),
            input: {
              placeholder: tv("firstNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "family",
            title: tv("lastName"),
            input: {
              placeholder: tv("lastNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "email",
            title: tv("email"),
            required: true,
            input: {
              placeholder: tv("emailPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "username",
            title: tv("username"),
            input: {
              autoComplete: "do-not-autofill",
              placeholder: tv("usernamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "telephoneNumber",
            title: tv("phoneNumber"),
            required: true,
            input: {
              dir: "ltr",
              type: "tel",
              placeholder: tv("phoneNumberPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "phoneNumber",
            title: tv("mobileNumber"),
            input: {
              dir: "ltr",
              type: "tel",
              placeholder: tv("mobileNumberPlaceholder"),
            },
          },
          {
            width: [2, 10],
            valueId: "address",
            title: tv("address"),
            input: {
              placeholder: tv("addressPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "postalCode",
            title: tv("zipCode"),
            input: {
              placeholder: tv("zipCodePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "websiteUrl",
            title: tv("website"),
            input: {
              placeholder: tv("websitePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "companyName",
            title: tv("company"),
            required: true,
            input: {
              placeholder: tv("companyPlaceholder"),
            },
          },
          {
            width: [2, 4],
            title: " ",
          },
          {
            width: [2, 10],
            valueId: "companyActivity",
            title: tv("activityDesc"),
            input: {
              autoComplete: "new-password",
              placeholder: tv("activityDescPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "password",
            title: tv("password"),
            required: true,
            input: {
              autoComplete: "new-password",
              type: "password",
              placeholder: tv("passwordPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "confirmPassword",
            title: tv("confirmPassword"),
            required: true,
            input: {
              autoComplete: "new-password",
              type: "password",
              placeholder: tv("confirmPasswordPlaceholder"),
            },
          },
        ],
      },
    },
  ];
  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title={tv("exhibitor")}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
      />
    </div>
  );
}

import React from "react";
import useStyles from "./styles";
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from "react-i18next";

export default function PerfectScrollbarUi(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isRtl = t("dir") === "rtl";
  return (
    < Scrollbars
      {...props}
      className={[classes.wrapper, props.className, isRtl ? classes.wrapperRtl : ""].join(" ")}
    >
      {props.children}
    </ Scrollbars>
  );
}
import organizers from "./organizers";
import admins from "./admins";
import exhibitors from "./exhibitors";

const Roles = {
  organizers,
  admins,
  exhibitors,
};
export default Roles;

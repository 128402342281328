import { useDispatch } from "react-redux";
import { BaseUrl, MainUrl, ResponseStatus } from "../../services/Api";
import useGet from "../useGet";
import { showAlert } from "../../redux/AlertsSlice";
import * as moment from "moment";
import usePost from "../usePost";
import useAuth from "./useAuth";
import { useNavigate } from "react-router";
import usePut from "../usePut";
import useDelete from "../useDelete";

const eventUrl = BaseUrl + "/admins/events";
const useEvents = () => {
  const dispatch = useDispatch();
  const fetchGet = useGet();
  const fetchPost = usePost();
  const put = usePut();
  const del = useDelete();
  const { errorHandler } = useAuth();
  const navigate = useNavigate();
  const getAllEvents = async (
    sort = "",
    filter = "",
    query = "",
    page = 1,
    limit = 10,
  ) => {
    try {
      var urlPagingPostFix = `?page=${page}&limit=${limit}`;
      var urlPostFix = `${sort.length > 0 ? "&sort=" + sort : ""}${
        filter.length > 0 ? "&filter=" + filter : ""
      }${query.length > 0 ? "&q=" + query : ""}`;
      let res = await fetchGet(eventUrl + urlPagingPostFix + urlPostFix);

      if (res.status === ResponseStatus.Success) {
        return {
          itemsList: res.data.itemsList.map((val, indx) => {
            return {
              _id: val._id,
              name: val.name,
              speakerName: val.speakerName,
              link: val.link,
              dateDescription: `${val.date} ${val.time}`,
              banner: MainUrl + val.banner,
              isActive: val.isActive,
            };
          }),
          paginator: res.data.paginator,
        };
      } else {
        dispatch(
          showAlert({
            visible: true,
            type: "error",
            text: res.data,
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getEvent = async (id) => {
    try {
      let res = await fetchGet(eventUrl + "/" + id);

      if (res.status === ResponseStatus.Success) {
        return {
          _id: res.data._id,
          name: res.data.name,
          speakerName: res.data.speakerName,
          link: res.data.link,
          date: res.data.date,
          dateUi: moment(
            `${res.data.date} ${res.data.time}`,
            "YYYY/MM/DD HH:mm:ss",
          ),
          time: res.data.time,
          dateDescription: moment(
            `${res.data.date} ${res.data.time}`,
            "YYYY-MM-DD HH:mm:ss",
          ).format("MMMM DD - HH:mm"),
          banner: res.data.banner,
          exhibitionId: res.data.exhibitionId ?? "0",
          isActive: res.data.isActive,
        };
      } else {
        dispatch(
          showAlert({
            visible: true,
            type: "error",
            text: res.data,
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getAllSpeakers = async () => {
    try {
      let res = await fetchGet(eventUrl + "/speakers");

      if (res.status === ResponseStatus.Success) {
        return res.data.map((val) => {
          return { value: val, name: val };
        });
      } else {
        dispatch(
          showAlert({
            visible: true,
            type: "error",
            text: res.data,
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const createEvent = async (body) => {
    try {
      let res = await fetchPost(eventUrl, body);

      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `Event added`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const updateEvent = async (id, body) => {
    try {
      let res = await put(eventUrl + "/" + id, body);

      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `Event updated`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        dispatch(
          showAlert({
            notify: {
              type: "error",
              message: res.errors
                .map((val) => `${val.param} is ${val.msg} \n`)
                .join(" - "),
            },
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const deleteEvent = async (id) => {
    try {
      let res = await del(eventUrl + "/" + id);

      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `Event deleted`, type: "success" },
          }),
        );
      } else {
        dispatch(
          showAlert({
            notify: {
              type: "error",
              message: res.errors
                .map((val) => `${val.param} is ${val.msg} \n`)
                .join(" - "),
            },
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return {
    getAllEvents,
    getAllSpeakers,
    createEvent,
    updateEvent,
    getEvent,
    deleteEvent,
  };
};

export default useEvents;

import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  List,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import useStyles from "./styles";
import defaultImage from "../../../../assets/images/default.jpg";
import { MainUrl } from "../../../../services/Api";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function BoothDesignSelector(props) {
  const { onChange, children, value, currentDesign, error } = props;
  const classes = useStyles();
  const [imageSource, setImageSource] = useState(defaultImage);
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const clickHandler = (v) => {
    onChange(v, "boothDesignId");
    setOpen(false);
  };
  const tv = useCustomTranslate("forms");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Select
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => {
          setOpen(true);
          if (value)
            setImageSource(
              MainUrl + children.filter((x) => x._id === value)[0]?.cover,
            );
        }}
        value={value ? value : ""}
        classes={{
          root: classes.selectRoot,
          select: classes.select,
          outlined: classes.selectOutlined,
        }}
        className={classes.selectRoot}
        sx={{ overflow: "hidden" }}
      >
        {children?.map(({ title, _id: value }, i) => (
          <MenuItem sx={{ display: "none" }} value={value} key={i}>
            {title}
          </MenuItem>
        ))}
        <Grid className={classes.selectionWrapper} container>
          <Grid className={classes.itemsWrapper} item>
            <List className={classes.itemList}>
              {children?.map(({ title, _id: value, cover, inUse }, i) => (
                <MenuItem
                  disabled={inUse && value !== currentDesign}
                  onClick={() => clickHandler(value)}
                  classes={{ root: classes.items }}
                  value={value}
                  key={i}
                  onMouseEnter={() => {
                    setImageSource(MainUrl + cover);
                    setLoaded(true);
                  }}
                >
                  {title}
                </MenuItem>
              ))}
            </List>
          </Grid>
          <Grid className={classes.imageWrapper} item xs={5}>
            <img src={imageSource} alt="" onLoad={() => setLoaded(false)} />
            {loaded && (
              <div className={classes.loaded}>
                <div>
                  <CircularProgress color="primary" />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Select>
      {error && (
        <FormHelperText sx={{ pl: 2, color: "#E31B54" }}>
          {tv("boothDesignError")}
        </FormHelperText>
      )}
    </Box>
  );
}

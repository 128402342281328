import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  chechedOptions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  checkbox: {
    backgroundColor: "#fff",
    borderRadius: 15,
  },
  checkedBtn: {
    backgroundColor: "#fff",
    borderRadius: 12,
    "&>button": {
      padding: "8px 12px",
    },
  },
}));

import BoxUi from "../../components/BaseElement/BoxUi";
import React, { useEffect, useState } from "react";
import LoginDescription from "../../components/LoginDescription";
import { useSearchParams } from "react-router-dom";
import UseCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import useAuth from "../../customHooks/hookRepositories/useAuth";

export default function EmailConfirm(props) {
  const { emailVerify } = useAuth()
  var [params, setParams] = useState();
  var [verified, setVerified] = useState(false);
  const [searchParams] = useSearchParams();
  const tv = UseCustomTranslate("emailConfirm");
  useEffect(() => {
    const params = {
      code: searchParams.get("code"),
      email: searchParams.get("email"),
    }
    if (params.code) {
      setParams(true);
      confirmationHandler(params);
    }
    // eslint-disable-next-line
  }, []);

  const confirmationHandler = async (body) => {
    const res = await emailVerify({ body })
    setVerified(res ? "Success" : "Error");
  };
  return (
    <BoxUi login>
      {params ? (
        verified === "Success" ? (
          <LoginDescription
            title={tv("verified")}
            description={tv("verifiedMessage")}
          />
        ) : verified === "Error" ?
          (
            <LoginDescription
              title={tv("error")}
              description={tv("errorDescription")}
            />
          ) : (
            <LoginDescription
              title={tv("pleaseWait")}
              description={tv("pleaseWaitMessage")}
            />
          )
      ) : (
        <LoginDescription
          title={tv("emailVerification")}
          description={tv("emailVerificationMessage")}
        />
      )}
    </BoxUi>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  titleEnd: {
    display: "flex",
    flexGrow: "1 !important",
    flexDirection: "column !important",
    alignItems: "stretch",
    justifyContent: "center",
    color: "#475467CC",
    whiteSpace: "nowrap",
    position: "relative",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "end",
    [theme.breakpoints.down("lg")]: {
      textAlign: "start",
    },
  },
  fullFlex: {
    flexGrow: "1 !important",
  },
  inputContainer: {
    display: "flex",
    position:'relative',
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "5px!important",
      marginBottom: "10px!important",
    },
  },
  titleStart: {
    textAlign: "start",
    display: "flex",
    flexGrow: "1 !important",
    flexBasis: "auto !important",
    flexDirection: "column !important",
    alignItems: "stretch",
    justifyContent: "flex-start",
    color: "#475467CC",
    whiteSpace: "nowrap",
    position: "relative",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    // paddingRight: "0px !important",
    // paddingLeft: "0px !important",
  },
  subTitle: {
    textAlign: "start",
    display: "flex",
    flexDirection: "column !important",
    alignItems: "stretch",
    justifyContent: "center",
    color: "#475467CC",
    whiteSpace: "nowrap",
    position: "relative",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "21px",
  },
  required: {
    position: "absolute",
    right: -5,
    top: "calc(50% - 15px)",
    color: "red",
    [theme.breakpoints.down("lg")]: {
      right: "0!important",
      left: "20px!important",
    },
  },
  btnWrapper: {
    display: "flex",
    marginTop: 50,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: 10,
  },
  error: {
    marginTop: 10,
    color: theme.palette.error.main,
    textAlign: "center",
    position: "absolute",
    width: "100%",
  },
  selectWrapper: {
    flexGrow: "1 !important",
    width: "100%",
    "& > fieldset": {
      borderRadius: "30px !important",
    },
  },
  select: {
    borderRadius: "30px !important",
    backgroundColor: `${theme.palette.inputBackground.main} !important`,
    padding: "10px 20px !important",
    "&[aria-expanded='true']": {
      backgroundColor: `${theme.palette.inputBackground.light} !important`,
    },
  },
  rtl: {
    left: 20,
    right: "auto",
  },
  gridTemplate: {
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0 !important",
      paddingLeft: "0 !important",
      display: "grid!important",
      gridTemplateColumns: "auto!important",
      width: "100%!important",
      maxWidth: "100%!important",
      "& :not(img):not(svg):not(.MuiInputAdornment-root)": {
        maxWidth: "100%!important",
      },
    },
  },
  formWrapper: {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column!important",
    },
  },
  rtlMain: {
    "& > div > div": {
      padding: "9px 9px 9px 39px !important",
    },
    "& .MuiAutocomplete-endAdornment ": {
      left: 9,
      right: "auto !important",
    },
  },
  autocomplete: {
    borderRadius: "30px !important",
    backgroundColor: theme.palette.inputBackground.main,
    "&:focus-within": {
      backgroundColor: theme.palette.inputBackground.light,
    },
    "& input": {
      borderRadius: "30px !important",
      backgroundColor: theme.palette.inputBackground.main,
      padding: "1px 10px !important",
      "&:focus": {
        backgroundColor: theme.palette.inputBackground.light,
      },
    },
    "& > div > div": {
      borderRadius: "30px !important",
    },
  },
  maxLengthBox: {
    color: theme.palette.inputColor.main,
    opacity: 0.5,
    fontSize: "12px",
  },
  ImageTypeWarningWrapper: {
    fontSize: 12,
    border: `1px solid ${theme.palette.warning.main}`,
    background: `${theme.palette.warning.main}1a`,
    color: theme.palette.warning.main,
    alignItems: 'center',
    padding: 5,
    borderRadius: 10,
    display: 'flex',
    gap: 10,
    width: '100%',
    marginBottom: 20,
  },
  ImageTypeWarning: {
    fontSize: 12,
    alignItems: 'center',
    padding: 5,
    borderRadius: 10,
    display: 'flex',
    gap: 10,
    width: '100%'
  }
}));

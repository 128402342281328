import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  inputContainer: {
    flexGrow: 1,
  },
  input: {
    "&> div": {
      paddingLeft: 0,
    },
  },
}));

import { useDispatch, useSelector } from "react-redux";
import { setTable } from "../../redux/TableSlice";
import { BaseUrl, MainUrl, ResponseStatus } from "../../services/Api";
import useGet from "../useGet";
import useDelete from "../useDelete";
import usePost from "../usePost";
import usePut from "../usePut";
import { showAlert } from "../../redux/AlertsSlice";
import useAuth from "./useAuth";
import Limits from "../../configs/Limits";
import { useNavigate } from "react-router";
const useUsers = () => {
  const dispatch = useDispatch();
  const get = useGet();
  const put = usePut();
  const Delete = useDelete();
  const post = usePost();
  const listBaseUrl = ({ name, q, limit = Limits.tabels.user.main, page = 1 }) =>
    `${BaseUrl}/admins/${name}?page=${page}&limit=${limit}${
      q ? `&q=${q}` : ""
    }`;
  const {
    itemsList,
    paginator: { currentPage, pageCount },
  } = useSelector((s) => s.table);
  const getAll = async ({ type, page, q, limit } = {}) => {
    const organizersUrl = listBaseUrl({ name: type, page, q, limit });
    try {
      let res = await get(organizersUrl);
      if (res.status === ResponseStatus.Success) {
        dispatch(setTable({ ...res.data, type }));
      } else {
        dispatch(
          setTable({
            itemsList: [],
            paginator: {},
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
      dispatch(
        setTable({
          itemsList: [],
          paginator: {},
        }),
      );
    }
  };

  const { errorHandler } = useAuth();
  const navigate = useNavigate();

  const addUser = async ({ type, body } = {}) => {
    const addUrl = `${BaseUrl}/admins/${type}`;
    try {
      let res = await post(addUrl, body);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `${type} added`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const getOne = async ({ type, id } = {}) => {
    const addUrl = `${BaseUrl}/admins/${type}/${id}`;
    try {
      let res = await get(addUrl);
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
        navigate("../");
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const updateOne = async ({ type, id, body } = {}) => {
    const addUrl = `${BaseUrl}/admins/${type}/${id}`;
    try {
      let res = await put(addUrl, body);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `${type} updated`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const deleteUser = async ({ type, ids: oldIds, q } = {}) => {
    const deletesUrl = `${BaseUrl}/admins/${type}`;
    const ids = Array.isArray(oldIds) ? oldIds : [oldIds];
    try {
      let res = await Delete(deletesUrl, {
        ids,
      });
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: "user deleted", type: "success" },
          }),
        );
        getAll({
          type,
          page:
            ids.length >= itemsList.length && currentPage === pageCount
              ? currentPage - 1
              : currentPage,
          q,
        });
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getExcel = async ({ name }) => {
    const excelUrl = `${BaseUrl}/admins/excels/${name}`;
    function downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = `${MainUrl}/${uri}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    try {
      let res = await get(excelUrl);
      if (res.status === ResponseStatus.Success) {
        downloadURI(res.data.excelPath, `${name}Excel`);
        dispatch(
          showAlert({
            notify: { message: "downloding excel", type: "success" },
          }),
        );
      } else {
        dispatch(
          showAlert({
            notify: { message: "downloding faild", type: "danger" },
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  return { getAll, getOne, addUser, deleteUser, getExcel, updateOne };
};

export default useUsers;

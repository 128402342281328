import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  title: {},
  describe: {
    color: "#98A2B3",
    marginBottom: "20px !important",
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.description.main,
    },
  },
}));

import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/AlertsSlice";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import usePut from "../usePut";
import useAuth from "./useAuth";
import useProfile from "./useProfile";
const useSettings = () => {
  const dispatch = useDispatch();
  const put = usePut();
  const { errorHandler } = useAuth();
  const { getUserInfo } = useProfile();
  const authUrl = (type) => `${BaseUrl}/admins/settings/${type}`;
  const updateSettings = async (type, body) => {
    const { oldPassword } = body;

    try {
      let res = await put(authUrl(type), {
        ...removeEmptyKeys(body),
        oldPassword: oldPassword ? oldPassword : undefined,
        confirmPassword: undefined,
      });
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `settings Updated`, type: "success" },
          }),
        );
        getUserInfo();
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return { updateSettings };
};

export default useSettings;

function removeEmptyKeys(obj) {
  Object.keys(obj).forEach(
    (key) =>
      (obj[key] === "" || obj[key] === null || obj[key] === undefined) &&
      delete obj[key],
  );
  return obj;
}

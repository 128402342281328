import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Icons from "../../../../assets/icons/Icons";
import useStyles from "./styles";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import useDate from "../../../../customHooks/hookRepositories/useDate";

export default function CommentsCard({
  user: { fullName, email, avatar },
  exhibition,
  salon,
  booth,
  userComment,
  isApproved,
  _id,
  createdAt,
  adminReply,
  onCheckboxChange,
  checked,
  onApprove,
  onDelete,
  onReply,

}) {
  const replyRef = useRef();
  const commentsRef = useRef();
  const classes = useStyles();
  const [expanded, setExpanded] = useState();
  const [expandedR, setExpandedR] = useState();
  const [isLong, setIsLong] = useState();
  const [isRLong, setIsRLong] = useState();
  const [reply, setReply] = useState();
  const [error, setError] = useState();
  const [replyValue, setReplyValue] = useState();
  const tv = useCustomTranslate("pages");
  const getDate = useDate();
  useEffect(() => {
    if (commentsRef) {
      if (commentsRef.current.clientHeight > 40) {
        setIsLong(true)
      }
    }
    if (replyRef.current) {
      if (replyRef.current.clientHeight > 40) {
        setIsRLong(true)
      }
    }
  }, [commentsRef, adminReply, replyRef, userComment])
  useEffect(() => {
    if (reply) {
      setReply(false)
    }
    // eslint-disable-next-line
  }, [])

  const approveHandler = () => {
    onApprove && onApprove([_id])
  }
  const deleteHandler = () => {
    onDelete && onDelete([_id])
  }
  return (
    <Grid item className={classes.commentsWrapper}>
      <Card sx={{ display: "flex", flexGrow: 1, borderRadius: "20px" }}>
        <Grid item sx={{ display: "flex", alignItems: "flex-start" }}>
          <Checkbox
            sx={{ mt: 2 }}
            checked={checked.includes(_id)}
            onChange={() => onCheckboxChange(_id)}
          />
        </Grid>
        <Grid
          item
          sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
        >
          <CardHeader
            classes={{
              subheader: classes.subheader,
              avatar: classes.avatar
            }}
            sx={{ p: 1.5, display: "flex", flexGrow: 1, gap: 2 }}
            avatar={<Avatar sx={{ width: 50, height: 50 }} src={avatar} alt="" />}
            title={
              <div className={classes.headerTitle}>
                <div className={classes.nameWrapper}>
                  <div>{fullName}</div>
                  <div className={classes.email}>{email}</div>
                  {isApproved && <div className={classes.approved}>{tv('approved')}</div>}
                </div>
                <div className={classes.date}>{`${getDate(createdAt).format("YYYY/MM/DD")} ${tv("at")} ${getDate(createdAt).format("HH:mm")}`}</div>
              </div>
            }
            subheader={`${exhibition.exhibitionName} | ${salon.name} | ${booth.name}`}
          />
          <CardContent sx={{ pb: 0 }}>
            <Collapse collapsedSize={42} in={expanded} sx={{ position: "relative" }} >
              <Typography
                variant="body2"
                color="text.secondary"
                maxWidth={687}
                position="relative"
                ref={commentsRef}
              >
                {userComment}
              </Typography>
              {!expanded && isLong && <div className={classes.textShdow} />}
            </Collapse>
            {isLong && (
              <Button
                variant="text"
                onClick={() => setExpanded(!expanded)}
                sx={{ width: "70px", justifyContent: "space-evenly" }}
              >
                {expanded ? "Less" : "More"}
                <Icons.ArrowDown
                  className={classes.moreIcon}
                  style={{
                    transform: expanded && "rotate(180deg)",
                  }}
                />
              </Button>)}
            {adminReply && <div className={classes.replyText} >
              <div>
                <Icons.ReplySolid />
              </div>
              <div>
                <Collapse collapsedSize={42} in={expandedR} sx={{ position: "relative" }} >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    maxWidth={687}
                    position="relative"
                    ref={replyRef}
                  >
                    {adminReply}
                  </Typography>
                  {!expandedR && isRLong && <div className={classes.textShdow} />}
                </Collapse>
                {isRLong && (
                  <Button
                    variant="text"
                    onClick={() => setExpandedR(!expandedR)}
                    sx={{ width: "70px", justifyContent: "space-evenly" }}
                  >
                    {expandedR ? "Less" : "More"}
                    <Icons.ArrowDown
                      className={classes.moreIcon}
                      style={{
                        transform: expandedR && "rotate(180deg)",
                      }}
                    />
                  </Button>)}
              </div>
            </div>}
          </CardContent>
          <CardActions sx={{ pt: 0 }}>
            {!isApproved && (
              <Button variant="text" color="success" onClick={approveHandler}>
                {tv('approve')}
              </Button>
            )}
            {isApproved && (
              <Button
                variant="text"
                onClick={() => setReply(!reply)}
                color="description"
              >
                {tv('reply')}
              </Button>
            )}
            <Button variant="text" color="error" onClick={deleteHandler}>
              {tv('delete')}
            </Button>
          </CardActions>
        </Grid>
      </Card>
      <Collapse in={reply}>
        <Grid sx={{
          display: "flex", flexGrow: 1,
          padding: "14px 30px",
          gap: 1,
          alignItems: "flex-end"
        }}>
          <TextField
            placeholder={tv('replyPlaceholder')}
            multiline
            className={classes.replyInput}
            onChange={(e) => setReplyValue(e.target.value)}
            error={error}
            helperText={error}
          />
          <Button
            sx={{ display: "flex", gap: 1 }}
            onClick={() => {
              if (replyValue) {
                setError()
                if (replyValue.length > 300) {
                  setError("too many characters")
                  return
                }
                setReply(false);
                onReply && onReply({ replyText: replyValue, id: _id });
              }
            }}
          >
            {tv('send')}<Icons.Send />
          </Button>
        </Grid>
      </Collapse>
    </Grid>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    overflow: "auto",
    padding: "0px 50px 30px !important",
    textAlign: "justify",
    "&>img": {
      width: "95%",
      margin: "auto",
      display: "block",
      height: "100%",
      minWidth: 800,
    },
  },
}));

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddForm from "../../../../components/AddForm/AddForm";
import AddFile from "../../../../components/AddForm/components/AddFile/AddFile";
import AddIcon from "../../../../components/AddForm/components/AddIcon/AddIcon";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import { UploadFileType } from "../../../../configs/FormEnum";
import { MainUrl } from "../../../../services/Api";
import useGetConstant from "../../../../customHooks/hookRepositories/useGetConstant";
import useExhibitions from "../../../../customHooks/hookRepositories/useExhibitions";
import { useParams } from "react-router";
import RouteEnum from "../../../../navigation/RouteEnum";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import useStyles from "./styles";
import Limits from "../../../../configs/Limits";

const calendarStandard = [
  { name: "solar", value: "solar" },
  { name: "gregorian", value: "gregorian" },
];

export default function ExhibitionsAdd(props) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    sponsors: [],
    standBanners: [],
    advertisingTv: [],
    currencyId: "None",
    calendarStandard: "None",
    isActiveRadio: false,
    isActiveChatroom: false,
  });
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState([]);
  const { getConstants } = useGetConstant();
  const { addOne, getOne, update } = useExhibitions();
  const { id } = useParams();
  const tv = useCustomTranslate("forms");
  const tvPages = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Exhibitions",
        title: { name: `${id ? tv("edit") : tv("new")} ${tv("exhibition")}` },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tv("exhibitions"), href: RouteEnum.Exhibitions },
          { name: `${id ? tv("edit") : tv("new")} ${tv("exhibition")}` },
        ],
      }),
    );
    //eslint-disable-next-line
  }, [dispatch, tv]);

  useEffect(() => {
    const getCategoriesArray = async () => {
      await getConstants({
        name: "currencyName",
        type: "currency",
        src: "currencies?limit=1000",
      }).then((res) => {
        setCurrency(res);
      });
    };
    getCategoriesArray();

    const setData = async () => {
      const res = await getOne({ name: "exhibitions", id });
      delete res.currency;
      setFormData({
        ...formData,
        ...res,
      });
    };
    if (id) {
      setData();
    }
    //eslint-disable-next-line
  }, [id]);

  const submitHandler = (error) => {
    if (error) {
      return;
    }
    if (id) {
      update({
        type: "exhibitions",
        body: formData,
        id,
      });
    } else {
      addOne({
        type: "exhibitions",
        body: formData,
      });
    }
  };
  const inputHandler = (value, type) => {
    const newValue = { ...formData };
    newValue[type] = value;
    setFormData(newValue);
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 6,
        fields: [
          {
            width: [4, 8],
            valueId: "logo",
            title: tv("poster"),
            component: (
              <AddIcon
                valueId="logo"
                size="w:763px, h:1080px"
                src={formData.logo && `${MainUrl}${formData.logo}`}
                onChange={inputHandler}
                id="ExhibitionLogo"
              />
            ),
          },
          {
            width: [4, 8],
            valueId: "exhibitionName",
            title: tv("exhibitionName"),
            required: true,
            input: {
              placeholder: tv("exhibitionNamePlaceholder"),
            },
          },
          {
            width: [4, 8],
            valueId: "exhibitionMessage",
            title: tv("message"),
            required: true,
            input: {
              placeholder: tv("messagePlaceholder"),
              inputProps: { maxLength: Limits.characters.booths },
            },
          },
          {
            width: [4, 8],
            valueId: "category",
            title: tv("category"),
            required: true,
            input: {
              placeholder: tv("categoryPlaceholder"),
            },
          },
          {
            width: [4, 8],
            valueId: "currencyId",
            title: tv("currency"),
            required: true,
            select: {
              placeholder: tv("currencyPlaceholder"),
              children: [
                { name: tv("currencyPlaceholder"), value: "None" },
              ].concat(currency),
            },
          },
          {
            width: [4, 8],
            valueId: "calendarStandard",
            title: tv("calendarStandard"),
            required: true,
            select: {
              placeholder: tv("currencyPlaceholder"),
              children: [
                { name: tv("currencyPlaceholder"), value: "None" },
              ].concat(
                calendarStandard.map((x) => ({ ...x, name: tv(x.name) })),
              ),
            },
          },
        ],
      },
    },
    {
      col: {
        direction: "column",
        width: 6,
        fields: [
          {
            width: [2, 10],
            flexDirection: "column",
            valueId: "mapImage",
            title: tv("exhibitionMap"),
            subTitle: tv("exhibitionMapDesc"),
            required: false,
            uploader: {
              fullWidth: true,
              fileType: UploadFileType.image,
              placeholder: "Drag a file here or Select from your device",
              description: `( ${tv("bestSize")} w:800px, h:600px )`,
            },
          },
        ],
      },
    },
  ];
  const afterForm = [
    <AddFile
      onChange={inputHandler}
      valueId="standBanners"
      value={formData.standBanners}
      key="Stand Banners"
      uploadProps={{
        description: `( ${tv("bestSize")} w:720px, h:1440px )`,
      }}
      ratio={1 / 2}
      title={tv("standBanners")}
      description={tv("standBannersDesc")}
      placeholder="Select a stand banner image"
      fileType={UploadFileType.image}
      limit={2}
    />,
    <AddFile
      onChange={inputHandler}
      valueId="advertisingTv"
      value={formData.advertisingTv}
      key="Advertising TV"
      title={tv("advTv")}
      description={tv("advTvDesc")}
      placeholder="Select a video for advertising tv"
      fileType={UploadFileType.video}
      limit={4}
    />,
    <AddFile
      onChange={inputHandler}
      valueId="radio"
      value={formData.radio}
      key="Exibition Radio"
      title={tv("exhibitionRadio")}
      description={tv("exhibitionRadioDesc")}
      placeholder="Select a audio for exhibition radio"
      fileType={UploadFileType.audio}
      switchBtn={{
        title: tvPages("active"),
        checked: formData.isActiveRadio,
        onChange: (e) => inputHandler(e.target.checked, "isActiveRadio"),
      }}
    />,
    //tv
    <AddFile
      onChange={inputHandler}
      valueId="sponsors"
      limit={10}
      uploadProps={{
        width: 150,
        height: 150,
        title: tv("selectLogo"),
        description: (
          <>
            {tv("bestSize")}
            <div>w:720 px, h:720 px </div>
          </>
        ),
        className: classes.upload,
      }}
      value={formData.sponsors}
      key="SponsorsLogo"
      title={tv("sponsorsLogo")}
      description={tv("sponsorsLogoDesc")}
      placeholder={tv("selectLogo")}
      fileType={UploadFileType.image}
    />,
    <AddFile
      key="Chat Room"
      title={tv("chatRoom")}
      description={tv("chatRoomDesc")}
      switchBtn={{
        title: tvPages("active"),
        checked: formData.isActiveChatroom,
        onChange: (e) => inputHandler(e.target.checked, "isActiveChatroom"),
      }}
    />,
  ];
  return (
    <div>
      <AddForm
        value={formData}
        imageWarning
        form={form}
        title={tv("exhibition")}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
        afterForm={afterForm}
      />
    </div>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  addUser: {
    padding: "4px 8px !important",
    margin: "0 !important",
    borderRadius: "10px !important"
  },
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: 95,
    fontSize: 14,
  },
}));

import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Icons from "../../../assets/icons/Icons";
import useStyles from "./styles";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import { useTranslation } from "react-i18next";

export default function SearchUi(props) {
  var classes = useStyles();
  const { onChange, className, value } = props;
  const tv = useCustomTranslate("pages");
  const { t } = useTranslation();
  const isRtl = t("dir") === "rtl";
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    if (value !== undefined) {
      if (value !== search) {
        setSearch(value);
        setSearchValue(value);
      }
    }
    //eslint-disable-next-line
  }, [value]);

  const searchHandler = (e) => {
    if (searchValue !== search) {
      if (e.keyCode) {
        if (e.keyCode === 13) {
          setSearchValue(search);
          onChange && onChange(search);
        }
        return;
      }
      setSearchValue(search);
      onChange && onChange(search);
    }
  };
  const cleanHandler = () => {
    setSearch("");
    setSearchValue("");
    onChange && onChange("");
  };
  const isClose = searchValue === search && searchValue;
  return (
    <TextField
      {...props}
      className={[classes.search, className].join(" ")}
      placeholder={`${tv("search")}...`}
      variant="standard"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onKeyDown={searchHandler}
      InputProps={{
        disableUnderline: true,
        sx: { fontSize: 13 },
        endAdornment: (
          <InputAdornment position={isRtl ? "start" : "end"}>
            <IconButton
              onClick={isClose ? cleanHandler : searchHandler}
              className={classes.searchBtn}
              sx={{
                backgroundColor: `${
                  searchValue === search ? "transparent" : "rgb(0 0 0 / 4%)"
                } !important`,
              }}
            >
              {isClose ? <Icons.Close /> : <Icons.Search />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

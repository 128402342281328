import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ButtonUi from "../../components/BaseElement/ButtonUi/ButtonUi";
import UseCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import { setToolbar } from "../../redux/ToolbarSlice";
import useStyles from "./styles";

export default function NotFound(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setToolbar({}));
  }, [dispatch]);
  const tv = UseCustomTranslate("error");

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>404</div>
      <Link to={"/"}>
        <ButtonUi>{tv("notFoundBtn")}</ButtonUi>
      </Link>
    </div>
  );
}

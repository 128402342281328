import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  price: {
    "& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  catalogue: {
    padding: 8,
    borderRadius: 20,
    border: "2px dashed rgba(152, 162, 179, 0.4)",
    display: "flex",
    // flexDirection: "column",
    gap: "5px",
  },
  catalogueCard: {
    flex: "unset",
    gap: 5,
    "&>div": {
      gap: 5,
      "&>p": {
        margin: 0,
      },
    },
  },
  catalogueCardUrlFront: {
    "&>div:nth-child(2)": {},
  },
}));

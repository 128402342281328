import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  newLanguageWrapper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  headerForm: {
    position: "sticky",
    top: 120,
    display: "flex",
    flexGrow: 1,
    gap: 36,
    paddingBottom: 27,
    borderBottom: "1px solid #98A2B34D",
    backgroundColor: theme.palette.background.main,
    zIndex: 4,
    [theme.breakpoints.down("lg")]: {
      zIndex: 1,
      position: "relative",
      top: "unset",
      gap: 20,
      flexWrap: "wrap",
    },
  },
  formItem: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      flex: "1!important",
      minWidth: "200px",
      width: "100%",
    },
  },
  selectWrapper: {
    width: "100%",
    "& > fieldset": {
      borderRadius: "12px !important",
    },
  },
  select: {
    borderRadius: "12px !important",
    backgroundColor: `${theme.palette.inputBackground.light} !important`,
    padding: "7px 36px 7px 15px !important",
  },
  Autocomplete: {
    "& > div >div": {
      borderRadius: "12px !important",
      backgroundColor: `${theme.palette.inputBackground.light} !important`,
      padding: "0px 36px 0px 15px !important",
    },
  },
  countrImg: {
    width: 30,
    height: 20,
  },
  formTitle: {},
  headerWrapper: {
    zIndex: 4,
    position: "sticky",
    top: 187,
    padding: "15px 0px 0px",
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down("lg")]: {
      zIndex: 1,
      position: "relative",
      top: "unset",
    },
  },
  input: {
    alignItems: "center",
    padding: "5px 15px !important",
    background: theme.palette.tableBgColor.main,
    borderRadius: 15,
    backgroundColor: theme.palette.tableBgColor.main,
    margin: "0 !important",
    "& input": {
      backgroundColor: theme.palette.tableBgColor.main,
    },
  },
  langSection: {
    display: "flex",
    flexDirection: "column",
    "&:not(:first-child)>div:first-child": {
      borderTop: "1px solid #98A2B399",
    },
  },
  langSectionTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    padding: "20px 10px",
    marginBottom: 5,
    cursor: "pointer",
  },
  arrow: {
    width: 20,
    height: 20,
  },
  activeArrow: {
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  langSectionBody: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    flexGrow: 1,
    marginBottom: 10,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.tableBgColor.main,
    flexGrow: 1,
    padding: "15px 20px",
    borderRadius: 20,
    position: "relative",
    "&::before": {
      content: "''",
      width: 1,
      height: "100%",
      backgroundColor: "#98A2B399",
      position: "absolute",
      top: 0,
      left: "calc(50% - 1px)",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  cardTitle: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  cardInputWrapper: {
    display: "flex",
    flex: 1,
  },
  cardInput: {
    "& > div": {
      padding: 10,
    },
  },
}));

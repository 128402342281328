import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 20,
    padding: "0 10px",
    color: "#fff",
    textAlign: "center",
    gap: 6,
    width: 110,
    height: 120,
    [theme.breakpoints.down("lg")]: {
      padding: "0 2px",
      width: 50,
      borderRadius: 8,
      height: 70,
    },
    [theme.breakpoints.down("sm")]: {
      gap: 2,
      width: 56,
    },
  },
  price: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "left",
    direction: "ltr",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  mobilePrice: {
    display: "none",
    justifyContent: "flex-end",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "left",
    direction: "ltr",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
    },
  },
  bigCard: {
    width: 230,
    height: 276,
    [theme.breakpoints.down("lg")]: {
      width: 85,
      height: 102,
    },
    [theme.breakpoints.down("sm")]: {
      width: 55,
    },
  },
  cardTitle: {
    fontSize: 14,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  cardDescription: {
    fontWeight: 500,
    display: "flex",
    fontSize: 15,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  currencySymbol: {
    fontSize: 14,
    paddingLeft: 5,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  irrCurrencySymbol: {
    fontSize: 14,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
}));

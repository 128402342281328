import React from "react";
import { Route, Routes } from "react-router";
import BuyBooth from "./components/BuyBooth/BuyBooth";
import BoothAdd from "../../../Booths/components/BoothAdd/BoothAdd";
import MyBoothsList from "./components/MyBoothsList";
import Chat from "../../../Booths/components/Chat/Chat";
import NotFound from "../../../NotFound/NotFound";
// import Chat from "./components/Chat";

export default function MyBooths(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<MyBoothsList />} />
      <Route path={"buy"} element={<BuyBooth />} />
      <Route
        path={"edit/:exhibitionId/:salonId/:id"}
        element={<BoothAdd type="ex" />}
      />
      <Route path={"chat/:id"} element={<Chat type="ex" />} />
    </Routes>
  );
}

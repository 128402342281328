import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  notifications: {
    padding: "35px 15px 5px",
    color: theme.palette.MessagesBoothBtnColor.contrastText,
    fontSize: 18,
    fontWeight: 600,
  },
  upload: {
    gap: 0,
    "&>div": {
      gap: 15,
    },
  },
}));

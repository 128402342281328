import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import useStyles from "./styles";
import { Link, Outlet } from "react-router-dom";
import Header from "../../Layout/components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import RouteEnum from "../../../navigation/RouteEnum";
import UseCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import Icons from "../../../assets/icons/Icons";
import useAuth from "../../../customHooks/hookRepositories/useAuth";
import { setToolbar } from "../../../redux/ToolbarSlice";
import PrivacyModal from "../../Modals/PrivacyModal/PrivacyModal";

export default function LoginLayout() {
  var classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isLogin = pathname === "/login" || pathname === "/";
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(setToolbar({}));
  }, [dispatch]);
  const { mainLink } = useSelector((s) => s.roles);
  useEffect(() => {
    if (isAuthenticated) {
      mainLink && navigate(mainLink);
    } else if (pathname === "/") {
      navigate(RouteEnum.Login);
    }
  }, [isAuthenticated, navigate, pathname, mainLink]);
  const tv = UseCustomTranslate("login");
  const { errorHandler } = useAuth();
  return (
    <div className={classes.layout}>
      <PrivacyModal />
      <Container>
        <Header login />
        <Grid container className={classes.container}>
          <div className={classes.formContainer}>
            <Outlet />
            {!isLogin && (
              <div className={classes.goBackWrapper}>
                <div className={classes.goBack} onClick={() => errorHandler()}>
                  <Link to="/login" className={classes.goBackLink}>
                    <Icons.BackSquare /> {tv("back")}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Container>
    </div>
  );
}

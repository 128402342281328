import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems:'start',
      gap:8
    },
  },
  nameWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems:'start'
    },
  },
  email: {
    fontSize: 12,
    color: theme.palette.description.main,
    display: "flex",
    alignItems: "flex-end",
  },
  approved: {
    padding: "2px 10px",
    borderRadius: 20,
    backgroundColor: theme.palette.success.main,
    fontSize: 12,
    color: theme.palette.success.contrastText,
  },
  date: {
    fontSize: 12,
    color: theme.palette.description.main,
    display: "flex",
  },
  subheader: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: `12px !important`,
  },
  textShdow: {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
    position: "absolute",
    width: "100%",
    height: 25,
    top: 14,
    maxWidth: 687,
  },
  replyInput: {
    display: "flex",
    flexGrow: 1,
    "& > div": {
      padding: "6px 4px",
    },
  },
  moreIcon: {
    marginLeft: "4px",
    "& path": {
      stroke: "#755EFC !important",
    },
  },
  replyText: {
    display: "flex",
    flexDirection: "row",
    marginTop: 12,
    padding: "15px 0",
    gap: 15,
    borderTop: `1px solid ${theme.palette.headerLine.main}4d`,
  },
  commentsWrapper: {
    display: "flex",
    flexGrow: 1,
    borderRadius: "20px",
    margin: `0 10px !important`,
    flexDirection: "column !important",
    backgroundColor: theme.palette.ReplyBg.main,
  },
  avatar: {
    margin: "0 !important"
  }
}));

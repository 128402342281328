import { Typography, Grid, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import UseCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import { showModal } from "../../../../redux/ModalSlice";
import BoxUi from "../../../BaseElement/BoxUi/BoxUi";
import SwitchUi from "../../../BaseElement/SwitchUi/SwitchUi";
import AddUploadUi from "../AddUploadUi/AddUploadUi";
import useStyles from "./styles";
import { Close, Warning } from "@mui/icons-material";

export default function AddFile({
  title,
  imageWarning,
  placeholder,
  description,
  fileType,
  switchBtn,
  value,
  onChange,
  valueId,
  limit,
  disabled,
  unlimited,
  children,
  sx,
  uploadProps,
  preview,
  ratio,
}) {
  const tv = UseCustomTranslate("forms");
  const callBack = (url) => {
    if (Array.isArray(value)) {
      onChange([...value, url], valueId);
    } else {
      onChange(url, valueId);
    }
  };
  const deleteHandler = (iv) => {
    if (Array.isArray(value)) {
      onChange(
        value.filter((v, i) => i !== iv),
        valueId,
      );
    } else {
      onChange("", valueId);
    }
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const previewHandler = () => {
    dispatch(showModal({ open: "previewModal", preview }));
  };
  const [open, setOpen] = useState(true)
  return (
    <BoxUi
      className={classes.addFormWrapper}
      disabled={disabled || limit === 0}
      sx={sx}
    >
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          {open && imageWarning && <div className={classes.ImageTypeWarningWrapper}>
            <div className={classes.ImageTypeWarning}>
              <Warning sx={{ fontSize: 12 }} />
              {tv("imageWarning")}
            </div>
            <IconButton onClick={() => setOpen(false)} ><Close sx={{ fontSize: 12 }} /></IconButton>
          </div>}
          <Typography className={classes.title}>
            {title}
            {(limit || unlimited) && (
              <span className={classes.limit}>
                ( {unlimited ? tv("unlimited") : `${tv("limit")}: ${limit}`} )
              </span>
            )}
          </Typography>
          <Typography
            className={classes.description}
            color="description"
            xs={{ fontSize: 12 }}
          >
            {description}
          </Typography>
        </div>
        {switchBtn && (
          <div className={classes.Switch}>
            {switchBtn.title && <div>{switchBtn.title}:</div>}
            {delete switchBtn.title}
            <SwitchUi {...switchBtn} />
          </div>
        )}
        {preview?.length > 0 && (
          <div>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                // padding: "12px 20px",
                borderRadius: 2.5,
              }}
              onClick={previewHandler}
            >
              {tv("preview")}
            </Button>
          </div>
        )}
      </div>
      {fileType && (
        <Grid container className={classes.filesWrapper}>
          {value &&
            (Array.isArray(value) ? (
              value.map((url, i) => (
                <Grid item sx={{ display: "flex" }} key={i}>
                  <AddUploadUi
                    ratio={ratio}
                    fileType={fileType}
                    url={url}
                    callBack={() => deleteHandler(i)}
                    {...uploadProps}
                  />
                </Grid>
              ))
            ) : (
              <Grid item sx={{ display: "flex" }}>
                <AddUploadUi
                  ratio={ratio}
                  fileType={fileType}
                  url={value}
                  callBack={() => deleteHandler(value)}
                  {...uploadProps}
                />
              </Grid>
            ))}
          {Array.isArray(value) ? (
            limit ? (
              value.length <= limit - 1 && (
                <Grid item sx={{ display: "flex" }}>
                  <AddUploadUi
                    ratio={ratio}
                    callBack={callBack}
                    placeholder={placeholder}
                    id={valueId}
                    fileType={fileType}
                    {...uploadProps}
                  />
                </Grid>
              )
            ) : (
              <Grid item sx={{ display: "flex" }}>
                <AddUploadUi
                  ratio={ratio}
                  callBack={callBack}
                  placeholder={placeholder}
                  id={valueId}
                  fileType={fileType}
                  {...uploadProps}
                />
              </Grid>
            )
          ) : (
            !value && (
              <Grid item sx={{ display: "flex" }}>
                <AddUploadUi
                  ratio={ratio}
                  callBack={callBack}
                  placeholder={placeholder}
                  id={valueId}
                  fileType={fileType}
                  {...uploadProps}
                />
              </Grid>
            )
          )}
        </Grid>
      )}
      {children}
    </BoxUi>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LanguageSelect from "../LanguageSelect";
import UserMenu from "../UserMenu";
import useStyles from "./styles";
import AddUserBtn from "../AddUserBtn";
import { Box, Button } from "@mui/material";
import ExcelBtn from "../ExcelBtn/ExcelBtn";
import Icons from "../../../../assets/icons/Icons";
import { showModal } from "../../../../redux/ModalSlice";
import BreadcrumbsUi from "../BreadcrumbsUi/BreadcrumbsUi";
import { setDrawerMenu } from "../../../../redux/DrawerMenuSlice";

// import AdvanceSearch from "../AdvanceSearch";

export default function Header({ login }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { title, leftElements, rightElements } = useSelector((s) => s.toolbar);
  const { name, icon } = title ? title : {};
  const Icon = Icons[icon];
  const { drawerMenu } = useSelector((s) => s);
  return (
    <>
      <div className={classes.container}>
        <Box className={classes.headerWrapper} sx={{ mt: "25px", mb: "14px" }}>
          <div className={classes.headerItems}>
            {name && (
              <div className={classes.pageTitle}>
                {icon && (
                  <div className={classes.titleIcon}>
                    <Icon />
                  </div>
                )}
                {name}
              </div>
            )}
            {leftElements && leftElements.length > 0 && (
              <React.Fragment>
                <div className={classes.headerLine} />
                {/* <AdvanceSearch /> */}
                {leftElements.map(({ type, name, Link, res }, i) => {
                  let element;
                  switch (type) {
                    // case "AdvanceSearch":
                    //   element = <AdvanceSearch key={i} />;
                    //   break;
                    case "ButtonUi":
                      element = (
                        <AddUserBtn
                          className={res && classes.resShow}
                          name={name}
                          key={i}
                          link={Link}
                        />
                      );
                      break;
                    case "UploadBtn":
                      element = (
                        <AddUserBtn
                          name={name}
                          key={i}
                          link={"#"}
                          icon={<Icons.SendSquare />}
                          onClick={() => {
                            dispatch(
                              showModal({
                                open: "UploadModal",
                                justUpload: true,
                              }),
                            );
                          }}
                        />
                      );
                      break;
                    default:
                  }
                  return element;
                })}
              </React.Fragment>
            )}
          </div>
          <div className={classes.headerItems}>
            {rightElements &&
              rightElements.length > 0 &&
              rightElements.map(({ type, name }, i) => {
                let element;
                switch (type) {
                  case "Excel":
                    element = <ExcelBtn key={i} name={name} />;
                    break;
                  default:
                }
                return element;
              })}
            {isAuthenticated && <UserMenu />}
            <LanguageSelect />
          </div>
        </Box>
        <BreadcrumbsUi />
      </div>
      <div className={classes.responsive}>
        <Box className={classes.headerWrapper} sx={{ mt: "15px", mb: "15px" }}>
          {!login && (
            <Button
              onClick={() => {
                dispatch(setDrawerMenu(!drawerMenu));
              }}
              className={classes.menuBtn}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 8.16699H24.5"
                  stroke="#475467"
                  strokeOpacity="0.8"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M3.5 14H24.5"
                  stroke="#475467"
                  strokeOpacity="0.8"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M3.5 19.833H24.5"
                  stroke="#475467"
                  strokeOpacity="0.8"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
              </svg>
            </Button>
          )}
          <div>
            {name && (
              <div className={classes.pageTitle}>
                {icon && (
                  <div className={classes.titleIcon}>
                    <Icon />
                  </div>
                )}
                {name}
              </div>
            )}
          </div>
          <div className={classes.headerItems}>
            <LanguageSelect />
          </div>
        </Box>
        {leftElements && leftElements.length > 0 && (
          <React.Fragment>
            <Box
              className={classes.headerWrapper}
              sx={{ mt: "15px", mb: "15px" }}
            >
              <div
                className={[classes.headerItems, classes.headerItemsBtn].join(
                  " ",
                )}
              >
                <div className={classes.headerLine} />
                {/* <AdvanceSearch /> */}
                {leftElements.map(({ type, name, Link, res }, i) => {
                  let element;
                  switch (type) {
                    // case "AdvanceSearch":
                    //   element = <AdvanceSearch key={i} />;
                    //   break;
                    case "ButtonUi":
                      element = (
                        <AddUserBtn
                          className={res && classes.resShow}
                          name={name}
                          key={i}
                          link={Link}
                        />
                      );
                      break;
                    case "UploadBtn":
                      element = (
                        <AddUserBtn
                          name={name}
                          key={i}
                          link={"#"}
                          icon={<Icons.SendSquare />}
                          onClick={() => {
                            dispatch(
                              showModal({
                                open: "UploadModal",
                                justUpload: true,
                              }),
                            );
                          }}
                        />
                      );
                      break;
                    default:
                  }
                  return element;
                })}
              </div>
            </Box>
          </React.Fragment>
        )}
        <BreadcrumbsUi />
      </div>
    </>
  );
}

import { BaseUrl, ResponseStatus } from "../../services/Api";
import useGet from "../useGet";

const useSalons = () => {
  const fetchGet = useGet();
  const salonBaseUrl = `${BaseUrl}/admins/salons/`;
  const getAvailableBoothDesigns = async (salonId) => {
    try {
      // var res = mock_getAvailableBoothDesigns;
      var res = await fetchGet(salonBaseUrl + salonId + "/booth-designs")
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return { getAvailableBoothDesigns };
};

export default useSalons;

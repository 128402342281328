import { createSlice } from "@reduxjs/toolkit";

const RolesSlice = createSlice({
  name: "roles",
  initialState: {},
  reducers: {
    setRole(state, action) {
      return action.payload;
    },
  },
});

export const { setRole } = RolesSlice.actions;
export default RolesSlice.reducer;

const RouteEnum = {
  // Register
  Login: "/login",
  Register: "/register",
  ForgotPassword: "/forgetPassword",
  EmailConfirm:"/EmailConfirm",
  // Register
  // admins
  admin: "/admin",
  dashboard: "",
  user: {
    Organizers: `users/Organizers`,
    Exhibitors: `users/Exhibitors`,
    Visitors: `users/Visitors`,
  },
  Exhibitions: `Exhibitions`,
  Events: "eventList",
  Comments: `Comments`,
  Files: `Files`,
  Analysis: `Analysis`,
  Language: `Language`,
  Settings: "settings",
  Salons: (v) => `salons/${v ? v : ":exhibitionId"}`,
  Booths: (x, y) => `Booths/${x ? `${x}/${y}` : ":exhibitionId/:salonId"}`,
  Edit: (id) => `edit/${id ? id : ":id"}`,
  // admins
  //exhibitors
  exhibitor:"/exhibitor",
  MyBooths: "MyBooths",
  //exhibitors
  //Organizers
  Organizer:"/Organizer",
  Exhibition: "Exhibition",
  //Organizers
};

export default RouteEnum;

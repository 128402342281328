import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setToolbar } from "../../../redux/ToolbarSlice";
import useEvents from "../../../customHooks/hookRepositories/useEvents";
import BaseCrudEvent from "./BaseCrudEvent";
import * as moment from "moment";
import RouteEnum from "../../../navigation/RouteEnum";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

export default function NewEvent(props) {
  const [formData] = useState({
    banner: "",
    exhibitionId: "0",
    dateUi: moment(),
    link: "",
    name: "",
    speakerName: "",
  });
  const tv = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");

  const dispatch = useDispatch();
  const { createEvent } = useEvents();

  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Events",
        title: { name: `${tv("new")} ${tv("event")}` },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("events"), href: RouteEnum.Events },
          { name: `${tv("new")} ${tv("event")}` },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);

  const submitHandler = (data) => {
    createEvent(data);
  };

  return <BaseCrudEvent callback={submitHandler} data={formData} />;
}

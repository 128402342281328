import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import map from "../../../assets/images/exMap.png";
import { showModal } from "../../../redux/ModalSlice";
import "swiper/css/navigation";
import UseCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Icons from "../../../assets/icons/Icons";

export default function MapViewModal() {
  const classes = useStyles();
  const tv = UseCustomTranslate("forms");
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.modal);
  const handleClose = () => {
    dispatch(showModal({ open: false }));
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open === "mapViewModal"}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          fontWeight: "bolder",
          // cursor: "move",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      // id="draggable-dialog-title"
      >
        {tv("exhibitionMap")}
        <Icons.Close onClick={handleClose} style={{ cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent className={classes.wrapper} >
        <img src={map} alt="Map" />
      </DialogContent>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import useEvents from "../../../customHooks/hookRepositories/useEvents";
import { useParams } from "react-router";
import BaseCrudEvent from "./BaseCrudEvent";
import { setToolbar } from "../../../redux/ToolbarSlice";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import RouteEnum from "../../../navigation/RouteEnum";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

export default function EditEvent(props) {
  const [formData, setFormData] = useState({
    banner: "/files/2022-05/images/68215648b4c0ea005a2630706ed1a716.png",
    exhibitionId: "0",
    dateUi: moment(),
    link: "",
    name: "",
    speakerName: "",
  });

  const { updateEvent, getEvent } = useEvents();
  const { id } = useParams();
  const dispatch = useDispatch();

  const tv = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    const fetchEvent = async (id) => {
      let res = await getEvent(id);
      setFormData(res);
    };
    if (id) {
      fetchEvent(id);
    }
    //eslint-disable-next-line
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Events",
        title: { name: `${tv("edit")} ${tv("event")}` },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("events"), href: RouteEnum.Events },
          { name: `${tv("edit")} ${tv("event")}` },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);

  const submitHandler = (data) => {
    updateEvent(id, data);
  };

  return <BaseCrudEvent callback={submitHandler} data={formData} />;
}

import axios from "axios";
import { ResponseStatus } from "../services/Api";
import { addLoader, deleteLoader } from "../redux/LoadingSlice";
import { useDispatch, useSelector } from "react-redux";

const useGet = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((s) => s.auth);
  async function fetchGet(url, jwt, header = {}) {
    dispatch(addLoader("Get:" + url));

    const _headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      _headers.authorization = token;
    }
    if (jwt) {
      _headers.authorization = jwt;
    }

    return new Promise((res) => {
      axios
        .get(url, {
          headers: Object.assign(header, _headers),
          timeout: 99999999999999999999,
        })
        .then((response) => {
          dispatch(deleteLoader("Get:" + url));
          res(
            Object.assign(
              { status: ResponseStatus.Success },
              { data: response?.data?.data },
            ),
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(deleteLoader("Get:" + url));
          res(
            Object.assign(
              { status: ResponseStatus.Failed },
              error.response?.data ?? { message: error.message },
            ),
          );
        });
    });
  }

  return fetchGet;
};
export default useGet;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  select: {
    width: 121,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    backgroundColor: theme.palette.headerBtnBackground.main,
    paddingLeft: "8px !important",
    paddingRight: "0 !important",
    fontSize: "14px !important",
    [theme.breakpoints.down("lg")]: {
      width: "auto",
    },
    "& > fieldset": {
      display: "none",
    },
  },
  Adornment: {
    "& > p": {
      color: "#755EFC",
    },
  },
}));

import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.main,
        display: "flex",
        position: "sticky",
        top: 0,
        zIndex: "2",
        flexDirection: "column",
        [theme.breakpoints.down("lg")]: {
            display: "none",
        },
    },
    headerWrapper: {
        width: " 100%",
        height: "100%",
        display: "flex",
        justifyContent: " space-between",
        alignItems: "center",
        gap: 8,
    },
    resShow:{
        display: "none!important",
        [theme.breakpoints.down("lg")]: {
            display: "flex!important",
        },
    },
    headerWrapperMobile: {
        width: " 100%",
        height: "100%",
        display: "none",
        justifyContent: " space-between",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        },
    },
    headerItems: {
        gridGap: "8px",
        display: "grid",
        gridAutoFlow: "column",
        alignItems: "center",
    },
    headerItemsBtn:{
        width:'100%',
        "& .MuiButton-contained":{
            height:44,
        }
    },
    headerItem: {
        marginLeft: "10px",
    },
    pageTitle: {
        color: theme.palette.pageTitle.main,
        position: "relative",
        fontSize: 24,
        fontWeight: 700,
        display: "flex",
        gap: 12,
    },
    headerLine: {
        backgroundColor: theme.palette.headerLine.main,
        height: 21,
        width: 1,
        [theme.breakpoints.down("lg")]: {
            display:'none'
        },
    },
    exportText: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: 120,
        [theme.breakpoints.down("lg")]: {
            width: 100,
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    responsive: {
        backgroundColor: theme.palette.background.main,
        display: "flex",
        position: "sticky",
        top: 0,
        zIndex: "12",
        flexDirection: "column",
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    menuBtn: {
        background:'transparent!important',
        boxShadow:'none!important',
        width:'auto!important',
        padding:'0!important',
        margin:'0!important',
        minWidth:'28px!important'
    }
}));

import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import Icons from "../../../../../../../../assets/icons/Icons";
import useCustomTranslate from "../../../../../../../../customHooks/hookRepositories/useCustomTranslate";
import { showModal } from "../../../../../../../../redux/ModalSlice";
import LocationCard from "../LocationCard/LocationCard";
import useStyles from "./styles";

export default function BoothLocation({ disabled, booths, designs, currency }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const tv = useCustomTranslate("boothLocation");
  let newPosition = [];
  let cell = [];
  locationArray.forEach((x, i) => {
    cell[Math.floor(i / 4)] = cell[Math.floor(i / 4)]
      ? [...cell[Math.floor(i / 4)], x]
      : [x];
  });
  cell.forEach((x, i) => {
    newPosition[Math.floor(i / 2)] = newPosition[Math.floor(i / 2)]
      ? [...newPosition[Math.floor(i / 2)], x]
      : [x];
  });
  const clickHandler = (id) => {
    const booth = booths.filter((x) => x._id === id)[0];
    const design = designs.filter((x) => x._id === booth.boothDesign._id)[0];
    dispatch(
      showModal({ open: "BoothBuyModal", info: { booth, design, currency } }),
    );
  };
  const lastCell = newPosition.pop();
  const design =
    booths && booths.filter((x) => x?.boothDesign?.index === 25)[0];
  const bigCell = design ? design : lastCell[0][0];
  return !disabled ? (
    <Box className={classes.mapWrapper}>
      <div className={classes.mapInfo}>
        <div className={classes.infoWrapper}>
          <div
            className={classes.infoColor}
            style={{ backgroundColor: theme.palette.primary.main }}
          ></div>
          <div className={classes.infoTitle}>{tv("forSale")}</div>
        </div>
        <div className={classes.infoWrapper}>
          <div
            className={classes.infoColor}
            style={{ backgroundColor: theme.palette.addBoothBtnColor.main }}
          ></div>
          <div className={classes.infoTitle}>{tv("soldOut")}</div>
        </div>
        <div className={classes.infoWrapper}>
          <div
            className={classes.infoColor}
            style={{ backgroundColor: theme.palette.background.main }}
          ></div>
          <div className={classes.infoTitle}>{tv("notForSelling")}</div>
        </div>
      </div>
      <div dir="rtl" className={classes.map}>
        <div className={classes.mapIntro}>
          <div>
            <div className={classes.Card}>{tv("askMe")}</div>
          </div>
          <div>
            <Icons.Frame />
          </div>
          <div>
            <div className={classes.Card}>{tv("lobby")}</div>
          </div>
        </div>
        <div className={classes.mapBody}>
          {newPosition.map((x, positionIndex) => (
            <div className={classes.mapSection} key={positionIndex}>
              {x.map((y, i) => (
                <React.Fragment key={`${i} ${positionIndex}`}>
                  <div className={classes.mapCell}>
                    {y.map((z, zi) => {
                      const design =
                        booths &&
                        booths.filter(
                          (x) => x?.boothDesign?.index === z.boothDesign.index,
                        )[0];
                      const cardProps = design ? design : z;
                      return (
                        <LocationCard
                          {...cardProps}
                          currency={currency}
                          key={`${i} ${positionIndex} ${zi}`}
                          onClick={clickHandler}
                        />
                      );
                    })}
                  </div>
                  {positionIndex === 1 && i === 0 && (
                    <LocationCard
                      {...bigCell}
                      currency={currency}
                      onClick={clickHandler}
                      big
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Box>
  ) : (
    <div className={classes.noDataWrapper} to="buy">
      <Box sx={{ pb: "12px" }}>
        <Icons.ShopAdd />
      </Box>
      <div className={classes.title}>{tv("SelectExhibitionSalon")}</div>
    </div>
  );
}

const locationArray = [
  {
    _id: 1,
    boothDesign: { index: 1 },
  },
  {
    _id: 2,
    boothDesign: { index: 2 },
  },
  {
    _id: 3,
    boothDesign: { index: 3 },
  },
  {
    _id: 4,
    boothDesign: { index: 4 },
  },
  {
    _id: 5,
    boothDesign: { index: 5 },
  },
  {
    _id: 6,
    boothDesign: { index: 6 },
  },
  {
    _id: 7,
    boothDesign: { index: 7 },
  },
  {
    _id: 8,
    boothDesign: { index: 8 },
  },
  {
    _id: 9,
    boothDesign: { index: 9 },
  },
  {
    _id: 10,
    boothDesign: { index: 10 },
  },
  {
    _id: 11,
    boothDesign: { index: 11 },
  },
  {
    _id: 12,
    boothDesign: { index: 12 },
  },
  {
    _id: 13,
    boothDesign: { index: 13 },
  },
  {
    _id: 14,
    boothDesign: { index: 14 },
  },
  {
    _id: 15,
    boothDesign: { index: 15 },
  },
  {
    _id: 16,
    boothDesign: { index: 16 },
  },
  {
    _id: 17,
    boothDesign: { index: 17 },
  },
  {
    _id: 18,
    boothDesign: { index: 18 },
  },
  {
    _id: 19,
    boothDesign: { index: 19 },
  },
  {
    _id: 20,
    boothDesign: { index: 20 },
  },
  {
    _id: 21,
    boothDesign: { index: 21 },
  },
  {
    _id: 22,
    boothDesign: { index: 22 },
  },
  {
    _id: 23,
    boothDesign: { index: 23 },
  },
  {
    _id: 24,
    boothDesign: { index: 24 },
  },
  {
    _id: 25,
    boothDesign: { index: 25 },
  },
];

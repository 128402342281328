import {createSlice} from "@reduxjs/toolkit";

const persianDatepickerSlice = createSlice({
    name: 'datePicker',
    initialState: {
        endDate: '',
        endTime: '',
        startDate: '',
        startTime: ''
    },
    reducers: {
        setDateTime(state,action){
            return (action.payload);
        }
    }
})

export const {setDateTime} = persianDatepickerSlice.actions;
export default persianDatepickerSlice.reducer

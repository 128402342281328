import React from "react";
import { Route, Routes } from "react-router";
import ExhibitorsList from "./components/ExhibitorsList";
import AddExhibitors from "./components/AddExhibitors";
import EditExhibitors from "./components/EditExhibitors";
import NotFound from "../NotFound/NotFound";
export default function Exhibitors(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<ExhibitorsList />} />
      <Route path={"add"} element={<AddExhibitors />} />
      <Route path={"edit/:id"} element={<EditExhibitors />} />
    </Routes>
  );
}

import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import RouteEnum from "../../../navigation/RouteEnum";
import useStyles from "../styles";
import { useParams } from "react-router-dom";
import useExhibitions from "../../../customHooks/hookRepositories/useExhibitions";
import { MainUrl } from "../../../services/Api";
import Icons from "../../../assets/icons/Icons";
import DeleteModul from "../../../components/Modals/DeleteModul/DeleteModul";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import { useSelector } from "react-redux";

export default function BoothCard({
  logo,
  id,
  userViewedCount,
  isActive,
  boothName,
  description,
  query,
  activeBtn,
  exhibition,
  boothDesign,
  salon,
  adminUnreadMessage,
}) {
  const tv = useCustomTranslate("pages");
  const tvForms = useCustomTranslate("forms");
  const classes = useStyles();
  const { exhibitionId, salonId } = useParams();
  const { deleteOne, updateStatus } = useExhibitions();
  const { deleteModul } = DeleteModul();
  const deleteHandler = () => {
    deleteModul(() => {
      deleteOne({ type: "booths", id, query, exhibitionId, salonId });
    });
  };
  const statusHandler = () => {
    updateStatus({
      type: "booths",
      id,
      query,
      exhibitionId,
      salonId,
    });
  };
  const { dir } = useSelector((s) => s);
  const rtlClass = dir.dir === "rtl" ? classes.rtl : "";
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img
            src={logo ? `${MainUrl}${logo}` : "/assets/image/no_photo.png"}
            alt="logo"
          />
        </div>
        <div className={classes.headerText}>
          <div className={classes.name}>{boothName}</div>
          {description && (
            <div className={classes.description}>{description}</div>
          )}
          {activeBtn && (
            <div className={classes.exhibitionName}>
              {exhibition?.exhibitionName} | {salon?.name}
            </div>
          )}
        </div>
      </div>
      <div className={classes.describeWrapper}>
        <div className={classes.describe}>
          <div className={classes.describeTitle}>{tv("views")}:</div>
          <div>{userViewedCount.toLocaleString()}</div>
        </div>
        <div className={classes.describe}>
          <div className={classes.describeTitle}>{tv("status")}:</div>
          {isActive ? (
            <div className={classes.active}>
              <div className={classes.activeDot}></div>
              <div>{tv("active")}</div>
            </div>
          ) : (
            <div className={classes.active}>
              <div className={classes.notActiveDot}></div>
              <div>{tv("notActive")}</div>
            </div>
          )}
        </div>
        <div className={classes.describe}>
          <div className={classes.describeTitle}>{tvForms("boothDesign")}:</div>
          <div>{boothDesign}</div>
        </div>
      </div>
      <div className={classes.btnWrapper}>
        <Link
          to={
            activeBtn
              ? `edit/${exhibition?._id}/${salon?._id}/${id}`
              : RouteEnum.Edit(id)
          }
          className={classes.btnLink}
        >
          <Button variant="contained" color="addBoothBtnColor">
            {`${tv("edit")} ${tv("booth")}`}
          </Button>
        </Link>
        <Link to={`chat/${id}`} className={classes.btnLink}>
          <Button
            variant="contained"
            color="MessagesBoothBtnColor"
            className={[classes.messages, rtlClass].join(" ")}
            startIcon={
              adminUnreadMessage ? <Icons.SmsNotification /> : <Icons.Sms />
            }
          >
            {tv("messages")}
          </Button>
        </Link>
        {activeBtn ? (
          <Button
            color={isActive ? "description" : "primary"}
            onClick={statusHandler}
          >
            {isActive ? tv("deactivate") : tv("activate")}
          </Button>
        ) : (
          <Button onClick={deleteHandler} color="error">
            {tv("delete")}
          </Button>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setToolbar } from "../../redux/ToolbarSlice";
import TitelUi from "../../components/BaseElement/TitelUi";
import EventsView from "./components/EventsView";
// import ChartUi from "../../components/BaseElement/CharUi/CharUi";
import UseCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import useStyles from "./styles";
import BoxUi from "../../components/BaseElement/BoxUi/BoxUi";
import useDashboard from "../../customHooks/hookRepositories/useDashboard";
import { Box } from "@mui/system";
import ExhibitionsCard from "../Exhibitions/components/ExhibitionsCard";
import { Grid } from "@mui/material";
export default function Dashboard(props) {
  var classes = useStyles();
  const dispatch = useDispatch();
  const tv = UseCustomTranslate("dashboard");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Dashboard",
        title: {
          name: tv("dashboard"),
          icon: "Bubble",
        },
      }),
    );
  }, [dispatch, tv]);
  const [header, setHeader] = useState([]);
  const [{ activeExhibitions, activeEvents }, setDashboarData] = useState({});
  const { getDashboard } = useDashboard();
  const getDashboardCall = async () => {
    const res = await getDashboard();
    if (res) {
      const headerValue = { ...res };
      delete headerValue.activeExhibitions;
      delete headerValue.activeEvents;
      const headerArray = Object.keys(headerValue).map((key) => ({
        title: key,
        amount: headerValue[key],
      }));
      setHeader(headerArray);

      setDashboarData(res);
    }
  };
  useEffect(() => {
    getDashboardCall();
    // eslint-disable-next-line
  }, []);
  // activeExhibitions && activeExhibitions.pop();

  return (
    <div>
      <div className={classes.dashbordCardWrapper}>
        {header.length > 0 &&
          header.map(({ amount, title }) => (
            <div className={classes.dashbordCard} key={title}>
              <BoxUi>
                <div className={classes.chartSections} sx={{ flexGrow: 1 }}>
                  <div className={classes.title}>{tv(title)}</div>
                  <div className={classes.amount}>{amount}</div>
                </div>
              </BoxUi>
            </div>
          ))}
      </div>
      {/* <Grid container item xs={12} spacing={2} height={334}>
        <Grid item container xs={12} lg={6} spacing={2}>
          <Grid item sx={{ display: "flex", flexgrow: 1 }} xs={6}>
            <ChartUi amount="12,857" title="Today Visits" />
          </Grid>
          <Grid item sx={{ display: "flex", flexgrow: 1 }} xs={6}>
            <ChartUi amount="213" title="Active Booths" />
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={6} spacing={2}>
          <Grid item sx={{ display: "flex", flexgrow: 1 }} xs={12}>
            <ChartUi amount="$ 12,857" title="Today Revenue" row />
          </Grid>
          <Grid item sx={{ display: "flex", flexgrow: 1 }} xs={12}>
            <ChartUi amount="9 pm" title="Most Active Hour" row />
          </Grid>
        </Grid>
      </Grid> */}
      {activeEvents?.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <TitelUi
            title={tv("upcomingEvents")}
            link={{ title: tv("allEvents"), href: "eventList" }}
          />
          <EventsView events={activeEvents} />
        </Box>
      )}
      {activeExhibitions?.length > 0 && (
        <div>
          <TitelUi
            title={tv("activeExhibitions")}
            link={{ title: tv("allExhibitions"), href: "Exhibitions" }}
          />
          {/* <Box
            sx={{ display: "flex", gap: 1.5, flexGrow: 1, flexWrap: "wrap" }}
          > */}
                <Grid container spacing={2}>
            {activeExhibitions.map((x, i) => (
             
          <Grid key={x._id} item xs={12} md={6} lg={3} sx={{ display: "flex" }}> <ExhibitionsCard
                {...x}
                dashboard
                key={i}
                onStatus={getDashboardCall}
                onDelete={getDashboardCall}
              />
            </Grid>
            ))}
            </Grid>
          {/* </Box> */}
        </div>
      )}
    </div>
  );
}

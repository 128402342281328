import { Button, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import RouteEnum from "../../../navigation/RouteEnum";
import useStyles from "../styles";
import { useParams } from "react-router-dom";
import Icons from "../../../assets/icons/Icons";
import useExhibitions from "../../../customHooks/hookRepositories/useExhibitions";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import DeleteModul from "../../../components/Modals/DeleteModul/DeleteModul";

export default function SalonsCard({
  id,
  userViewedCount,
  isActive,
  salonsName,
  Booths,
  query,
  IsOrg,
}) {
  var classes = useStyles();
  const theme = useTheme();
  const { info } = useSelector((s) => s.auth);
  const { type } = useSelector((s) => s.roles);
  const params = useParams();
  const { exhibitionId } = IsOrg ? (info ? info.extraInfo : {}) : params;
  const tv = useCustomTranslate("pages");

  const { updateStatus, deleteOne } = useExhibitions();
  const statusHandler = () => {
    updateStatus({ type: "salons", id, query, exhibitionId });
  };
  const { deleteModul } = DeleteModul();
  const deleteHandler = () => {
    deleteModul(() => {
      deleteOne({ type: "salons", id, query, exhibitionId });
    });
  };
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.header}>
        <div
          className={classes.name}
          style={!isActive ? { color: theme.palette.description.main } : {}}
        >
          {salonsName}
        </div>
        <div className={classes.editBtn}>
          <Link to={RouteEnum.Edit(id)} className={classes.btnLink}>
            <IconButton>
              <Icons.Edit2 />
            </IconButton>
          </Link>
        </div>
      </div>
      <div className={classes.describeWrapper}>
        <div className={classes.describe}>
          <div className={classes.describeTitle}>{tv("views")}:</div>
          <div
            style={!isActive ? { color: theme.palette.description.main } : {}}
          >
            {userViewedCount.toLocaleString()}
          </div>
        </div>
        <div className={classes.describe}>
          <div className={classes.describeTitle}>{tv("status")}:</div>
          <div className={classes.active}>
            <div
              className={isActive ? classes.activeDot : classes.notActiveDot}
            ></div>
            <div
              style={!isActive ? { color: theme.palette.description.main } : {}}
            >
              {isActive ? tv("active") : tv("notActive")}
            </div>
          </div>
        </div>
        <div className={classes.describe}>
          <div className={classes.describeTitle}>{tv("booths")}:</div>
          <div
            style={!isActive ? { color: theme.palette.description.main } : {}}
          >
            {Booths?.toLocaleString()}
          </div>
        </div>
      </div>
      <div className={classes.btnWrapper}>
        <Link
          to={"../../" + RouteEnum.Booths(exhibitionId, id)}
          className={classes.btnLink}
          style={!isActive ? { pointerEvents: "none" } : {}}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!isActive}
            classes={{ disabled: classes.disabled }}
          >
            {tv("open") + " " + tv("salon")}
          </Button>
        </Link>
        <Button
          color={isActive ? "description" : "primary"}
          onClick={statusHandler}
        >
          {isActive ? tv("deactivate") : tv("activate")}
        </Button>
        {type === "admins" && (
          <Button onClick={deleteHandler} color="error">
            {tv("delete")}
          </Button>
        )}
      </div>
    </div>
  );
}

import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
    mainContainer: {
        borderBottomColor: theme.palette.background.light,
        borderRadius: "30px !important",
        backgroundColor: theme.palette.inputBackground.main,
    },
    containerTitle: {
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "start",
        fontWeight: 600,
        fontSize: 16
    },
    containerPlaceholder: {
        fontSize: 14,
        color: theme.palette.description.main
    },
    area: {
        flex: 1,
        cursor: "pointer",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:"center",
        padding: '20px',
        borderRadius: 20,
        background: "#FFFFFF",
        border: "2px dashed rgba(152, 162, 179, 0.4)",
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    fileArea: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
        padding: '20px',
        borderRadius: 20,
        border: "2px dashed rgba(117, 94, 252, 0.6)",
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        height:'100%'
    },
    areaTitle: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color :"#98A2B3",
        margin :" 28px 0 0 0"
    },
    fileAreaTitle: {
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "16px",
        color :"#475467",
        margin :" 28px 0 0 0",
        "& span":{
            color:"#755EFC",
            textDecoration:'underline'
        },
        cursor:'pointer'
    },
    areaLogo: {},
    areaPlaceholder: {
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "16px",
        color :"#475467",
        margin :" 5px 0 0 0"
    },
    fileAreaPlaceholder: {
        fontWeight: 500,
        fontSize: 13,
        lineHeight: "16px",
        color :"rgba(71, 84, 103, 0.6)",
        margin :" 5px 0 0 0",
        cursor:'pointer',
        marginTop:12
    },
    focusedStyle: {
        borderColor: '#2196f3'
    },
    acceptStyle: {
        borderColor: '#00e676'
    },
    rejectStyle: {
        borderColor: '#ff1744'
    }

}));

import React from "react";
import { Route, Routes } from "react-router";
import LanguagesList from "./components/LanguagesList/LanguagesList";
import LanguageCrud from "./components/LanguageCrud";
// import useStyles from "./styles";
import NotFound from "../NotFound/NotFound";

export default function Language(props) {
  // const classes = useStyles();
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<LanguagesList />} />
      <Route path={"add"} element={<LanguageCrud />} />
      <Route path={"edit/:id"} element={<LanguageCrud />} />
    </Routes>
  );
}

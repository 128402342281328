import { makeStyles } from "@mui/styles";

const sections = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
};
export default makeStyles((theme) => ({
  TabelHeaderUiWrapper: {
    display: "flex",
    flexDirection: "row",
    height: 40,
    paddingBottom: 10,
    flexGrow: 1,
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      height: "auto",
    },
  },
  sort: {
    ...sections,
  },
  filter: {
    ...sections,
  },
  search: {
    ...sections,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      "& .MuiFormControl-root": {
        width: "100%",
        "& .MuiInputBase-root": {
          width: "100%",
        },
      },
    },
  },
  searchDesk: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  searchRes: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  modalSearch: {
    background: "#E4E7EC66 !important",
    "& input": {
      background: "transparent !important",
    },
    // flexGrow: 1.5,
  },
  leftSide: {
    display: "flex",
    flexDirection: "row",
    gap: 35,
    [theme.breakpoints.down("lg")]: {
      height: "auto",
      width: "100%",
      position: "relative",
      maxWidth: "991px",
      flexWrap: "wrap",
    },
  },
  selectWrapper: {
    width: "100%",
    "& > fieldset": {
      borderRadius: "12px !important",
    },
  },
  select: {
    borderRadius: "12px !important",
    backgroundColor: `${theme.palette.inputBackground.light} !important`,
    padding: "7px 36px 7px 15px !important",
  },
  selectModal: {
    borderRadius: "12px !important",
    backgroundColor: `#E4E7EC66 !important`,
    padding: "7px 36px 7px 15px !important",
  },
  searchInput: {
    alignItems: "center",
    padding: "5px 15px !important",
    background: theme.palette.tableBgColor.main,
    borderRadius: 15,
    backgroundColor: theme.palette.tableBgColor.main,
    margin: "0 !important",
    "& input": {
      backgroundColor: theme.palette.tableBgColor.main,
    },
  },
  title: {
    color: "#475467CC",
  },
}));

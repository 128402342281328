import { BaseUrl, ResponseStatus } from "../../services/Api";
import useGet from "../useGet";
const authUrl = `${BaseUrl}/admins/dashboard`;
const useDashboard = () => {
  const fetchGet = useGet();
  const getDashboard = async (props) => {
    try {
      let res = await fetchGet(authUrl);
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return { getDashboard };
};

export default useDashboard;

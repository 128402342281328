import { Box, CircularProgress, IconButton, TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Icons from "../../../../../../assets/icons/Icons";
import BoxUi from "../../../../../../components/BaseElement/BoxUi/BoxUi";
import PerfectScrollbarUi from "../../../../../../components/BaseElement/PerfectScrollbarUi/PerfectScrollbarUi";
import useChat from "../../../../../../customHooks/hookRepositories/useChat";
import { MainUrl } from "../../../../../../services/Api";
import useStyles from "./styles";
import useCustomTranslate from "../../../../../../customHooks/hookRepositories/useCustomTranslate";
import { useTranslation } from "react-i18next";

export default function ChatBody({ creator, _id, messages, showChatBody, onClose }) {
	const tvPages = useCustomTranslate('pages');
	const tvForms = useCustomTranslate('forms');
	const { t } = useTranslation();
	const { avatar, fullName } = creator ? creator : { fullName: tvPages("deletedAccount") };
	const isRtl = t("dir") === "rtl";
	const classes = useStyles();
	const containerRef = useRef();
	const { sendMessage } = useChat()
	const messagesRef = useRef();
	useEffect(() => {
		if (containerRef?.current && showChatBody) {
			containerRef.current.childNodes[0].childNodes[0].scrollTo(0, messagesRef.current.scrollHeight);
		}
	}, [containerRef, _id, messages, showChatBody])
	const [text, setText] = useState();
	const textHandler = (e) => {
		setText(e.target.value);
	}
	const [isSanding, setIsSanding] = useState(false);
	const sendHandler = async (e) => {
		if (!isSanding) {
			setText("");
			setIsSanding(true);
			await sendMessage(_id, text);
			setIsSanding(false);
		}
	}
	const handleKeyPress = (e) => {
		if (e.key === "Enter" && !e.shiftKey && text) {
			e.preventDefault();
			sendHandler()
		}
	};
	return (
		<BoxUi className={classes.cardWrapper}>
			<div className={classes.header}>
				<div className={classes.profileWrapper}>
					<IconButton className={isRtl ? classes.arrowFront : classes.arrowBack} onClick={onClose}>
						<Icons.ArrowDown />
					</IconButton>
					<div className={classes.profile}>
						<img className={classes.img} alt=""
							src={avatar ? `${MainUrl}${avatar}` : "/assets/image/no_photo.png"} />
					</div>
				</div>
				<div className={classes.name}>{fullName}</div>
			</div>
			<div className={classes.body}>
				<div className={classes.messagesWrapper} ref={containerRef} >
					<PerfectScrollbarUi>
						<div className={classes.messages} ref={messagesRef}>
							{messages.map((m, i) => {
								const { body, createdAt } = m
								const isAdmin = !!m.admin
								return (
									<Box className={[classes.message, isAdmin && classes.adminMessages].join(" ")}
										key={i}>
										<Box className={isAdmin ? classes.admin : classes.user}>
											<div>{isAdmin ? tvPages('admin') : fullName}</div>
											<div>{body}</div>
											<div>{moment(createdAt).format("HH:mm")}</div>
										</Box>
										<Box sx={{ display: "flex", flex: 1 }}></Box>
									</Box>
								)
							})}
						</div>
					</PerfectScrollbarUi>
				</div>
			</div>
			<div className={classes.footer}>
				<TextField
					className={classes.input}
					placeholder={tvForms('messagePlaceholder')}
					multiline
					rows={3}
					// sx={{ padding: "13px 4px" }}
					onChange={textHandler}
					value={text}
					inputProps={{
						sx: { fontSize: 14, },
						onKeyPress: handleKeyPress
					}}
					disabled={!creator}
				/>
				<IconButton sx={{
					transform: isRtl && "scaleX(-1)"
				}} onClick={sendHandler} disabled={!text || !creator} classes={{ disabled: classes.disabled }}>
					{isSanding ? <CircularProgress color="primary" size={24} /> : <Icons.Send />}
				</IconButton>
			</div>
		</BoxUi>
	);
}
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Button, Grid, Tab, Tabs } from "@mui/material";
import FilesCardUi from "../FilesCardUi";
import { useDispatch, useSelector } from "react-redux";
import { setFile } from "../../../redux/SelectedFileSlice";
import { showModal } from "../../../redux/ModalSlice";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container spacing={1}>
          {children}
        </Grid>
      )}
    </div>
  );
}

export default function FilesCatTabUi(props) {
  const {
    className,
    itemsList,
    classNameCard,
    modal,
    uploadUserChanged,
    getData,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("0");
  const { filesTab } = useSelector((s) => s.roles);

  const handleChange = (event, newValue) => {
    uploadUserChanged(newValue);
    setValue(newValue);
  };

  const { type, id } = useSelector((state) => state.modal);
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    setSelected("");
  }, [itemsList]);
  const tv = useCustomTranslate("pages");
  return (
    <>
      <Tabs
        className={classes.root + " " + className}
        TabIndicatorProps={{
          style: {
            top: 0,
            bottom: 0,
            height: "48px",
            borderRadius: 50,
            backgroundColor: "rgba(117, 94, 252, 0.1)",
            zIndex: 0,
          },
        }}
        centered
        value={value}
        onChange={handleChange}
      >
        {filesTab?.map(({ value, label }) => (
          <Tab className={classes.tabBtn} disableRipple value={value} label={tv(label)} key={label} />
        ))}
      </Tabs>
      <TabPanel
        // value={value}
        value={0}
        index={0}
      >
        {itemsList.map(({ _id, description, type, file, adminId: { name, family } }) => (
          <Grid
            item
            xs={12}
            lg={4}
            key={_id}
            onClick={() => setSelected(selected === _id ? "" : _id)}
          >
            <FilesCardUi
              getData={getData}
              id={_id}
              file={file}
              role={name || family? `${name} ${family}`:'' }
              type={type}
              des={description}
              className={classNameCard}
              selected={selected === _id && modal}
            />
          </Grid>
        ))}
      </TabPanel>
      {props.modal && (
        <Grid container display={"flex"} justifyContent="center" mt={2}>
          <Button
            sx={{ pr: 4, pl: 4 }}
            variant={"contained"}
            onClick={() => {
              dispatch(
                setFile({
                  url: itemsList.filter((x) => x._id === selected)[0].file.path,
                  type,
                  id,
                }),
              );
              dispatch(showModal({ open: false }));
            }}
            disabled={!selected}
          >
            {tv('submit')}
          </Button>
        </Grid>
      )}
    </>
  );
}

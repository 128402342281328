import { Box } from "@mui/material";
import React from "react";
import useStyles from "./styles";

export default function BoxUi(props) {
  var classes = useStyles();
  const { children, className } = props;
  return (
    <Box
      {...props}
      login={null}
      className={[classes.box, className, props.login && classes.login].join(
        " ",
      )}
    >
      {children}
    </Box>
  );
}

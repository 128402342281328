import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  search: {
    alignItems: "center",
    padding: "5px 15px !important",
    background: theme.palette.tableBgColor.main,
    borderRadius: 15,
    backgroundColor: theme.palette.tableBgColor.main,
    margin: "0 !important",
    "& input": {
      backgroundColor: theme.palette.tableBgColor.main,
    },
  },
  searchBtn: {
    "& > svg": {
      width: 16,
      height: 16,
    },
  },
}));

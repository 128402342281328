import React from "react";
import { Route, Routes } from "react-router";
import Layout from "../../components/Layout/Layout";
import RouteEnum from "../../navigation/RouteEnum";
import Analysis from "../Analysis/Analysis";
import Booths from "../Booths/Booths";
import Comments from "../Comments/Comments";
import Events from "../Events/Events";
import Exhibitors from "../Exhibitors/Exhibitors";
import Files from "../Files/Files";
import Salons from "../Salons/Salons";
import Visitors from "../Visitors/Visitors";
import Dashboard from "../Dashboard";
import OrganizerSettings from "./pages/OrganizerSettings/OrganizerSettings";
import NotFound from "../NotFound/NotFound";
// import MyBooths from "./pages/MyBooths/MyBooths";

export default function Organizer(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        {/* <Route path={RouteEnum.MyBooths + "/*"} element={<MyBooths />} /> */}
        {/* user */}
        <Route
          path={RouteEnum.user.Exhibitors + "/*"}
          element={<Exhibitors />}
        />
        <Route path={RouteEnum.user.Visitors + "/*"} element={<Visitors />} />
        {/* user */}
        <Route path={RouteEnum.Exhibition + "/*"} element={<Salons />} />
        <Route path={RouteEnum.Booths() + "/*"} element={<Booths />} />
        <Route path={RouteEnum.Events + "/*"} element={<Events />} />
        <Route path={RouteEnum.Comments} element={<Comments />} />
        <Route path={RouteEnum.Files} element={<Files />} />
        <Route path={RouteEnum.Analysis} element={<Analysis />} />
        <Route path={RouteEnum.Settings} element={<OrganizerSettings />} />
      </Route>
    </Routes>
  );
}

import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Logo from "../../../Logo";
//mui
import {
  Collapse,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Icons from "../../../../assets/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import PerfectScrollbarUi from "../../../BaseElement/PerfectScrollbarUi/PerfectScrollbarUi";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import { setDrawerMenu } from "../../../../redux/DrawerMenuSlice";
import { Close } from "@mui/icons-material";
import { showModal } from "../../../../redux/ModalSlice";
import { signOut } from "../../../../redux/AuthSlice";

export default function PanelMenu(props) {
  var classes = useStyles();
  const [open, setOpen] = useState([]);
  const { id: toolbarId } = useSelector((s) => s.toolbar);
  const { menuList, UserMenu } = useSelector((s) => s.roles);
  const handleClick = (value) => {
    if (open.includes(value)) {
      const newOpen = open.filter((i) => i !== value);
      setOpen(newOpen);
    } else {
      const newOpen = [...open];
      newOpen.push(value);
      setOpen(newOpen);
    }
  };
  const tv = useCustomTranslate("panelMenu");
  useEffect(() => {
    if (menuList) {
      const haveChildren = menuList.filter((x) => x.children);
      haveChildren.forEach((x) => {
        x.children.forEach((y) => {
          if (y.id === toolbarId) {
            setOpen([x.name]);
          }
        });
      });
    }
  }, [toolbarId, menuList]);
  const dispatch = useDispatch();
  const { info: { username, extraInfo, name, family } = {} } = useSelector(
    (s) => s.auth,
  );
  const { dir } = useSelector((s) => s.dir);
  const { drawerMenu } = useSelector((s) => s);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    dispatch(setDrawerMenu(open));
  };

  return (
    <>
      <div className={classes.panelMenu}>
        <div className={classes.box}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.listWrapper}>
            <PerfectScrollbarUi>
              <List
                sx={{ width: "100%", maxWidth: 232, bgcolor: "#fff" }}
                component="nav"
              >
                {menuList &&
                  menuList.map(({ icon, link, name, children, id }) => {
                    const active = open.includes(name);
                    const activeLink = toolbarId === id;
                    return (
                      <React.Fragment key={name}>
                        {children ? (
                          <React.Fragment>
                            <ListItemButton
                              onClick={() => handleClick(name)}
                              sx={{ bgcolor: active ? "#98A2B326" : "#fff" }}
                            >
                              <ListItemIcon
                                className={activeLink ? classes.activeIcon : ""}
                              >
                                {icon}
                              </ListItemIcon>
                              <ListItemText primary={tv(name)} />
                              <Icons.ArrowDown
                                className={active ? classes.activeArrow : ""}
                              />
                            </ListItemButton>
                            <Collapse in={active} timeout="auto" unmountOnExit>
                              <List
                                component="div"
                                disablePadding
                                sx={{ bgcolor: "#98A2B326" }}
                              >
                                {children.map(({ name, link, id }) => {
                                  const activeLink = toolbarId === id;

                                  return (
                                    <Link
                                      key={name}
                                      to={link}
                                      onClick={() => {
                                        dispatch(setDrawerMenu(false));
                                      }}
                                    >
                                      <ListItemButton
                                        sx={{
                                          pl: 4,
                                          pr: 4,
                                          color: activeLink
                                            ? "#755EFC"
                                            : "#475467",
                                        }}
                                      >
                                        <ListItemText primary={tv(name)} />
                                        {activeLink && (
                                          <div
                                            className={classes.activeBar}
                                          ></div>
                                        )}
                                      </ListItemButton>
                                    </Link>
                                  );
                                })}
                              </List>
                            </Collapse>
                          </React.Fragment>
                        ) : (
                          <Link
                            to={link}
                            onClick={() => {
                              setOpen([]);
                              dispatch(setDrawerMenu(false));
                            }}
                          >
                            <ListItemButton
                              sx={{ color: activeLink ? "#755EFC" : "#475467" }}
                            >
                              <ListItemIcon
                                className={activeLink ? classes.activeIcon : ""}
                              >
                                {icon}
                              </ListItemIcon>
                              <ListItemText
                                color={activeLink ? "#755EFC" : ""}
                                primary={tv(name)}
                              />
                              {activeLink && (
                                <div className={classes.activeBar}></div>
                              )}
                            </ListItemButton>
                          </Link>
                        )}
                      </React.Fragment>
                    );
                  })}
              </List>
            </PerfectScrollbarUi>
          </div>
        </div>
      </div>
      <SwipeableDrawer
        PaperProps={{
          sx: { width: "100%", background: "#EFF1F4" },
        }}
        anchor={dir === "rtl" ? "right" : "left"}
        open={drawerMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Grid>
          <IconButton onClick={toggleDrawer(false)}>
            <Close />
          </IconButton>
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          mb={3}
        >
          <svg
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.7548 27.1575C25.606 27.1363 25.4148 27.1363 25.2448 27.1575C21.5048 27.03 18.5298 23.97 18.5298 20.2088C18.5298 16.3625 21.6323 13.2388 25.4998 13.2388C29.346 13.2388 32.4698 16.3625 32.4698 20.2088C32.4485 23.97 29.4948 27.03 25.7548 27.1575Z"
              stroke="#475467"
              strokeOpacity="0.8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M39.8227 41.1825C36.0402 44.6463 31.0252 46.75 25.5002 46.75C19.9752 46.75 14.9602 44.6463 11.1777 41.1825C11.3902 39.185 12.6652 37.23 14.939 35.7C20.7615 31.8325 30.2815 31.8325 36.0615 35.7C38.3352 37.23 39.6102 39.185 39.8227 41.1825Z"
              stroke="#475467"
              strokeOpacity="0.8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.5 46.75C37.236 46.75 46.75 37.236 46.75 25.5C46.75 13.7639 37.236 4.25 25.5 4.25C13.7639 4.25 4.25 13.7639 4.25 25.5C4.25 37.236 13.7639 46.75 25.5 46.75Z"
              stroke="#475467"
              strokeOpacity="0.8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Typography>
            {extraInfo && extraInfo.companyName
              ? extraInfo.companyName
              : name
              ? name + " " + family
              : username}
          </Typography>
        </Grid>
        <List
          sx={{
            width: "100%",
            gap: "2px",
            display: "flex",
            flexDirection: "column",
          }}
          component="nav"
        >
          {menuList &&
            [
              ...menuList,
              ...UserMenu.map((x) => ({ ...x, name: x.title })),
            ].map(({ icon, link, name, children, id }) => {
              const active = open.includes(name);
              const activeLink = toolbarId === id;
              const menuHandler = (type) => {
                switch (type) {
                  case "Logout":
                    dispatch(signOut());
                    break;
                  case "HowToUse":
                    dispatch(showModal({ open: "TipsModal" }));
                    break;
                  default:
                    break;
                }
              };
              return (
                <React.Fragment key={name}>
                  {children ? (
                    <React.Fragment>
                      <ListItemButton
                        onClick={() => handleClick(name)}
                        sx={{ bgcolor: active ? "#EFF1F4" : "#fff" }}
                      >
                        <ListItemIcon
                          className={activeLink ? classes.activeIcon : ""}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={tv(name)} />
                        <Icons.ArrowDown
                          className={active ? classes.activeArrow : ""}
                        />
                      </ListItemButton>
                      <Collapse in={active} timeout="auto" unmountOnExit>
                        <List
                          component="div"
                          disablePadding
                          sx={{ bgcolor: active ? "#EFF1F4" : "#fff" }}
                        >
                          {children.map(({ name, link, id }) => {
                            const activeLink = toolbarId === id;

                            return (
                              <Link
                                key={name}
                                to={link}
                                onClick={() => {
                                  dispatch(setDrawerMenu(false));
                                }}
                              >
                                <ListItemButton
                                  sx={{
                                    pl: 4,
                                    pr: 4,
                                    color: activeLink ? "#755EFC" : "#475467",
                                  }}
                                >
                                  <ListItemText primary={tv(name)} />
                                  {activeLink && (
                                    <div className={classes.activeBar}></div>
                                  )}
                                </ListItemButton>
                              </Link>
                            );
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <Link
                      to={link ? link : "/"}
                      onClick={(e) => {
                        !link && e.preventDefault();
                        setOpen([]);
                        dispatch(setDrawerMenu(false));
                        menuHandler(id);
                      }}
                    >
                      <ListItemButton
                        sx={{
                          color: activeLink ? "#755EFC" : "#475467",
                          bgcolor: activeLink ? "#EFF1F4" : "#fff",
                        }}
                      >
                        <ListItemIcon
                          className={activeLink ? classes.activeIcon : ""}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          color={activeLink ? "#755EFC" : ""}
                          primary={tv(name)}
                        />
                        {activeLink && (
                          <div className={classes.activeBar}></div>
                        )}
                      </ListItemButton>
                    </Link>
                  )}
                </React.Fragment>
              );
            })}
        </List>
      </SwipeableDrawer>
    </>
  );
}

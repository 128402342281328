import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableUi from "../../components/BaseElement/TableUi/TableUi";
import { setToolbar } from "../../redux/ToolbarSlice";
import useStyles from "./styles";
import useAnalysis from "../../customHooks/hookRepositories/useAnalysis";
import useCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import useDate from "../../customHooks/hookRepositories/useDate";
import { Autocomplete, Box, TextField } from "@mui/material";
import useGetConstant from "../../customHooks/hookRepositories/useGetConstant";

export default function Analysis(props) {
  var classes = useStyles();
  const [tabelData, setTabelData] = useState({});
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const dispatch = useDispatch();
  const { getAnalysis, deleteAnalysis } = useAnalysis();
  const getDate = useDate();
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Analysis",
        title: { name: tv("analysis") },
        rightElements: [{ type: "Excel", name: "analysis" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("analysis") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  useEffect(() => {
    getAnalysis();
    // eslint-disable-next-line
  }, []);
  const { getExhibitions } = useGetConstant();
  useEffect(() => {
    getExhibitions();
    // eslint-disable-next-line
  }, []);
  const { exhibitions } = useSelector((s) => s.constants);
  const { type: roleType } = useSelector((s) => s.roles);
  const { itemsList, type } = useSelector((s) => s.table);
  useEffect(() => {
    if (type === "analysis") {
      const rows = itemsList.map(
        ({
          _id,
          createdAt,
          exhibition: { exhibitionName },
          user: { fullName, email, phoneNumber },
          browser,
          os,
        }) => ({
          id: _id,
          name: fullName,
          email,
          phoneNumber,
          platform: browser ? browser : os ? os : "Unknown",
          exhibition: exhibitionName,
          dateTime: `${getDate(createdAt).format("YYYY/MM/DD")} ${tv(
            "at",
          )} ${getDate(createdAt).format("HH:mm")}`,
        }),
      );
      const columns = [
        {
          field: "Name",
          headerName: tv("name"),
          flex: 2,
          renderCell: ({ row: { name, email } }) => (
            <div className={classes.nameCell}>
              <div className={classes.name}>{name}</div>
              <div className={classes.email}>{email}</div>
            </div>
          ),
        },
        {
          field: "platform",
          headerName: tv("platform"),
          flex: 1,
          renderCell: ({ row: { platform } }) => (
            <div title={platform}>{platform}</div>
          ),
        },
        { field: "phoneNumber", headerName: tv("phoneNumber"), flex: 2 },
        {
          field: "exhibition",
          headerName: tv("exhibition"),
          flex: 2,
          renderCell: ({ row: { exhibition } }) => (
            <div className={classes.exhibition}>{exhibition}</div>
          ),
        },
        {
          field: "dateTime",
          headerName: tv("date"),
          flex: 1.5,
          renderCell: ({ row: { dateTime } }) => (
            <div className={classes.dateTime}>{dateTime}</div>
          ),
        },
      ];
      setTabelData({ rows, columns });
    }
    //eslint-disable-next-line
  }, [itemsList, tv("name")]);

  const [q, setQ] = useState({
    exhibitionId: "None",
  });
  const onPageChange = (e, page) => {
    getAnalysis({ page, q });
  };
  const onDelete = async (ids) => {
    deleteAnalysis({ ids, q });
  };
  const onChange = (v) => {
    getAnalysis({ ...q, ...v });
    setQ({ ...q, ...v });
  };
  const exhibitionList = [{ name: "None", value: "None" }].concat(exhibitions);
  return (
    <div>
      {roleType === "admins" && (
        <div className={classes.sort}>
          <div className={classes.title}>{tv("sortBy")}:</div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Autocomplete
              disableClearable
              isOptionEqualToValue={(op, val) => op.name === val.name}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.name}>
                  {option.name}
                </Box>
              )}
              fullWidth
              classes={{
                root: classes.autocomplete,
              }}
              options={exhibitionList}
              getOptionLabel={(option) =>
                option?.name ? option?.name : option
              }
              value={
                exhibitionList.filter((x) => x.value === q.exhibitionId)[0]
                  ? exhibitionList.filter((x) => x.value === q.exhibitionId)[0]
                  : { value: "None", name: "None" }
              }
              onChange={(e, n) => {
                onChange({ exhibitionId: n.value });
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                );
              }}
            />
          </Box>
        </div>
      )}
      {tabelData.columns?.length > 0 && (
        <TableUi
          onPageChange={onPageChange}
          onDelete={onDelete}
          {...tabelData}
          disableEdit
          onChange={(q) => onChange({ q })}
        />
      )}
    </div>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    background: "#fff",
    display: "flex",
    padding: 5,
    borderRadius: 15,
    position: "relative",
  },
  text: {
    flex: 4,
    "& p:first-child": {
      color: "#101828",
      fontSize: 14,
      fontWeight: 400,
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2,
      maxWidth: 174,
    },
    "& p:nth-child(2)": {
      color: "#755EFC",
      fontSize: 13,
      fontWeight: 400,
    },
    "& p:last-child": {
      color: "#98A2B3",
      fontSize: 13,
      fontWeight: 400,
    },
  },
  img: {
    width: 96,
    height: 96,
  },
  more: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
  },
  selected: {
    border: "1px solid #755EFC!important",
    boxShadow: "0px 0px 7px #755EFC",
  },
  menu: {
    "& .MuiPaper-rounded": {
      borderRadius: 8,
    },
    "& ul": {
      width: 120,
      paddingTop: 0,
      paddingBottom: 0,
      "& li": {
        height: 30,
        paddingTop: 0,
        paddingBottom: 0,
        color: "#667085",
        gap: 10,
        fontSize: 13,
      },
    },
  },
  edit: {
    "& > svg": {
      width: 16,
      color: "transparent !important",
      height: 16,
      // "& > path": {
      //   stroke: "#000 !important",
      //   fill: "transparent !important",
      // },
    },
  },
  delete: {
    color: "#E31B54!important",
    "& > svg": {
      width: 16,
      height: 16,
    },
  },
  divider: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  download: {
    transition: "opacity .25s ease-out",
    display: "flex",
    opacity: 0,
    top: 0,
    left: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#101828B2",

    "&>svg": {
      width: 36,
      height: 36,
      "&>path": {
        fillOpacity: 1,
        fill: "#fff",
      },
    },
  },
  downloadWrapper: {
    position: "relative",
    display: "flex",
    "&:hover": {
      "&>span": {
        opacity: 1,
      },
    },
  }
}));

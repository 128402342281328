import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px 30px",
  },
  preview: {},
  imgStyle: {
    width: 56,
    height: 56,
    borderRadius: 10,
  },
  text: {
    color: "#101828",
  },
  selectModal: {
    borderRadius: "12px !important",
    backgroundColor: `rgba(228, 231, 236, 0.7) !important`,
    padding: "7px 36px 7px 15px !important",
  },
  selectWrapper: {
    width: "100%",
    "& > fieldset": {
      borderRadius: "12px !important",
    },
  },
  des: {
    alignItems: "center",
    padding: "5px 15px !important",
    borderRadius: 15,
    margin: "0 !important",
    background: "rgba(228, 231, 236, 0.7)",
    "& input": {
      background: "transparent",
    },
  },
  divider: {
    marginTop: "15px !important",
    backgroundColor: "rgba(152, 162, 179, 0.25)",
  },
  uploadBtn: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    "& button": {
      width: 120,
      height: 40,
    },
  },
  filesInput: {
    padding: "10px 15px",
  },
}));

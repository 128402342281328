import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  panelMenu: {
    position: "fixed",
    flexGrow: 1,
    width: "230px",
    padding: "15px 0",
    height: "100vh",
  },
  box: {
    flexGrow: 1,
    padding: 0,
    height: "100%",
    borderRadius: 15,
    backgroundColor: theme.palette.boxUiBackground.main,
  },
  logo: {
    padding: "30px 0px",
  },
  activeBar: {
    backgroundColor: "#755EFC",
    position: "absolute",
    right: 0,
    width: 4,
    height: "90%",
    borderRadius: "2px 0px 0px 2px",
  },
  activeArrow: {
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  activeIcon: {
    color: "red",
    "& > svg > path": {
      stroke: "#755EFC",
    },
  },
  listWrapper: {
    height: "calc(100vh - 162px)",
    overflow: "auto",
  },
}));

import { useDispatch } from "react-redux";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import useGet from "../useGet";
import { setConstants } from "../../redux/ConstantsSlice";

const authUrl = `${BaseUrl}/admins/languages`;
const useGetConstant = () => {
  const dispatch = useDispatch();
  const fetchGet = useGet();

  const getLanguages = async () => {
    dispatch(setConstants({ isSend: true }));
    try {
      let res = await fetchGet(authUrl);
      if (res.status === ResponseStatus.Success) {
        dispatch(setConstants({ languages: res.data }));
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const codeUrl = `${BaseUrl}/admins/country-codes?limit=1000`;
  const getCountryCode = async () => {
    try {
      let res = await fetchGet(codeUrl);
      if (res.status === ResponseStatus.Success) {
        const phoneCodes = res.data.itemsList
          .map(({ locale, countryCodeNumber, title }) => ({
            key: locale,
            code: countryCodeNumber,
            name: title,
          }))
          .filter((c) => c.name !== "Israel");
        dispatch(setConstants({ phoneCodes }));
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const exhibitionsUrl = `${BaseUrl}/admins/exhibitions/available`;
  const getExhibitions = async () => {
    try {
      let res = await fetchGet(exhibitionsUrl);
      if (res.status === ResponseStatus.Success) {
        const exhibitions = res.data.map(({ exhibitionName, _id, inUse }) => ({
          name: exhibitionName,
          value: _id,
          inUse,
        }));
        dispatch(setConstants({ exhibitions }));
        return exhibitions;
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const categoriesUrl = `${BaseUrl}/admins/exhibitions/categories`;
  const getCategories = async () => {
    try {
      let res = await fetchGet(categoriesUrl);
      if (res.status === ResponseStatus.Success) {
        const categories = res.data.map((name) => ({
          name,
          value: name,
        }));
        return categories;
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const constantsUrl = (v) => `${BaseUrl}/admins/${v}`;
  const getConstants = async ({ src, name, value }) => {
    try {
      let res = await fetchGet(constantsUrl(src));

      if (res.status === ResponseStatus.Success) {
        const constants = res.data.itemsList.map((x) => ({
          name: x[name],
          value: value ? x[value] : x._id,
        }));
        return constants;
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return {
    getLanguages,
    getCountryCode,
    getExhibitions,
    getConstants,
    getCategories,
  };
};

export default useGetConstant;

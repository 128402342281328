import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setToolbar} from "../../../redux/ToolbarSlice";
import useStyles from "../styles";
import {Grid} from "@mui/material";
import {Link} from "react-router-dom";
import Icons from "../../../assets/icons/Icons";
import EventCard from "./EventCard";
import useEvents from "../../../customHooks/hookRepositories/useEvents";
import FiltersEnum from "../../../configs/FiltersEnum";
import ListLayout from "../../../components/Layouts/ListLayout/ListLayout";
import Limits from "../../../configs/Limits";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

export default function EventsList(props) {
    const classes = useStyles();
    const tv = useCustomTranslate("pages");
    const tvForms = useCustomTranslate("forms");
    const tvCrumb = useCustomTranslate("breadcrumb");
    const [query, setQuery] = useState({
        speakerFilter: "None",
        q: "",
    });
    const [
        {itemsList: data, paginator: {currentPage, pageCount} = {}},
        setData,
    ] = useState([]);
    const [speakersName, setSpeakersName] = useState([]);
    const {getAllEvents, getAllSpeakers, deleteEvent} = useEvents();
    const dispatch = useDispatch();

    useEffect(() => {
        const func = async () => {
            let resSpeakers = await getAllSpeakers();
            setSpeakersName(resSpeakers);
            fetchAllEvents(query, 1);
        };
        func();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        dispatch(
            setToolbar({
                id: "Events",
                title: {name: tv("events")},
                leftElements: [
                    {
                        type: "AdvanceSearch"
                    },
                    {
                        type: "ButtonUi",
                        name: tvForms('new') + ' ' + tv("events"),
                        Link: "EventList/new",
                        res:true
                    },
                ],
                breadcrumbs: [{name: tvCrumb('home'), href: "/"}, {name: tvCrumb('events')}],
            }),
        );
        // eslint-disable-next-line
    }, [dispatch, tv]);

    const fetchAllEvents = async (queryObject, page = 1) => {
        let res = await getAllEvents(
            queryObject.sort,
            queryObject.speakerFilter !== "None" ? queryObject.speakerFilter : "",
            queryObject.q,
            page,
            Limits.tabels.events,
        );
        res && setData(res);
    };

    const TabelHeader = {
        sort: {
            type: "sort",
            children: FiltersEnum.Sort
        },
        filter: [
            {
                api: true,
                type: "speakerFilter",
                children: [{value: "None", name: "speaker"}].concat(
                    speakersName ? speakersName : [],
                ),
            },
        ],
    };

    const onQueryCheng = (e, type) => {
        const newQ = {...query};
        newQ[type] = e.target ? e.target.value : e;
        setQuery(newQ);
        fetchAllEvents(newQ, 1);
    };

    const onPageChange = (e, page) => {
        fetchAllEvents(query, page);
    };

    const onDelete = async (id) => {
        await deleteEvent(id);
        fetchAllEvents(
            query,
            data.length <= 1 && currentPage === pageCount
                ? currentPage - 1
                : currentPage,
        );
    };
    return (
        <ListLayout
            pagination={{
                count: pageCount,
                page: currentPage ? currentPage : 1,
                onChange: onPageChange,
            }}
            TabelHeader={{
                ...TabelHeader,
                onChange: onQueryCheng,
                value: query,
            }}
            hasData={true}
        >
            <Grid item xs={12} md={6} lg={3} className={classes.resNone} sx={{display: "flex"}}>
                <div className={classes.cardWrapper}>
                    <Link className={classes.addIconWrapper} to={"new"}>
                        <Icons.Add className={classes.addIcon}/>
                        <div className={classes.addTitle}>{tv("addEvent")}</div>
                    </Link>
                </div>
            </Grid>
            {data &&
                data.map((values) => (
                    <Grid key={values._id} item xs={12} sm={6} lg={3} sx={{display: "flex"}}>
                        <EventCard {...values} onDelete={onDelete}/>
                    </Grid>
                ))}
        </ListLayout>
    );
}

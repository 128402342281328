import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AddForm from "../../../../../../components/AddForm/AddForm";
import useCustomTranslate from "../../../../../../customHooks/hookRepositories/useCustomTranslate";
import useExhibitions from "../../../../../../customHooks/hookRepositories/useExhibitions";
import useGetConstant from "../../../../../../customHooks/hookRepositories/useGetConstant";
import useSalons from "../../../../../../customHooks/hookRepositories/useSalons";
import RouteEnum from "../../../../../../navigation/RouteEnum";
import { showModal } from "../../../../../../redux/ModalSlice";
import { setToolbar } from "../../../../../../redux/ToolbarSlice";
import useStyles from "./styles";
import BoothLocation from "./components/BoothLocation";

export default function BuyBooth(props) {
  var classes = useStyles();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    exhibitionId: "None",
    salonId: "None",
  });

  const {
    salons: { itemsList },
    booths: { itemsList: boothsItemsList },
  } = useSelector((s) => s.exhibitions);
  const [designs, setDesigns] = useState([]);
  const { getAll, getOne } = useExhibitions();
  const { getAvailableBoothDesigns } = useSalons();

  const getBooths = async (id) => {
    getAll({ name: "booths", ...id, limit: 25 });
    let res = await getAvailableBoothDesigns(id.salonId);
    setDesigns(res);
  };
  const [searchParams] = useSearchParams();
  const isSuccess = searchParams.get("status");
  useEffect(() => {
    if (isSuccess === "OK" || isSuccess === "NOK") {
      dispatch(
        showModal({
          open: "BuyResponseModal",
          isSuccess: isSuccess === "OK" ? true : false,
        }),
      );
    }
  }, [isSuccess, dispatch]);
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const tvForm = useCustomTranslate("forms");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "MyBooths",
        title: { name: tv("newBooth") },
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tv("myBooth"), href: RouteEnum.MyBooths },
          { name: tv("newBooth") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);

  const { getExhibitions } = useGetConstant();
  const { exhibitions } = useSelector((s) => s.constants);
  useEffect(() => {
    getExhibitions();
    // eslint-disable-next-line
  }, []);

  const inputHandler = async (value, type) => {
    const newValue = { ...formData };
    newValue[type] = value;
    if (type === "exhibitionId") {
      newValue.salonId = "None";
      if (value !== "None" && value !== formData.exhibitionId) {
        getAll({
          name: "salons",
          exhibitionId: value,
          query: { is_active: "1" },
        });
        const res = await getOne({ name: "exhibitions", id: value });
        newValue.currency = res.currency.currencyName;
      }
    }
    if (type === "salonId" && value !== "None" && value !== formData.salonId) {
      getBooths(newValue);
    }
    setFormData(newValue);
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "exhibitionId",
            title: `${tvForm("exhibition")}:`,
            required: true,
            select: {
              children: [
                { name: tvForm("selectExhibition"), value: "None" },
              ].concat(exhibitions),
              placeholder: tvForm("selectExhibition"),
            },
          },
          {
            width: [2, 4],
            valueId: "salonId",
            title: `${tv("salon")}:`,
            required: true,
            select: {
              children: itemsList
                ? [{ name: tvForm("selectSalon"), value: "None" }].concat(
                    itemsList.map(({ _id, name }) => ({
                      name,
                      value: _id,
                    })),
                  )
                : [{ name: tvForm("selectSalon"), value: "None" }],
              placeholder: tvForm("selectSalon"),
              disabled: formData.exhibitionId === "None",
            },
          },
        ],
      },
    },
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 12],
            valueId: "BoothLocation",
            title: `${tvForm("boothLocation")}:`,
            component: (
              <BoothLocation
                booths={boothsItemsList}
                designs={designs}
                currency={formData.currency}
                disabled={
                  !(
                    formData.exhibitionId !== "None" &&
                    formData.salonId !== "None"
                  )
                }
              />
            ),
          },
        ],
      },
    },
  ];
  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title="Booth"
        className={classes.wrapper}
        onInputChange={inputHandler}
      />
    </div>
  );
}

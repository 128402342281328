import React from "react";
// import useStyles from "./styles";
import { Route, Routes } from "react-router";
import SalonsAdd from "./components/SalonsAdd/SalonsAdd";
import SalonsList from "./components/SalonsList/SalonsList";
import RouteEnum from "../../navigation/RouteEnum";
import NotFound from "../NotFound/NotFound";

export default function Salons(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<SalonsList {...props} />} />
      <Route path={"add"} element={<SalonsAdd {...props} />} />
      <Route path={RouteEnum.Edit()} element={<SalonsAdd {...props} />} />
    </Routes>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  input: {
    borderRadius: "30px !important",
    backgroundColor: theme.palette.inputBackground.main,
    "&:focus-within": {
      backgroundColor: theme.palette.inputBackground.light,
    },
    "& input": {
      borderRadius: "30px !important",
      backgroundColor: theme.palette.inputBackground.main,
      padding: 10,
      "&:focus": {
        backgroundColor: theme.palette.inputBackground.light,
      },
    },
  },
  startAdornment: {
    padding: 8,
    paddingRight: 0,
    "& svg": {
      marginTop: 4,
    },
  },
  startAdornmentLtr: {
    paddingRight: 8,
    paddingLeft: 0,
  },
  tel: {
    display: "flex",
    alignItems: "center",
  },
  visibility: {
    padding: "10px",
    paddingLeft: "0",
    cursor: "pointer",
    "& > svg": {
      marginTop: "5px",
    },
  },
  phoneCode: {
    "& > div": {
      padding: "6.5px 14px",
      paddingRight: "20px !important",
    },
    "& > fieldset": {
      display: "none",
    },
    "& > svg": {
      right: "-4px",
    },
  },
  phoneCodeList: {
    "& *": {
      border: "none !important",
      background: "transparent !important",
    },
    "& > div >div": {
      padding: "0px 20px 0px 0px !important",
      minWidth: 80,
    },
  },
  popper: {
    width: "150px !important",
    direction: "ltr",
    textAlign: "left",
  },
  option: {
    // justifyContent: "space-between !important",
    gap: 10,
    "&>span": {
      width: 30,
    },
  },
  listbox: {
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#00000033",
      borderRadius: 3,
    },
    "&::-webkit-scrollbar": {
      // display: "none",
      width: 5,
      backgroundColor: "transparent",
    },
  },
  countrImg: {
    width: 30,
    height: 20,
  },
}));

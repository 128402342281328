import React from "react";
import useStyles from "./styles";
import {
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import docImg from "../../../assets/icons/folder-open.svg";
import musicImg from "../../../assets/icons/music.svg";
import { UploadFileType } from "../../../configs/FormEnum";
import Icons from "../../../assets/icons/Icons";
import useFiles from "../../../customHooks/hookRepositories/useFiles";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../redux/ModalSlice";
import { MainUrl } from "../../../services/Api";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import DeleteModul from "../../Modals/DeleteModul/DeleteModul";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import { Download } from "@mui/icons-material";

export default function FilesCardUi({
  className,
  role,
  des,
  selected,
  type,
  id,
  getData,
  file,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let preview = "";
  switch (type) {
    case UploadFileType.video: {
      preview = (
        <video src={`${MainUrl}${file.path}`} className={classes.img} />
      );
      break;
    }
    case UploadFileType.image: {
      preview = (
        <img className={classes.img} alt="" src={`${MainUrl}${file.path}`} />
      );
      break;
    }
    case UploadFileType.audio: {
      preview = <img className={classes.img} alt="" src={musicImg} />;
      break;
    }
    case UploadFileType.file: {
      preview = <img className={classes.img} alt="" src={docImg} />;
      break;
    }
    default:
      break;
  }
  const { deleteOne } = useFiles();
  const { deleteModul } = DeleteModul();
  const deleteHandler = async () => {
    handleClose();
    deleteModul(async () => {
      await deleteOne(id);
      getData();
    });
  };
  const modal = useSelector((state) => state.modal);
  const editHandler = async () => {
    dispatch(
      showModal({
        open: "EditModal",
        lastModal: modal.open && modal,
        file: { ...file, des: des },
        id,
      }),
    );
    handleClose();
  };

  const tv = useCustomTranslate("pages");
  return (
    <>
      <Grid
        gap={1}
        className={[classes.root, className, selected && classes.selected].join(
          " ",
        )}
      >
        <Menu
          className={classes.menu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem className={classes.edit} onClick={editHandler}>
            <Icons.Edit2S />
            {tv("edit")}
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem className={classes.delete} onClick={deleteHandler}>
            <Icons.Trash />
            {tv("delete")}
          </MenuItem>
        </Menu>
        <Grid display={"flex"} alignItems={"center"} flex={2}>
          <a download className={classes.downloadWrapper} target="_blank" rel="noreferrer" href={`${MainUrl}${file.path}`} >
            {preview}
            <span className={classes.download}>
              <Download />
            </span>
          </a>
        </Grid>
        <Grid
          className={classes.text}
          display={"flex"}
          alignItems={"start"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Typography>{file.meta.name}</Typography>
          <Typography>{role}</Typography>
          <Typography>{des}</Typography>
        </Grid>
        <div className={classes.more}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </Grid>
    </>
  );
}

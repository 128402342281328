import React, { useEffect } from "react";
import useStyles from "./styles";
import logo from "../../../../assets/images/new_icon.png";
import Icons from "../../../../assets/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../redux/ModalSlice";
import { UploadFileType } from "../../../../configs/FormEnum";
import { setFile } from "../../../../redux/SelectedFileSlice";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function AddIcon({ src, onChange, id, valueId, size }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: fileId, url } = useSelector((s) => s.file);
  const tv = useCustomTranslate("forms");
  useEffect(() => {
    if (fileId === id) {
      onChange(url, valueId);
      dispatch(setFile({}));
    }
    //eslint-disable-next-line
  }, [fileId, url, id, dispatch]);

  const onClick = () => {
    dispatch(showModal({ open: "FilesModal", type: UploadFileType.image, id }));
  };
  return (
    <div className={classes.wrapper} onClick={onClick}>
      <div
        className={[classes.imageWrapper, src && classes.hoverWrapper].join(
          " ",
        )}
      >
        <div className={classes.image}>
          <img src={src ? src : logo} alt="" />
          {src && (
            <div className={classes.hoverIcon}>
              <Icons.Edit2 />
            </div>
          )}
        </div>
        {!src ? (
          <span className={classes.addCircle}>
            <Icons.AddCircle />
          </span>
        ) : (
          <span className={classes.edit}>
            <Icons.Edit2 />
          </span>
        )}
      </div>
      <div className={classes.title}>
        ( {tv("bestSize")} {size ? size : "w:720px, h:720px"} )
      </div>
    </div>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  headerWrapper: {
    backgroundColor: theme.palette.background.main,
    position: "sticky",
    zIndex: 4,
    top: 120,
    [theme.breakpoints.down("lg")]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      position: 'unset',
      zIndex: 1,
      top: "unset"
    },
  },
  paginationWrapper: {
    backgroundColor: theme.palette.background.main,
    position: "sticky",
    zIndex: 12,
    bottom: 0,
    padding: "15px 0",
  },
  listWrapper: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 10,
  },
  noData: {
    height: "calc(100vh - 193px)",
    backgroundColor: "#ffffff61",
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  TabelHeaderUiModal: {
    top: 70,
    backgroundColor: "#fff",
  },
  paginationModal: {
    backgroundColor: "#fff",
  },
}));

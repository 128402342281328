import { BaseUrl, ResponseStatus } from "../../services/Api";
import usePost from "../usePost";
import usePut from "../usePut";
import { useDispatch } from "react-redux";
import { signIn, signOut } from "../../redux/AuthSlice";
import { useNavigate } from "react-router";
import { showAlert } from "../../redux/AlertsSlice";
import RouteEnum from "../../navigation/RouteEnum";
import { toast } from "react-toastify";
import useProfile from "./useProfile";
import Roles from "../../configs/RolesConfigs/Roles";
import UseCustomTranslate from "./useCustomTranslate";
import { useTranslation } from "react-i18next";
// ###########################################################

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
const useAuth = () => {
  const post = usePost();
  const put = usePut();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tv = UseCustomTranslate("error");

  const isValid = ({
    email,
    password,
    confirmPassword,
    phoneNumber,
    date,
    username,
  }) => {
    errorHandler();
    let isValid = true;
    const validation = {};
    if (email) {
      if (!validateEmail(email)) {
        validation.email = tv("invalidEmail");
        isValid = false;
      }
    }
    if (phoneNumber) {
      if (!+phoneNumber?.number || phoneNumber?.number.length <= 9) {
        validation.phoneNumber = tv("invalidPhoneNumber");
        isValid = false;
      }
    }
    if (password) {
      if (password.length <= 8) {
        validation.password = tv("passwordTooShort");
        isValid = false;
      }
    }
    if (username) {
      if (username.length <= 6) {
        validation.username = tv("usernameTooShort");
        isValid = false;
      }
    }
    if (confirmPassword) {
      if (confirmPassword !== password) {
        validation.confirmPassword = tv("conformNotMatch");
        isValid = false;
      }
    }
    if (date) {
      const today = new Date();
      const expire = new Date(date.value);
      if (expire <= today) {
        validation[date.valueId] = tv("dateExpired");
        isValid = false;
      }
    }
    !isValid && errorHandler({ validation });
    return isValid;
  };

  const getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`;
    }

    return newUrl;
  };

  const errorHandler = (res) => {
    if (res) {
      if (res.message) {
        dispatch(
          showAlert({
            notify: { message: res.message, type: "danger" },
          }),
        );
      } else if (res.errors) {
        const validation = {};
        res.errors.forEach(({ param, msg }) => {
          validation[param] = msg;
        });
        dispatch(
          showAlert({
            validation,
          }),
        );
      } else if (res.validation) {
        dispatch(
          showAlert({
            validation: res.validation,
          }),
        );
      }
    } else {
      toast.dismiss();
      dispatch(showAlert({}));
    }
  };

  const authUrl = `${BaseUrl}/admins/login`;
  const { getUserInfo } = useProfile();
  const login = async ({ body, rMe }) => {
    errorHandler();
    let res = await post(authUrl, body);
    if (res.status === ResponseStatus.Success) {
      await dispatch(
        signIn({ token: res.data.token, rMe, info: res.data.admin }),
      );
      getUserInfo(res.data.token);
      navigate(Roles[res.data.admin.type].mainLink);
    } else {
      errorHandler(res);
      dispatch(signOut());
    }
  };

  const exhibitorsRegistrUrl = `${BaseUrl}/admins/register/exhibitor/send/code`;
  const exhibitorsRegister = async ({ body }) => {
    errorHandler();
    let res = await post(exhibitorsRegistrUrl, body);
    if (res.status === ResponseStatus.Success) {
      navigate(RouteEnum.EmailConfirm);
    } else {
      errorHandler(res);
    }
  };

  const emailVerifyUrl = `${BaseUrl}/admins/register/exhibitor/verify/code`;
  const emailVerify = async ({ body }) => {
    errorHandler();
    let res = await post(emailVerifyUrl, body);
    if (res.status === ResponseStatus.Success) {
      // navigate(RouteEnum.Login);
      return true;
    } else {
      errorHandler(res);
    }
  };

  const {
    i18n: { language },
  } = useTranslation();
  const forgetPasswordUrl = `${BaseUrl}/admins/reset-password`;
  const forgetPassword = async ({ body, type }) => {
    errorHandler();
    let res = await post(
      `${forgetPasswordUrl}?type=${type}&lang=${language}`,
      body,
    );
    if (res.status === ResponseStatus.Success) {
      return true;
    } else {
      errorHandler(res);
    }
  };

  const changePasswordUrl = `${BaseUrl}/admins/reset-password`;
  const changePassword = async ({ body }) => {
    errorHandler();
    let res = await put(
      `${changePasswordUrl}?type=${body.email ? "email" : "phoneNumber"}`,
      body,
    );
    if (res.status === ResponseStatus.Success) {
      navigate(RouteEnum.Login);
      return true;
    } else {
      errorHandler(res);
    }
  };

  return {
    login,
    exhibitorsRegister,
    emailVerify,
    forgetPassword,
    changePassword,
    isValid,
    errorHandler,
    getValidUrl,
  };
};

export default useAuth;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  upload: {
    gap: 0,
    "&>div": {
      gap: 15,
    },
  },
}));

import React, { useEffect, useState } from "react";
import useStyles from "./styles";
// import FiltersEnum from "../../../../configs/FiltersEnum";
import TabelHeaderUi from "../../../../components/BaseElement/TabelHeaderUi/TabelHeaderUi";
import { useDispatch, useSelector } from "react-redux";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import {
  Autocomplete,
  Button,
  Collapse,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { countries } from "country-flags-svg";
import { Box } from "@mui/system";
import InputUi from "../../../../components/BaseElement/InputUi/InputUi";
import useLanguage from "../../../../customHooks/hookRepositories/useLanguage";
import { useParams } from "react-router";
import RouteEnum from "../../../../navigation/RouteEnum";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import { useTranslation } from "react-i18next";
import Icons from "../../../../assets/icons/Icons";

export default function LanguageCrud(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const itemsRef = useRef({});
  const { t } = useTranslation();
  const currentLocale = t("locale");
  const { getLanguage, addLanguage, updateLanguage } = useLanguage();
  const tv = useCustomTranslate("pages");
  const tvForm = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");

  //----states
  const { languages } = useSelector((s) => s.constants);
  const [formData, setFormData] = useState({
    dir: "rtl",
    flag: "Iran",
  });
  const { dir, title, flag, translations, locale } = formData;
  const [languageKey, setLanguageKey] = useState({});
  const [languageShow, setLanguageShow] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState({});
  const [query, setQuery] = useState({});
  const [error, setError] = useState({});
  const [collapse, setCollapse] = useState();
  const [loadedDome, setLoadedDome] = useState([]);

  //----useEffect
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Language",
        title: {
          name: id
            ? `${tvForm("edit")} ${tv("language")}`
            : `${tvForm("new")} ${tv("language")}`,
        },
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tv("language"), href: RouteEnum.Language },
          {
            name: id
              ? `${tvForm("edit")} ${tv("language")}`
              : `${tvForm("new")} ${tv("language")}`,
          },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, id, languages, tv]);
  useEffect(() => {
    if (currentLocale) {
      const setCurrentLung = async () => {
        const currentLang = await getLanguage({ locale: currentLocale });
        currentLang && setCurrentLanguage(currentLang.translations);
        translations && onQueryCheng("", "q");
      };
      setCurrentLung();
    }
    // eslint-disable-next-line
  }, [currentLocale]);
  useEffect(() => {
    const setlung = async () => {
      try {
        const enLang = await getLanguage({ locale: "en" });
        if (languages.length > 0) {
          setLanguageKey(enLang.translations);
          if (id) {
            const editLang = await getLanguage({
              locale: languages.filter((x) => x._id === id)[0].locale,
            });
            setLanguageShow(editLang.translations);
            setFormData(editLang);
          } else {
            setLanguageShow(enLang.translations);
            setFD(enLang.translations, "translations");
          }
        }
      } catch (e) {
        console.log("error", e);
      }
    };
    setlung();
    // eslint-disable-next-line
  }, [languages]);
  //----function
  const setFD = (value, type) => {
    const newData = { ...formData };
    newData[type] = value;
    setFormData(newData);
  };
  const onQueryCheng = (e, type) => {
    const newQ = {};
    newQ[type] = e.target ? e.target.value : e;
    setQuery({ ...query, ...newQ });
    const newTranslations = {};
    setLoadedDome([]);
    if (newQ.q) {
      for (let key in translations) {
        newTranslations[key] = {};
        for (let skey in translations[key]) {
          if (
            currentLanguage[key][skey]
              .toLowerCase()
              .indexOf(newQ.q.toLowerCase()) !== -1 ||
            skey.toLowerCase().indexOf(newQ.q.toLowerCase()) !== -1
          )
            newTranslations[key][skey] = translations[key][skey];
        }
        !Object.keys(newTranslations[key]).length &&
          delete newTranslations[key];
      }
      setLanguageShow(newTranslations);
    } else {
      setLanguageShow(translations);
    }
  };
  const onSubmit = () => {
    const error = {};
    setError(error);
    if (!formData.title) {
      error.title = tv("fillMsg");
      setError(error);
      return;
    }
    if (!formData.locale) {
      error.locale = tv("fillMsg");
      setError(error);
      return;
    }
    const body = { ...formData };
    const oldTranslation = formData.translations;
    const newTranslation = [];
    for (const key in oldTranslation) {
      for (const sKey in oldTranslation[key]) {
        newTranslation.push({
          title: key,
          key: sKey,
          description: languageKey[key][sKey],
          translate: oldTranslation[key][sKey],
        });
      }
    }
    body.translations = newTranslation;
    id ? updateLanguage({ body, id }) : addLanguage({ body });
  };
  const collapseHandler = (value) => {
    // itemsRef.current[value].scrollIntoView({ behavior: "smooth", block: "nearest" });
    setCollapse(collapse === value ? "" : value);
    if (!loadedDome.includes(value)) {
      setLoadedDome([...loadedDome, value]);
    }
  };
  const removedContries = ["IL", "UM"];
  return (
    <div className={classes.newLanguageWrapper}>
      <div className={classes.headerForm}>
        {countries && (
          <Box sx={{ flex: 1 }} className={classes.formItem}>
            <div className={classes.formTitle}>{tvForm("flag")}:</div>
            <Autocomplete
              options={countries.filter(
                (x) => !removedContries.includes(x.iso2),
              )}
              disableClearable
              getOptionLabel={(option) => (option.name ? option.name : option)}
              isOptionEqualToValue={(o, v) => o.name === v}
              renderOption={(props, { flag }) => (
                <Box {...props}>
                  <img className={classes.countrImg} src={flag} alt="" />
                </Box>
              )}
              classes={{
                root: classes.Autocomplete,
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    // label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                );
              }}
              onChange={(e, newValue) => {
                setFD(newValue.name, "flag");
              }}
              value={flag}
            />
          </Box>
        )}
        <Box sx={{ flex: 1 }} className={classes.formItem}>
          <div className={classes.formTitle}>{tvForm("slug")}:</div>
          <TextField
            className={classes.input}
            placeholder="E.g: EN"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: 14 },
            }}
            error={!!error.locale}
            helperText={error.locale}
            value={locale}
            onChange={(e) => setFD(e.target.value, "locale")}
          />
        </Box>
        <Box sx={{ flex: 2 }} className={classes.formItem}>
          <div className={classes.formTitle}>{tvForm("name")}:</div>
          <TextField
            className={classes.input}
            placeholder="E.g: English"
            variant="standard"
            value={title ? title : ""}
            error={!!error.title}
            helperText={error.title}
            onChange={(e) => setFD(e.target.value, "title")}
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: 14 },
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }} className={classes.formItem}>
          <div className={classes.formTitle}>{tvForm("direction")}:</div>
          <Select
            classes={{
              select: classes.select,
            }}
            className={classes.selectWrapper}
            onChange={(e) => setFD(e.target.value, "dir")}
            value={dir}
          >
            <MenuItem value={"rtl"}>RTL</MenuItem>
            <MenuItem value={"ltr"}>LTR</MenuItem>
          </Select>
        </Box>
        <Box sx={{ flex: 1.5 }} className={classes.formItem}>
          <Button sx={{ flexGrow: 1 }} onClick={onSubmit} variant="contained">
            {tvForm("submit")}
          </Button>
        </Box>
      </div>
      <div className={classes.headerWrapper}>
        <TabelHeaderUi onChange={onQueryCheng} value={query} hasData />
      </div>
      {Object.keys(languageShow).map((key, i) => (
        <div
          // ref={(el) => (itemsRef.current[key] = el)}
          className={classes.langSection}
          key={key}
        >
          <div
            className={classes.langSectionTitle}
            onClick={() => collapseHandler(key)}
          >
            {key}
            <Icons.ArrowDown
              className={`${classes.arrow} ${
                collapse === key ? classes.activeArrow : ""
              }`}
            />
          </div>
          <Collapse in={collapse === key}>
            <div className={classes.langSectionBody}>
              {(collapse === key || loadedDome.includes(key)) &&
                Object.keys(languageShow[key]).map((sKey) => {
                  const sentChange = ({ target: { value } }) => {
                    const newData = { ...formData };
                    newData.translations[key][sKey] = value;
                    setFormData(newData);
                  };
                  return (
                    <div className={classes.cardWrapper} key={sKey}>
                      <div className={classes.cardTitle}>
                        {languageKey[key][sKey]}
                      </div>
                      {/* <div className={classes.cardInput}>{translations[key][sKey]}</div> */}
                      <div className={classes.cardInputWrapper}>
                        <InputUi
                          onChange={sentChange}
                          className={classes.cardInput}
                          sx={{ mb: 0, pl: 1, pr: 1, flex: 1 }}
                          value={translations && translations[key][sKey]}
                          multiline
                          placeholder={tvForm("translatedText")}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  selectionWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "220px !important",
    backgroundColor: theme.palette.inputBackground.main + " !important",
    overflow: "hidden",
  },

  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    borderEnd: "1px solid " + theme.palette.borderColor.main + " !important",
    overflow: "hidden",
    height: "100%",
  },
  itemList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
  },
  items: {
    borderBottom: "1px solid " + theme.palette.borderColor.main + " !important",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    //width:"320px",
    "& > img ": {
      height: 200,
      width: 200,
      margin: "auto",
      border: "2px dashed rgba(152, 162, 179, 0.4)",
      borderRadius: "15px",
    },
  },
  loaded: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#00000057",
    "& > div": {
      width: "fit-content",
      height: "fit-content",
      margin: "auto",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  select: {
    borderRadius: "30px !important",
    backgroundColor: `${theme.palette.inputBackground.main} !important`,
    padding: "10px 20px !important",
    "&[aria-expanded='true']": {
      backgroundColor: `${theme.palette.inputBackground.light} !important`,
    },
  },
  selectRoot: {
    flexGrow: "1",
  },
}));

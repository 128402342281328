import React from "react";
import useStyles from "./styles";
import { Checkbox, FormControlLabel } from "@mui/material";
export default function CheckboxUi(props) {
  var classes = useStyles();
  const { label } = props;
  return (
    <div className={classes.Checkbox}>
      <FormControlLabel size="invisible" control={<Checkbox {...props} />} label={label} />
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { socketDisconnect } from "../customHooks/hookRepositories/useChat";

const token = sessionStorage.getItem("id_token")
  ? sessionStorage.getItem("id_token")
  : localStorage.getItem("id_token");
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: !!token,
    // isAuthenticated: true,
    token,
  },
  reducers: {
    signOut(state, action) {
      socketDisconnect();
      localStorage.removeItem("id_token");
      sessionStorage.removeItem("id_token");
      return { isAuthenticated: false, token: "" };
    },
    setUserInfo(state, { payload: { info } }) {
      return { ...state, info };
    },
    signIn(state, { payload: { token, rMe, info } }) {
      if (rMe) {
        sessionStorage.removeItem("id_token");
        localStorage.setItem("id_token", token);
      } else {
        localStorage.removeItem("id_token");
        sessionStorage.setItem("id_token", token);
      }

      return { isAuthenticated: true, token, info };
    },
  },
});

export const { signOut, signIn, setUserInfo } = authSlice.actions;
export default authSlice.reducer;

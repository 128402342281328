import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logo: ({ width, height }) => ({
    width,
    height,
    margin: "auto",
    "& img": {
      width: "100%",
      height: "100%",
    },
  }),
}));

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  cardWrapper: {
    padding: "10px 20px",
    borderBottom: `1px solid ${theme.palette.description.main}26`,
    display: "flex",
    gap: 8,
    maxWidth: 345,
    cursor: "pointer",

    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
    },
  },
  profileWrapper: {
    display: "flex",
    position: "relative",
  },
  profile: {
    width: 50,
    height: 50,
    borderRadius: 100,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  unread: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.error.main,
    width: 10,
    height: 10,
    border: `1px solid #fff`,
    borderRadius: 100,
    zIndex: 1000000000000,
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: "center",
  },
  massage: {
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "start",
    maxWidth: 186,
    color: theme.palette.description.main,
    fontSize: 13,
  },
  name: {
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "start",
    maxWidth: 186,
    fontSize: 14,
  },
  date: {
    color: theme.palette.description.main,
    fontSize: 13,
  },
}));

import { Button, Checkbox, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListLayout from "../../components/Layouts/ListLayout/ListLayout";
import { setToolbar } from "../../redux/ToolbarSlice";
import FiltersEnum from "../../configs/FiltersEnum";
import CommentsCard from "./components/CommentsCard/CommentsCard";
import useComments from "../../customHooks/hookRepositories/useComments";
import Limits from "../../configs/Limits";
import useGetConstant from "../../customHooks/hookRepositories/useGetConstant";
import DeleteModul from "../../components/Modals/DeleteModul/DeleteModul";
import useStyles from "./styles";
import Icons from "../../assets/icons/Icons";
import useCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";

export default function Comments(props) {
  var classes = useStyles();
  const dispatch = useDispatch();
  const { getComments, approve, deleteComments, reply } = useComments();
  const { getExhibitions } = useGetConstant();
  const { type } = useSelector(s => s.roles);
  useEffect(() => {
    getExhibitions();
    // eslint-disable-next-line
  }, []);
  const { exhibitions } = useSelector((s) => s.constants);
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Comments",
        title: { name: tv("comments") },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("comments") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  useEffect(() => {
    fetchComments({ page: 1 });
    // eslint-disable-next-line
  }, []);
  const fetchComments = async (query) => {
    checkboxHandler();
    await getComments({
      limit: Limits.tabels.comments.main,
      page: 1,
      ...query,
    }).then((res) => {
      if (res) {
        setData(res);
      }
    });
  };
  //----states
  const [query, setQuery] = useState({});
  const [checked, setChecked] = useState([]);
  const [checkedA, setCheckedA] = useState([]);
  const [{ itemsList, paginator }, setData] = useState({});
  const { currentPage, pageCount } = paginator ? paginator : {};
  const onQueryCheng = (e, type) => {
    const newQ = { ...query };
    newQ[type] = e.target ? e.target.value : e;
    setQuery({ ...newQ });
    fetchComments({ ...newQ });
  };
  const checkboxHandler = (id) => {
    if (!id) {
      setCheckedA([]);
      setChecked([]);
      return;
    }
    if (checked.includes(id)) {
      setChecked(checked.filter((v) => v !== id));
    } else {
      setChecked([...checked, id]);
    }
    const approveCheck = itemsList.filter((x) => x._id === id)[0].isApproved;
    if (!approveCheck) {
      if (checkedA.includes(id)) {
        setCheckedA(checkedA.filter((v) => v !== id));
      } else {
        setCheckedA([...checkedA, id]);
      }
    }
  };
  const TabelHeader =
    checked.length > 0
      ? {
        component: (
          <div className={classes.chechedOptions}>
            <div className={classes.checkbox}>
              <Checkbox indeterminate onClick={() => checkboxHandler()} />
            </div>
            {checkedA.length > 0 && (
              <div className={classes.checkedBtn}>
                <Button
                  onClick={() => onApprove(checkedA)}
                  variant="text"
                  color="success"
                  startIcon={<Icons.TickCircle />}
                >
                  {tv("approve")}
                </Button>
              </div>
            )}
            <div className={classes.checkedBtn}>
              <Button
                onClick={() => onDelete(checked)}
                variant="text"
                color="error"
                startIcon={<Icons.Trash />}
              >
                {tv("delete")}
              </Button>
            </div>
          </div>
        ),
      }
      : {
        sort: {
          type: "sort",
          children: FiltersEnum.Sort,
        },
        filter: type === "admins" ? [
          {
            api: true,
            type: "exhibitionId",
            children: [{ name: "exhibition", value: "None" }].concat(
              exhibitions,
            ),
          },
          {
            type: "has_approving",
            children: [
              { value: "None", name: "allComments" },
              { value: 1, name: "approved" },
              { value: 0, name: "notApproved" },
            ],
          },
        ] : [
          {
            type: "has_approving",
            children: [
              { value: "None", name: "allComments" },
              { value: 1, name: "approved" },
              { value: 0, name: "notApproved" },
            ],
          }],
      };

  const onPageChange = async (e, page) => {
    await fetchComments({ ...query, page });
    window.scrollTo(0, 0);
  };

  const onApprove = async (ids) => {
    await approve(ids);
    fetchComments({
      ...query,
      page:
        query.has_approving === 0 &&
          ids.length >= itemsList.length &&
          currentPage === pageCount
          ? currentPage - 1
          : currentPage,
    });
  };
  const { deleteModul } = DeleteModul();
  const onDelete = (ids) => {
    deleteModul(async () => {
      await deleteComments(ids);
      fetchComments({
        ...query,
        page:
          ids.length >= itemsList.length && currentPage === pageCount
            ? currentPage - 1
            : currentPage,
      });
    });
  };
  const onReply = async (body) => {
    await reply(body);
    fetchComments({
      ...query,
      page: currentPage,
    });
  };
  return (
    <ListLayout
      pagination={{
        count: pageCount,
        page: currentPage ? currentPage : 1,
        onChange: onPageChange,
      }}
      TabelHeader={{
        ...TabelHeader,
        onChange: onQueryCheng,
        value: query,
      }}
      hasData
    >
      {itemsList &&
        itemsList.map((x) => (
          <Grid container key={x._id} item xs={12} sx={{ display: "flex" }}>
            <CommentsCard
              {...x}
              onCheckboxChange={checkboxHandler}
              checked={checked}
              onApprove={onApprove}
              onDelete={onDelete}
              onReply={onReply}
            />
          </Grid>
        ))}
    </ListLayout>
  );
}

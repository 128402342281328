import React, { useState } from "react";
import useStyles from "./styles";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import useFiles from "../../../customHooks/hookRepositories/useFiles";
import { MainUrl, ResponseStatus } from "../../../services/Api";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

export default function UploadPreviewUi({
  onCancel,
  files,
  preview,
  onSubmit,
}) {
  const tv = useCustomTranslate('forms')
  const tvModal = useCustomTranslate('modal')
  const classes = useStyles();
  const [value, setValue] = useState({
    description: preview ? preview.des : "",
    file: files ? files[0] : preview?.path,
  });
  const onValueChange = (e, name) => {
    setValue((currentValue) => {
      currentValue[name] = e;
      return Object.assign([{}], currentValue);
    });
  };
  const { addOne } = useFiles();
  const [error, setError] = useState("");
  const submitHandler = () => {
    setError("");
    if (value.description) {
      if (onSubmit) {
        onSubmit(value);
      } else {
        addOne({
          type: "files",
          body: value,
        }).then((res) => {
          if (res.status === ResponseStatus.Success) {
            onCancel();
          }
        });
      }
    } else {
      setError(tv('fillMsg'));
    }
  };
  return (
    <Grid className={classes.root} container>
      <Grid item xs={12} className={classes.preview}>
        <Grid container gap={2}>
          <Grid gap={2} item xs={12} display={"flex"} alignItems={"center"}>
            <img
              alt=""
              className={classes.imgStyle}
              src={
                preview
                  ? `${MainUrl}${preview.path}`
                  : files && files[0] && URL.createObjectURL(files[0])
              }
            />
            <Typography className={classes.text}>
              {!files ? preview?.meta.name : files[0].name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className={[classes.des].join(" ")}
              placeholder={tvModal('uploadFileDesc')}
              variant="standard"
              value={value.description}
              onChange={(e) => {
                setError("");
                onValueChange(e.target.value, "description");
              }}
              error={!!error}
              helperText={error}
              InputProps={{
                disableUnderline: true,
                sx: { fontSize: 13 },
                endAdornment: <InputAdornment position="end"></InputAdornment>,
              }}
            />
            <div className={ classes.filesInput} >{tvModal("filesInput")}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.uploadBtn} gap={3}>
        <Button onClick={onCancel} variant={"outlined"}>
          {tv('cancel')}
        </Button>
        <Button
          onClick={() => {
            submitHandler();
          }}
          variant={"contained"}
        >
          {tvModal('btnUpload')}
        </Button>
      </Grid>
    </Grid>
  );
}

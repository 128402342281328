import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  titleIcon: {
    width: 28,
    display: "inline-block",
    "& > svg": {
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
    },
  },
  dashbordCardWrapper: {
    display: "flex",
    margin: "16px 0",
    gap: 14,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  dashbordCard: {
    flex: 1,
  },
  chartSections: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    display: "flex",
    fontSize: ".9rem",
    color: theme.palette.description.main,
  },
  amount: {
    color: "#475467",
    fontWeight: 600,
    fontSize: "2rem",
  },
}));

import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteEnum from "../navigation/RouteEnum";
import Themes from "../themes";
// components
import LoginLayout from "./Layouts/LoginLayout";
import Layout from "./Layout";
// pages
import Login from "../pages/login";
import Dashboard from "../pages/Dashboard";
import ForgetPassword from "../pages/ForgetPassword";
import Register from "../pages/Register";
import Exhibitions from "../pages/Exhibitions";
import Comments from "../pages/Comments";
import Files from "../pages/Files";
import Analysis from "../pages/Analysis";
import Salons from "../pages/Salons";
import Booths from "../pages/Booths";
import Organizers from "../pages/Organizers";
import Exhibitors from "../pages/Exhibitors";
import Visitors from "../pages/Visitors";
import Events from "../pages/Events/Events";
import Language from "../pages/Language";
import Settings from "../pages/Settings";
import Exhibitor from "../pages/Exhibitor";
// context
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { setDir } from "../redux/DirSlice";
import Toastify from "./Toastify/Toastify";
import LoaderComponent from "./LoaderComponent/LoaderComponent";
import useProfile from "../customHooks/hookRepositories/useProfile";
import Organizer from "../pages/Organizer/Organizer";
import NotFound from "../pages/NotFound/NotFound";
import "swiper/css";
import EmailConfirm from "../pages/EmailConfirm/EmailConfirm";

export default function App() {
  // global
  const dispatch = useDispatch();
  const { theme } = useSelector((s) => s.theme);
  const { t } = useTranslation();
  const isRtl = t("dir");
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { type } = useSelector((s) => s.roles);
  const { getUserInfo } = useProfile();
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const body = document.querySelector("body");
    const dirAttr = body.getAttribute("dir");
    if (dirAttr !== isRtl) {
      body.setAttribute("dir", isRtl);
    }
    dispatch(setDir({ dir: isRtl }));
  }, [isRtl, dispatch]);
  return (
    <div>
      <ThemeProvider theme={Themes[theme]}>
        <CssBaseline />
        <Toastify />
        <LoaderComponent />
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                !isAuthenticated ? (
                  <NotFound />
                ) : type ? (
                  <NotFound />
                ) : (
                  <LoaderComponent />
                )
              }
            />
            {/* admins */}
            {type === "admins" && (
              <Route path={RouteEnum.admin} element={<Layout />}>
                <Route index element={<Dashboard />} />
                {/* user */}
                <Route
                  path={RouteEnum.user.Organizers + "/*"}
                  element={<Organizers />}
                />
                <Route
                  path={RouteEnum.user.Exhibitors + "/*"}
                  element={<Exhibitors />}
                />
                <Route
                  path={RouteEnum.user.Visitors + "/*"}
                  element={<Visitors />}
                />
                {/* user */}
                <Route
                  path={RouteEnum.Exhibitions + "/*"}
                  element={<Exhibitions />}
                />
                {/* Exhibitions parts */}
                <Route path={RouteEnum.Salons() + "/*"} element={<Salons />} />
                <Route path={RouteEnum.Booths() + "/*"} element={<Booths />} />
                {/* Exhibitions parts */}
                <Route path={RouteEnum.Events + "/*"} element={<Events />} />
                <Route path={RouteEnum.Comments} element={<Comments />} />
                <Route path={RouteEnum.Files} element={<Files />} />
                <Route path={RouteEnum.Analysis} element={<Analysis />} />
                {/* Settings */}
                <Route
                  path={RouteEnum.Language + "/*"}
                  element={<Language />}
                />
                <Route path={RouteEnum.Settings} element={<Settings />} />
                {/* Settings */}
              </Route>
            )}
            {/* admins */}
            {/* exhibitors */}
            {type === "exhibitors" && (
              <Route
                path={RouteEnum.exhibitor + "/*"}
                element={<Exhibitor />}
              />
            )}
            {/* exhibitors */}
            {/* organizers */}
            {type === "organizers" && (
              <Route
                path={RouteEnum.Organizer + "/*"}
                element={<Organizer />}
              />
            )}
            {/* organizers */}
            <Route path="/" element={<LoginLayout />}>
              <Route path={RouteEnum.Login} element={<Login />} />
              <Route
                path={RouteEnum.ForgotPassword}
                element={<ForgetPassword />}
              />
              <Route path={RouteEnum.EmailConfirm} element={<EmailConfirm/>} />
              <Route path={RouteEnum.Register} element={<Register />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

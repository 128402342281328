import {createSlice} from "@reduxjs/toolkit";

const progressBarSlice = createSlice({
    name: 'progressBar',
    initialState: 0,
    reducers: {
        setProgressBar(state,action){
            return action.payload;
        }
    }
})

export const {setProgressBar} = progressBarSlice.actions;
export default progressBarSlice.reducer

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  titleWrapper: {
    marginBottom: 15,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
  },
  link: {
    display: "flex",
    color: theme.palette.primary.main,
    gap: 12,
  },
  linkIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    "& > svg": {
      transform: "rotate(-90deg)",
      width: 15,
      height: 15,
      "& > path": {
        stroke: theme.palette.primary.main,
      },
    },
  },
  linkIconRtl: {
    "& > svg": {
      transform: "rotate(90deg)",
    }
  },
  description: {
    color: theme.palette.description.main
  }
}));

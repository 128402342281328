import { useDispatch, useSelector } from "react-redux";
import Limits from "../../configs/Limits";
import { showAlert } from "../../redux/AlertsSlice";
import { setTable } from "../../redux/TableSlice";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import useDelete from "../useDelete";
import useGet from "../useGet";

const useAnalysis = () => {
  const Delete = useDelete();
  const fetchGet = useGet();
  const dispatch = useDispatch();
  const {
    itemsList,
    paginator: { currentPage, pageCount },
  } = useSelector((s) => s.table);
  const analysisUrl = (query) =>
    `${BaseUrl}/admins/user-analysis${
      query
        ? "?&" +
          Object.keys(query)
            .filter((x) => query[x] !== "None")
            .map((key) => `${key}=${query[key]}`)
            .join("&")
        : ""
    }`;
  const {
    tabels: { analysis },
  } = Limits;
  const getAnalysis = async (props) => {
    try {
      let res = await fetchGet(analysisUrl({ limit: analysis, ...props }));
      if (res.status === ResponseStatus.Success) {
        dispatch(setTable({ ...res.data, type: "analysis" }));
      } else {
        dispatch(
          setTable({
            itemsList: [],
            paginator: {},
          }),
        );
      }
    } catch (e) {
      console.log("error", e);
      dispatch(
        setTable({
          itemsList: [],
          paginator: {},
        }),
      );
    }
  };

  const deleteAnalysis = async ({ ids: oldIds, q } = {}) => {
    const deletesUrl = `${BaseUrl}/admins/user-analysis`;
    const ids = Array.isArray(oldIds) ? oldIds : [oldIds];
    try {
      let res = await Delete(deletesUrl, {
        ids,
      });
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: "user deleted", type: "success" },
          }),
        );
        getAnalysis({
          page:
            ids.length >= itemsList.length && currentPage === pageCount
              ? currentPage - 1
              : currentPage,
          q,
        });
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return { getAnalysis, deleteAnalysis };
};

export default useAnalysis;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  noDataWrapper: {
    height: "calc(100vh - 193px)",
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: 6,
  },
  title: {
    fontSize: 18,
    color: theme.palette.description.main,
  },
  description: {
    fontSize: 14,
    color: theme.palette.description.main,
  },
}));

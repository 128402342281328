import React, { useEffect, useState } from "react";
import AddForm from "../../../../components/AddForm/AddForm";
import AddIcon from "../../../../components/AddForm/components/AddIcon/AddIcon";
import { MainUrl } from "../../../../services/Api";
import { useDispatch, useSelector } from "react-redux";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import AddFile from "../../../../components/AddForm/components/AddFile/AddFile";
import useStyles from "./styles";
import { UploadFileType } from "../../../../configs/FormEnum";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import useGetConstant from "../../../../customHooks/hookRepositories/useGetConstant";
import useExhibitions from "../../../../customHooks/hookRepositories/useExhibitions";
import useSettings from "../../../../customHooks/hookRepositories/useSettings";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";

export default function OrganizerSettings(props) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    sponsors: [],
    standBanners: [],
    advertisingTv: [],
    currencyId: "None",
    isActiveRadio: false,
    isActiveChatroom: false,
  });
  const [currency, setCurrency] = useState([]);
  const dispatch = useDispatch();
  const tv = useCustomTranslate("forms");
  const tvPages = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");

  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Settings",
        title: { name: tvPages("settings") },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvPages("settings") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  const { getOne } = useExhibitions();
  const { updateSettings } = useSettings();
  const { info } = useSelector((s) => s.auth);
  const { getConstants } = useGetConstant();
  useEffect(() => {
    const {
      email,
      phoneNumber,
      name,
      family,
      extraInfo: { exhibitionId } = {},
    } = info ? info : {};
    const getCategoriesArray = async () => {
      await getConstants({
        name: "currencyName",
        type: "currency",
        src: "currencies?limit=1000",
      }).then((res) => {
        setCurrency(res);
      });
    };
    getCategoriesArray();
    const setData = async () => {
      const res = await getOne({ name: "exhibitions", id: exhibitionId });
      delete res.currency;
      setFormData({
        ...res,
        email,
        phoneNumber,
        name,
        family,
      });
    };
    setData();
    // eslint-disable-next-line
  }, [info]);

  const inputHandler = (value, type) => {
    const newValue = { ...formData };
    newValue[type] = value;
    setFormData(newValue);
  };
  const { isValid, errorHandler } = useAuth();
  const submitHandler = (error) => {
    const validation = {};
    exForm.forEach(({ col: { fields } }) => {
      fields.forEach(({ required, valueId }) => {
        if (required && (!formData[valueId] || formData[valueId] === "None")) {
          validation[valueId] = tv("fillMsg");
        }
      });
    });
    passwordForm.forEach(({ col: { fields } }) => {
      fields.forEach(({ required, valueId }) => {
        if (required && (!formData[valueId] || formData[valueId] === "None")) {
          validation[valueId] = tv("fillMsg");
        }
      });
    });
    Object.keys(validation).length > 0 && errorHandler({ validation });
    if (
      Object.keys(validation).length > 0 ||
      !isValid({
        email: formData.email,
        password: formData.newPassword && formData.newPassword,
        confirmPassword: formData.confirmPassword && formData.confirmPassword,
      }) ||
      error
    ) {
      return;
    }
    updateSettings("organizers", formData);
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          // {
          //   width: [2, 4],
          //   valueId: "avatar",
          //   title: "Avatar",
          //   component: (
          //     <AddIcon
          //       valueId="logo"
          //       src={formData.logo && `${MainUrl}${formData.logo}`}
          //       onChange={inputHandler}
          //       id="ExhibitionLogo"
          //     />
          //   ),
          // },
          {
            width: [2, 4],
            valueId: "name",
            title: tv("firstName"),
            required: true,
            input: {
              placeholder: tv("firstNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "family",
            title: tv("lastName"),
            required: true,
            input: {
              placeholder: tv("lastNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "email",
            title: tv("email"),
            required: true,
            input: {
              placeholder: tv("emailPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "phoneNumber",
            title: tv("phoneNumber"),
            required: true,
            input: {
              dir: "ltr",
              type: "tel",
              placeholder: tv("phoneNumberPlaceholder"),
            },
          },
        ],
      },
    },
  ];

  const exForm = [
    {
      col: {
        direction: "row",
        width: 6,
        fields: [
          {
            width: [4, 8],
            valueId: "logo",
            title: tv("logo"),
            component: (
              <AddIcon
                valueId="logo"
                size="w:720px, h:1280px"
                src={formData.logo && `${MainUrl}${formData.logo}`}
                onChange={inputHandler}
                id="ExhibitionLogo"
              />
            ),
          },
          {
            width: [4, 8],
            valueId: "exhibitionName",
            title: tv("exhibitionName"),
            required: true,
            input: {
              placeholder: tv("exhibitionNamePlaceholder"),
            },
          },
          {
            width: [4, 8],
            valueId: "exhibitionMessage",
            title: tv("message"),
            required: true,
            input: {
              placeholder: tv("messagePlaceholder"),
            },
          },
          {
            width: [4, 8],
            valueId: "category",
            title: tv("category"),
            required: true,
            input: {
              placeholder: tv("categoryPlaceholder"),
            },
          },
          {
            width: [4, 8],
            valueId: "currencyId",
            title: tv("currency"),
            required: true,
            select: {
              placeholder: tv("currencyPlaceholder"),
              children: [
                { name: tv("currencyPlaceholder"), value: "None" },
              ].concat(currency),
            },
          },
        ],
      },
    },
    {
      col: {
        direction: "column",
        width: 6,
        fields: [
          {
            width: [2, 10],
            flexDirection: "column",
            valueId: "mapImage",
            title: tv("exhibitionMap"),
            subTitle: tv("exhibitionMapDesc"),
            required: false,
            uploader: {
              fullWidth: true,
              fileType: UploadFileType.image,
              placeholder: "Drag a file here or Select from your device",
              description: `( ${tv("bestSize")} w:800px, h:600px )`,
            },
          },
        ],
      },
    },
  ];

  const passwordForm = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "oldPassword",
            title: tv("oldPassword"),
            input: {
              type: "password",
              autoComplete: "no",
              placeholder: tv("oldPasswordPlaceholder"),
            },
          },
          {
            width: [0, 6],
          },
          {
            width: [2, 4],
            valueId: "newPassword",
            title: tv("password"),
            required: !!formData.oldPassword,
            input: {
              autoComplete: "no",
              type: "password",
              placeholder: tv("passwordPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "confirmPassword",
            title: tv("confirmPassword"),
            required: !!formData.oldPassword,
            input: {
              autoComplete: "no",
              type: "password",
              placeholder: tv("confirmPasswordPlaceholder"),
            },
          },
        ],
      },
    },
  ];

  const afterForm = [
    <div key="ExhibitionSettings" className={classes.notifications}>
      {tv("exhibitionSettings")}
    </div>,
    <AddForm
      value={formData}
      form={exForm}
      onInputChange={inputHandler}
      cancelDisable
    />,
    <AddFile
      onChange={inputHandler}
      valueId="standBanners"
      value={formData.standBanners}
      key="Stand Banners"
      ratio={1 / 2}
      uploadProps={{
        description: `( ${tv("bestSize")} w:720px, h:1440px )`,
      }}
      title={tv("standBanners")}
      description={tv("standBannersDesc")}
      placeholder="Select a stand banner image"
      fileType={UploadFileType.image}
      limit={2}
    />,
    <AddFile
      onChange={inputHandler}
      valueId="advertisingTv"
      value={formData.advertisingTv}
      key="Advertising TV"
      title={tv("advTv")}
      description={tv("advTvDesc")}
      placeholder="Select a video for advertising tv"
      fileType={UploadFileType.video}
      limit={4}
    />,
    <AddFile
      onChange={inputHandler}
      valueId="radio"
      value={formData.radio}
      key="Exibition Radio"
      title={tv("exhibitionRadio")}
      description={tv("exhibitionRadioDesc")}
      placeholder="Select a audio for exhibition radio"
      fileType={UploadFileType.audio}
      switchBtn={{
        title: tvPages("active"),
        checked: formData.isActiveRadio,
        onChange: (e) => inputHandler(e.target.checked, "isActiveRadio"),
      }}
    />,
    //tv
    <AddFile
      onChange={inputHandler}
      valueId="sponsors"
      limit={10}
      uploadProps={{
        width: 150,
        height: 150,
        title: tv("selectLogo"),
        description: (
          <>
            {tv("bestSize")}
            <div>w:720 px, h:720 px </div>
          </>
        ),
        className: classes.upload,
      }}
      value={formData.sponsors}
      key="sponsorsLogo"
      title={tv("sponsorsLogo")}
      description={tv("sponsorsLogoDesc")}
      placeholder={tv("selectLogo")}
      fileType={UploadFileType.image}
    />,
    <AddFile
      key="Chat Room"
      title={tv("chatRoom")}
      description={tv("chatRoomDesc")}
      switchBtn={{
        title: tvPages("active"),
        checked: formData.isActiveChatroom,
        onChange: (e) => inputHandler(e.target.checked, "isActiveChatroom"),
      }}
    />,
    <div key="Password" className={classes.notifications}>
      {tv("password")}
    </div>,
    <AddForm
      value={formData}
      form={passwordForm}
      onInputChange={inputHandler}
      cancelDisable
    />,
  ];
  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
        afterForm={afterForm}
      />
    </div>
  );
}

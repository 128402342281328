import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    padding: "0 35px",
    position: "relative",
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    userSelect: "none",
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    // maxHeight: 200,
  },
  swiper: {
    "& .swiper-button-prev": {
      left: -20,
      zIndex: 100,
    },
  },
  nextEl: {
    cursor: "pointer",
    position: "absolute",
    top: "calc(50% - 30px)",
    right: -20,
    "-webkit-transform": "rotate(-90deg)",
    "-moz-transform": "rotate(-90deg)",
    "-ms-transform": "rotate(-90deg)",
    "-o-transform": "rotate(-90deg)",
    transform: "rotate(-90deg)",
  },
  prevEl: {
    cursor: "pointer",
    position: "absolute",
    top: "calc(50% - 30px)",
    left: -20,
    "-webkit-transform": "rotate(90deg)",
    "-moz-transform": "rotate(90deg)",
    "-ms-transform": "rotate(90deg)",
    "-o-transform": "rotate(90deg)",
    transform: "rotate(90deg)",
  },
  disabled: {
    opacity: 0.5,
    cursor: "default",
  },
  icon: {
    width: 60,
    height: 60,
    "& > path": {
      stroke: theme.palette.primary.main,
    },
  },
}));

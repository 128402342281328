import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { showAlert } from "../../redux/AlertsSlice";
import { setExhibitions } from "../../redux/ExhibitionsSlice";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import Limits from "../../configs/Limits";
import useGet from "../useGet";
import usePost from "../usePost";
import useAuth from "./useAuth";
import usePut from "../usePut";
import useDelete from "../useDelete";
const useExhibitions = () => {
  const dispatch = useDispatch();
  const fetchGet = useGet();
  const post = usePost();
  const put = usePut();
  const del = useDelete();

  const { tabels: TabelsLimit } = Limits;

  const getBaseUrl = ({
    name,
    exhibitionId,
    salonId,
    limit = TabelsLimit.exhibitions.exhibitions,
    query = {},
  }) =>
    `${BaseUrl}/admins/${name ? name : "exhibitions"}${
      exhibitionId ? `/${exhibitionId}/salons` : ""
    }${salonId ? `/${salonId}/booths` : ""}?&limit=${limit}&${Object.keys(query)
      .filter((x) => query[x] !== "None")
      .map((key) => `${key}=${query[key]}`)
      .join("&")}`;

  const getAll = async ({
    name,
    exhibitionId,
    salonId,
    query,
    byName,
    limit,
  }) => {
    try {
      let res = await fetchGet(
        getBaseUrl({
          name: byName && name,
          exhibitionId,
          salonId,
          limit: limit ? limit : TabelsLimit.exhibitions[name],
          query,
        }),
      );
      if (res.status === ResponseStatus.Success) {
        const newGet = {};
        newGet[name] = res.data;
        dispatch(setExhibitions(newGet));
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const getBoothUrl = (query) =>
    `${BaseUrl}/admins/booths${
      query
        ? "?&" +
          Object.keys(query)
            .filter((x) => query[x] !== "None")
            .map((key) => `${key}=${query[key]}`)
            .join("&")
        : ""
    }`;

  const getAllBooth = async (query) => {
    try {
      let res = await fetchGet(
        getBoothUrl({ limit: TabelsLimit.exhibitions.booths, ...query }),
      );
      if (res.status === ResponseStatus.Success) {
        dispatch(setExhibitions({ booths: res.data }));
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const oneUrl = (type, id) => `${BaseUrl}/admins/${type}${id ? `/${id}` : ""}`;
  const getOne = async ({ name, id }) => {
    try {
      let res = await fetchGet(oneUrl(name, id));
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const { errorHandler } = useAuth();
  const navigate = useNavigate();

  const addOne = async ({ type, body }) => {
    try {
      let res = await post(oneUrl(type), body);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `${type} added`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const update = async ({ type, body, id, exhibitionId, salonId }) => {
    try {
      let res = await put(oneUrl(type, id), body);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `${type} updated`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const state = useSelector((s) => s.exhibitions);
  const { exhibitions } = TabelsLimit;

  const updateStatus = async ({ type, id, query, exhibitionId, salonId }) => {
    try {
      let res = await put(`${BaseUrl}/admins/${type}/${id}/status`);
      if (res.status === ResponseStatus.Success) {
        const {
          paginator: { currentPage, pageCount },
          itemsList,
        } = state[type];
        if (type === "booths" && !exhibitionId && !salonId) {
          getAllBooth(query);
        } else {
          getAll({
            name: type,
            page:
              itemsList.length === 1 &&
              (query.is_active === 0 || query.is_active === 1) &&
              currentPage === pageCount
                ? currentPage - 1
                : currentPage,
            query,
            exhibitionId,
            salonId,
            limit: exhibitions[type],
          });
        }
        dispatch(
          showAlert({
            notify: { message: `${type} status changed`, type: "success" },
          }),
        );
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const deleteOne = async ({ type, id, query, exhibitionId, salonId }) => {
    try {
      let res = await del(`${BaseUrl}/admins/${type}/${id}`);
      if (res.status === ResponseStatus.Success) {
        const {
          paginator: { currentPage, pageCount },
          itemsList,
        } = state[type];
        getAll({
          name: type,
          page:
            itemsList.length === 1 && currentPage === pageCount
              ? currentPage - 1
              : currentPage,
          query,
          exhibitionId,
          salonId,
          limit: exhibitions[type],
        });

        dispatch(
          showAlert({
            notify: { message: `${type} Deleted`, type: "success" },
          }),
        );
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  return {
    getAll,
    addOne,
    updateStatus,
    getOne,
    update,
    deleteOne,
    getAllBooth,
  };
};

export default useExhibitions;

import React from "react";
// import useStyles from "./styles";
import { Route, Routes } from "react-router";
import OrganizersList from "./components/OrganizersList";
import AddOrganizer from "./components/AddOrganizer";
import EditOrganizer from "./components/EditOrganizer/EditOrganizer";
import NotFound from "../NotFound/NotFound";
export default function Organizers(props) {
  return (
    <Routes>
      <Route path="*" element={ <NotFound />} />
      <Route path={"/"} element={<OrganizersList />} />
      <Route path={"add"} element={<AddOrganizer />} />
      <Route path={"edit/:id"} element={<EditOrganizer />} />
    </Routes>
  );
}

import React, { useState } from "react";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import InputUi from "../../components/BaseElement/InputUi/InputUi";
import ButtonUi from "../../components/BaseElement/ButtonUi/ButtonUi";
import useAuth from "../../customHooks/hookRepositories/useAuth";
import { useSelector } from "react-redux";
import Icons from "../../assets/icons/Icons";

export default function ChangePassword({ code, email, phoneNumber, tv }) {
  var classes = useStyles();
  const { validation } = useSelector((state) => state.alerts);
  var [password, setPassword] = useState("");
  var [confirmPassword, setConfirmPassword] = useState("");
  const { changePassword, isValid } = useAuth();
  const submitHandler = () => {
    if (
      isValid({
        password,
        confirmPassword,
      })
    ) {
      changePassword({
        body: {
          email,
          phoneNumber,
          code,
          password,
          confirmPassword,
        },
      });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler();
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom component="div">
        {tv("newPasswordTitle")}
      </Typography>
      <InputUi
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder={tv("newPassword")}
        type="password"
        error={!!(validation && validation.password)}
        helperText={validation && validation.password}
        icon={<Icons.Key />}
      />
      <InputUi
        id="confirmPassword"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder={tv("confirmPassword")}
        type="password"
        error={!!(validation && validation.confirmPassword)}
        helperText={validation && validation.confirmPassword}
        icon={<Icons.Key />}
        inputProps={{
          onKeyPress: handleKeyPress,
        }}
      />
      <ButtonUi
        disabled={password.length === 0 || confirmPassword.length === 0}
        onClick={submitHandler}
      >
        <Icons.Lock className={classes.CheckIcon} /> {tv("changePassword")}
      </ButtonUi>
    </React.Fragment>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const constantsSlice = createSlice({
  name: "constants",
  initialState: {
    languages: [],
    phoneCodes: [],
    isSend: false,
    exhibitions: [],
  },
  reducers: {
    setConstants(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setConstants } = constantsSlice.actions;
export default constantsSlice.reducer;

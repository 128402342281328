import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BoxUi from "../BaseElement/BoxUi/BoxUi";
import InputUi from "../BaseElement/InputUi/InputUi";
import useStyles from "./styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { showAlert } from "../../redux/AlertsSlice";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import AddUploadUi from "./components/AddUploadUi";
import useCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import PersianDatepicker from "../PersianDatepicker/PersianDatepicker";
import { useTranslation } from "react-i18next";
import MoltiTimePicker from "react-multi-date-picker/plugins/time_picker";
import { Close, Warning } from "@mui/icons-material";

export default function AddForm({
  form,
  afterForm,
  imageWarning,
  title,
  onSubmit,
  value,
  cancelDisable,
  onInputChange,
  sx,
  className,
}) {
  const [open, setOpen] = useState(true)
  const tv = useCustomTranslate("forms");
  const { t } = useTranslation();
  const isRtl = t("dir") === "rtl";
  var classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    toast.dismiss();
    dispatch(showAlert({}));
  }, [dispatch]);
  const [error, setError] = useState({});
  const submit = () => {
    const error = {};
    setError(error);
    form.forEach(({ col: { fields } }) => {
      fields.forEach(({ required, valueId }) => {
        if (
          required &&
          (!(value[valueId] || value[valueId] === 0) ||
            value[valueId] === "None")
        ) {
          error[valueId] = tv("fillMsg");
        }
        if (valueId === "expireDate" && value[valueId] === "Invalid date") {
          error[valueId] = tv("fillMsg");
        }
      });
    });
    setError(error);
    var size = Object.keys(error).length;
    onSubmit && onSubmit(!!size);
  };
  const { validation } = useSelector((state) => state.alerts);

  const errorHandler = (id, isTel) => {
    // `${id}.number`
    // id
    return validation && (validation[id] || validation[`${id}.number`])
      ? validation[id]
        ? validation[id]
        : validation[`${id}.number`]
      : error[id]
        ? error[id]
        : "";
  };
  const { dir } = useSelector((s) => s);
  const rtlClassMain = dir.dir === "rtl" ? classes.rtlMain : "";
  const rtlClass = dir.dir === "rtl" ? classes.rtl : "";
  return (
    <div className={classes.wrapper}>

      <BoxUi sx={sx} className={className}>
        {open && imageWarning && <div className={classes.ImageTypeWarningWrapper}>
          <div className={classes.ImageTypeWarning}>
            <Warning sx={{ fontSize: 12 }} />
            {tv("imageWarning")}
          </div>
          <IconButton onClick={() => setOpen(false)} ><Close sx={{ fontSize: 12 }} /></IconButton>
        </div>}
        <Grid
          container
          spacing={2.5}
          className={classes.formWrapper}
          sx={{ p: 2.5 }}
        >
          {form.map(({ col }, indx) => (
            <Grid
              container
              item
              xs={col.width}
              sx={col.sx}
              spacing={2.5}
              key={indx}
              wrap={col.direction === "column" ? "nowrap" : "wrap"}
              direction={col.direction}
              className={classes.gridTemplate}
            >
              {col.fields.map(
                (
                  {
                    input,
                    component,
                    datePicker,
                    timePicker,
                    dateTimePicker,
                    uploader,
                    required,
                    valueId,
                    title,
                    subTitle,
                    flexDirection,
                    width,
                    select,
                    select: { children } = {},
                    sx,
                    titleSx,
                  },
                  i,
                ) => {
                  const isTel = input?.type === "tel";
                  return (
                    <React.Fragment key={i}>

                      {title && (
                        <Grid
                          key={i}
                          className={
                            !flexDirection || flexDirection === "row"
                              ? classes.titleEnd
                              : classes.titleStart
                          }
                          item
                          direction={flexDirection && flexDirection}
                          wrap={"nowrap"}
                          container
                          xs={width[0]}
                          sx={titleSx}
                        >
                          {title}
                          <span className={classes.subTitle}>{subTitle}</span>
                          {required && (
                            <div
                              className={[classes.required, rtlClass].join(" ")}
                            >
                              *
                            </div>
                          )}
                          {input?.inputProps?.maxLength && (
                            <div className={classes.maxLengthBox}>
                              {value[valueId] ? value[valueId].length : 0}/
                              {input.inputProps.maxLength}
                            </div>
                          )}
                        </Grid>
                      )}
                      <Grid
                        className={classes.inputContainer}
                        direction={flexDirection && flexDirection}
                        sx={{
                          alignItems: uploader ? "normal" : "center",
                          ...sx,
                        }}
                        wrap={"nowrap"}
                        container
                        item
                        xs={width[1]}
                      >
                        {input && (
                          <InputUi
                            {...input}
                            className={[classes.fullFlex, input.className].join(
                              " ",
                            )}
                            onChange={({ target: { value } }, { code }) => {
                              onInputChange(
                                value
                                  ? isTel
                                    ? { code, number: value }
                                    : value
                                  : null,
                                valueId,
                              );
                            }}
                            value={
                              value[valueId] || value[valueId] === 0
                                ? isTel
                                  ? value[valueId].number
                                  : value[valueId]
                                : ""
                            }
                            code={value[valueId]?.code}
                            sx={{ mb: 0, flexGrow: 1 }}
                            error={!!errorHandler(valueId, isTel)}
                            helperText={errorHandler(valueId, isTel)}
                            inputsx={{ fontSize: ".8rem" }}
                          />
                        )}
                        {select && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexGrow: 1,
                            }}
                          >
                            <Autocomplete
                              {...select}
                              disableClearable
                              isOptionEqualToValue={(op, val) =>
                                op.name === val.name
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  {...props}
                                  key={option.name}
                                >
                                  {option.name}
                                </Box>
                              )}
                              fullWidth
                              classes={{
                                root: [classes.autocomplete, rtlClassMain].join(
                                  " ",
                                ),
                              }}
                              options={children}
                              getOptionLabel={(option) =>
                                option?.name ? option?.name : option
                              }
                              value={
                                children.filter(
                                  (x) => x.value === value[valueId],
                                )[0]
                                  ? children.filter(
                                    (x) => x.value === value[valueId],
                                  )[0]
                                  : { value: "None", name: "None" }
                              }
                              onChange={(e, n) => {
                                onInputChange(n.value, valueId);
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    // label="Choose a country"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password",
                                    }}
                                  />
                                );
                              }}
                            />
                            {errorHandler(valueId) && (
                              <FormHelperText sx={{ pl: 2, color: "#E31B54" }}>
                                {errorHandler(valueId)}
                              </FormHelperText>
                            )}
                          </Box>
                        )}
                        {datePicker &&
                          (isRtl ? (
                            <PersianDatepicker
                              className={classes.fullFlex}
                              {...datePicker}
                              error={!!errorHandler(valueId)}
                              helperText={errorHandler(valueId)}
                              value={value[valueId] ? value[valueId] : ""}
                              toolbarPlaceholder={"sddf"}
                              onChange={(e) => {
                                onInputChange(
                                  moment(e.toDate()).format("YYYY-MM-DD"),
                                  valueId,
                                );
                              }}
                              format="YYYY/MM/DD"
                            />
                          ) : (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                className={classes.fullFlex}
                                {...datePicker}
                                error={!!errorHandler(valueId)}
                                helperText={errorHandler(valueId)}
                                value={value[valueId] ? value[valueId] : ""}
                                toolbarPlaceholder={"sddf"}
                                onChange={(e) =>
                                  onInputChange(
                                    moment(e).format("YYYY-MM-DD"),
                                    valueId,
                                  )
                                }
                                renderInput={(params) => {
                                  return (
                                    <InputUi
                                      {...params}
                                      sx={{ mb: 0, flexGrow: 1 }}
                                      error={!!errorHandler(valueId)}
                                      helperText={errorHandler(valueId)}
                                    />
                                  );
                                }}
                                inputFormat="yyyy/MM/dd"
                                mask="____/__/__"
                              />
                            </LocalizationProvider>
                          ))}
                        {timePicker && (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              className={classes.fullFlex}
                              {...timePicker}
                              error={!!errorHandler(valueId, required)}
                              helperText={errorHandler(valueId, required)}
                              value={value[valueId] ? value[valueId] : ""}
                              onChange={(e, v) => onInputChange(e, valueId, v)}
                              renderInput={(params) => (
                                <InputUi
                                  {...params}
                                  sx={{ mb: 0, flexGrow: 1 }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                        {dateTimePicker &&
                          (isRtl ? (
                            <PersianDatepicker
                              className={classes.fullFlex}
                              {...datePicker}
                              error={!!errorHandler(valueId)}
                              helperText={errorHandler(valueId)}
                              value={value[valueId] ? value[valueId] : ""}
                              onChange={(e) => {
                                onInputChange(e.toDate(), valueId);
                              }}
                              plugins={[<MoltiTimePicker position="left" />]}
                              format="YYYY/MM/DD - HH:mm"
                            />
                          ) : (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                className={classes.fullFlex}
                                {...timePicker}
                                error={!!errorHandler(valueId, required)}
                                helperText={errorHandler(valueId, required)}
                                value={value[valueId] ? value[valueId] : ""}
                                onChange={(e, v) => {
                                  onInputChange(e, valueId, v);
                                }}
                                renderInput={(params) => (
                                  <InputUi
                                    {...params}
                                    sx={{ mb: 0, flexGrow: 1 }}
                                  />
                                )}
                                inputFormat="yyyy/MM/dd - HH:mm"
                                mask="____/__/__ - __:__"
                              />
                            </LocalizationProvider>
                          ))}
                        {uploader && (
                          <>
                            {errorHandler(valueId) && <Box sx={{
                              color: 'error.main',
                              fontSize: 12,
                              marginBottom: '10px'
                            }} >

                              {tv('noImageError')}
                            </Box>}
                            <AddUploadUi
                              {...uploader}
                              url={value[valueId]}
                              id={valueId}
                              callBack={(url) => {
                                onInputChange(url, valueId);
                              }}
                            />
                          </>
                        )}
                        {component && component}
                      </Grid>
                    </React.Fragment>
                  );
                },
              )}
            </Grid>
          ))}
        </Grid>
      </BoxUi>
      {afterForm}
      {(Object.keys(error).length > 0 || validation) && (
        <div className={classes.error}>{tv("notValid")}</div>
      )}
      {(!cancelDisable || onSubmit) && (
        <div className={classes.btnWrapper}>
          {!cancelDisable && (
            <Link to={"../"}>
              <Button
                color="description"
                sx={{
                  textTransform: "none",
                  padding: "12px 20px",
                  borderRadius: 2.5,
                }}
              >
                {tv("cancel")}
              </Button>
            </Link>
          )}
          {onSubmit && (
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                padding: "12px 20px",
                borderRadius: 2.5,
              }}
              onClick={submit}
            >
              {tv("submit")} {title}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

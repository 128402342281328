import { createSlice } from "@reduxjs/toolkit";

const theme = localStorage.getItem("theme");
const themes = ["dark", "default"];
if (!themes.includes(theme)) {
  localStorage.setItem("theme", "default");
}
const themeSlice = createSlice({
  name: "theme",
  initialState: {
    checked: theme ? theme === "dark" : false,
    theme: theme && themes.includes(theme) ? theme : "default",
  },
  reducers: {
    setTheme(state, action) {
      return action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;

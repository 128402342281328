import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import RouteEnum from "../../navigation/RouteEnum";
import { showLoader } from "../../redux/LoadingSlice";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import usePost from "../usePost";
import usePut from "../usePut";
import useAuth from "./useAuth";
import UseCustomTranslate from "./useCustomTranslate";
const useBooth = () => {
  const fetchPut = usePut();
  const post = usePost();
  const tv = UseCustomTranslate("BoothBuy");
  const { errorHandler } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getBaseUrl = (id) => `${BaseUrl}/admins/booths/${id}`;
  const payBaseUrl = (query) =>
    `${BaseUrl}/admins/payment/request${query
      ? "?&" +
      Object.keys(query)
        .filter((x) => query[x] !== "None")
        .map((key) => `${key}=${query[key]}`)
        .join("&")
      : ""
    }`;
  const buyBooth = async (boothId, gate) => {
    try {
      const res = await fetchPut(getBaseUrl(boothId + "/buy"));
      if (res.status === ResponseStatus.Success) {
        if (res.data.isFree) {
          navigate(`${RouteEnum.MyBooths}/buy?status=OK`)
        } else {
          const res = await post(payBaseUrl({ gate, boothId }));
          if (res.status === ResponseStatus.Success) {
            var link = document.createElement("a");
            link.href = `${res.data.url}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch(
              showLoader({
                visible: true,
                text: tv("Connecting"),
              }),
            );
          } else {
            errorHandler(res);
            return false;
          }
        }
      } else {
        errorHandler(res);
        return false;
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return { buyBooth };
};

export default useBooth;

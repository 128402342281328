import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/AlertsSlice";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import useDelete from "../useDelete";
import useGet from "../useGet";
import usePut from "../usePut";
const useComments = () => {
  const dispatch = useDispatch();
  const fetchGet = useGet();
  const put = usePut();
  const del = useDelete();
  const authUrl = (query) =>
    `${BaseUrl}/admins/comments${
      query
        ? "?&" +
          Object.keys(query)
            .filter((x) => query[x] !== "None")
            .map((key) => `${key}=${query[key]}`)
            .join("&")
        : ""
    }`;
  const getComments = async (props) => {
    try {
      let res = await fetchGet(authUrl(props));
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const approve = async (ids) => {
    try {
      let res = await put(authUrl() + "/approve", { commentsIds: ids });
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `comments approved`, type: "success" },
          }),
        );
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const deleteComments = async (ids) => {
    try {
      let res = await del(authUrl(), { commentsIds: ids });
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `comments deleted`, type: "success" },
          }),
        );
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const reply = async ({ id, replyText }) => {
    try {
      let res = await put(authUrl() + "/" + id + "/reply", { replyText });
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `comment replyed`, type: "success" },
          }),
        );
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  return { getComments, approve, reply, deleteComments };
};

export default useComments;

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import BoxUi from "../../../../components/BaseElement/BoxUi/BoxUi";
import PerfectScrollbarUi from "../../../../components/BaseElement/PerfectScrollbarUi/PerfectScrollbarUi";
import SearchUi from "../../../../components/BaseElement/searchUi/searchUi";
import useChat from "../../../../customHooks/hookRepositories/useChat";
import RouteEnum from "../../../../navigation/RouteEnum";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import ChatBody from "./components/ChatBody/ChatBody";
import ChatCard from "./components/ChatCard/ChatCard";
import useStyles from "./styles";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function Chat() {
	const tvPages = useCustomTranslate('pages')
	const tvCrumb = useCustomTranslate('breadcrumb')
	const classes = useStyles();
	const dispatch = useDispatch();
	const { exhibitionId, salonId, id } = useParams();
	const { getAllChats, getChatroom } = useChat()
	const { chatList: { rooms, exhibition, booth }, messages } = useSelector(s => s.chat);
	const { type } = useSelector(s => s.roles);
	useEffect(() => {
		let breadcrumbs = [
			{ name: tvCrumb('home'), href: "/" },
			{ name: tvPages('exhibitions'), href: RouteEnum.Exhibitions },
			{ name: tvPages('salons'), href: RouteEnum.Salons(exhibitionId) },
			{ name: tvPages('booths'), href: RouteEnum.Booths(exhibitionId, salonId) },
			{ name: tvPages('chat') },
		];
		switch (type) {
			case "organizers":
				breadcrumbs = [
					{ name: tvCrumb('home'), href: "/" },
					{ name: tvPages('salons'), href: RouteEnum.Exhibition },
					{ name: tvPages('booths'), href: RouteEnum.Booths(exhibitionId, salonId) },
					{ name: tvPages('chat') },
				]
				break;
			case "exhibitors":
				breadcrumbs = [
					{ name: tvCrumb('home'), href: "/" },
					{ name: tvPages('myBooth'), href: RouteEnum.MyBooths },
					{ name: tvPages('chat') },
				]
				break;
			default:
				break;
		}

		dispatch(
			setToolbar({
				id: "Exhibitions",
				title: { name: tvPages('chats') },
				breadcrumbs
			}),
		);
		// eslint-disable-next-line
	}, [type, tvCrumb, dispatch, exhibitionId, salonId]);
	useEffect(() => {
		const getChats = async () => {
			await getAllChats(id);
		}
		getChats()
		// eslint-disable-next-line
	}, []);
	const [showChatBody, setShowChatBody] = useState(false);
	const handelGetChatroom = async (id) => {
		if (!messages || id !== messages?._id) {
			const bool = await getChatroom(id);
			setShowChatBody(bool);
		}
	}
	const classHandler = (name) => {
		if (showChatBody) {
			return classes[`${showChatBody ? "active" : ""}${name}`]
		}
	}
	const [search, setSearch] = useState("");
	const searchHandler = (value) => {
		setSearch(value)
	}
	return (
		<div className={classes.chatWrapper}>
			<BoxUi className={[classes.chatSidebar, classHandler('SideBar')].join(' ')}>
				<Box className={classes.section}>
					<div className={classes.breadcrumbs}>
						<div>{exhibition?.name}</div>
						{">"}
						<div>{booth?.name}</div>
					</div>
				</Box>
				<Box className={classes.section}>
					<SearchUi className={classes.search} onChange={searchHandler} />
				</Box>
				<Box
					sx={{ display: "flex", flexGrow: 1, height: "calc(100vh - 258px)" }}
				>
					<PerfectScrollbarUi style={{ flexGrow: 1 }}>
						{rooms ? rooms.filter(({ creator }) => {
							return (search ? creator?.fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1 : true)
						}).map((x) => (
							<ChatCard {...x} key={x._id} onClick={() => handelGetChatroom(x._id)} />
						)) : <div></div>}
					</PerfectScrollbarUi>
				</Box>
			</BoxUi>
			<Box className={[classes.chatBody, classHandler('ChatBody')].join(' ')} sx={{ flex: 2 }}>
				{messages && <ChatBody {...messages} showChatBody={showChatBody} onClose={() => {
					setShowChatBody(false)
				}} />}
			</Box>
		</div>
	);
}
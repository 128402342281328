import React from "react";
import useStyles from "./styles";
import {Button} from "@mui/material";
import {findFlagUrlByCountryName} from "country-flags-svg";
import RouteEnum from "../../../../../../navigation/RouteEnum";
import {Link} from "react-router-dom";
import useLanguage from "../../../../../../customHooks/hookRepositories/useLanguage";
import DeleteModul from "../../../../../../components/Modals/DeleteModul/DeleteModul";
import {useTheme} from "@emotion/react";
import useCustomTranslate from "../../../../../../customHooks/hookRepositories/useCustomTranslate";

export default function LanguageCard({flag, title, _id, isActive}) {
    const classes = useStyles();
    const {deleteLanguage, updateStatus} = useLanguage();
    const {deleteModul} = DeleteModul();
    const deleteHandler = async () => {
        deleteModul(() => {
            deleteLanguage(_id);
        });
    };
    const statusHandler = () => {
        updateStatus(_id);
    };
    const tv = useCustomTranslate('pages');
    const theme = useTheme();
    return (
        <div className={classes.cardWrapper}>
            <div className={classes.logoWrapper}>
                <div className={classes.logo}>
                    <img
                        style={!isActive ? {filter: " grayscale(100%)"} : {}}
                        src={findFlagUrlByCountryName(flag.toLowerCase())}
                        alt=""
                    />
                </div>
            </div>
            <div
                className={classes.name}
                style={!isActive ? {color: theme.palette.description.main} : {}}
            >
                {title}
            </div>
            <div className={classes.btnWrapper}>
                <Link
                    to={RouteEnum.Edit(_id)}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Button variant="contained" color="primary">
                        {tv('edit')}
                    </Button>
                </Link>
                <Button
                    color={isActive ? "description" : "primary"}
                    onClick={statusHandler}
                >
                    {isActive ? tv('deactivate') : tv('activate')}
                </Button>
                <Button color="error" onClick={deleteHandler}>
                    {tv('delete')}
                </Button>
            </div>
        </div>
    );
}

import React from "react";
import useStyles from "./styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import Icons from "../../../assets/icons/Icons";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      >
      <Paper {...props} />
    </Draggable>
  );
}
export default function Modal({ title, children, open, onClose, DialogProps }) {
  const classes = useStyles();
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        // aria-labelledby="draggable-dialog-title"
        // classes={{ 
        //   container:classes.paper
        // }}
        {...DialogProps}
      >
        <DialogTitle
          sx={{
            fontWeight: "bolder",
            // cursor: "move",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          // id="draggable-dialog-title"
        >
          {title ? title : <div></div>}
          <Icons.Close onClick={onClose} style={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent className={classes.paper} >{children}</DialogContent>
      </Dialog>
    </>
  );
}

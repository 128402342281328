import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    height: "100vh",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.main,
  },
  titleWrapper: {
    fontSize: 200,
    [theme.breakpoints.down("lg")]: {
      fontSize: 100,
    },
  },
}));

import React, { useEffect } from "react";
import useStyles from "./styles";
import galleryAdd from "../../../../assets/images/gallery-add.png";
import playAdd from "../../../../assets/images/play-add.png";
import musicSquareAdd from "../../../../assets/images/music-square-add.png";
import folderAdd from "../../../../assets/images/folder-add.png";
import { UploadFileType } from "../../../../configs/FormEnum";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../redux/ModalSlice";
import { MainUrl } from "../../../../services/Api";
import { setFile } from "../../../../redux/SelectedFileSlice";
import docImg from "../../../../assets/icons/folder-open.svg";
import musicImg from "../../../../assets/icons/music.svg";
import Icons from "../../../../assets/icons/Icons";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export default function AddUploadUi({
  fileType,
  fullWidth,
  callBack,
  url,
  ratio,
  id,
  description: propDescription,
  title,
  height: propHeight,
  width: propWidth,
  className,
}) {
  // let style;
  const dispatch = useDispatch(),
    tv = useCustomTranslate("forms"),
    etv = useCustomTranslate("error"),
    { t } = useTranslation(),
    [error, setError] = useState(""),
    isRtl = t("dir") === "rtl",
    classes = useStyles();

  let image = galleryAdd,
    description = "",
    height = propHeight ? propHeight : 135,
    width = propWidth ? propWidth : 240,
    preview = () => {
      switch (fileType) {
        case UploadFileType.image: {
          if (ratio) {
            var image = new Image();
            image.src = MainUrl + url;

            image.onload = function () {
              const width = image.width,
                height = image.height,
                imageRatio = width / height;
              if (!(imageRatio >= ratio - 0.05 && imageRatio <= ratio + 0.05)) {
                setError(true);
              } else {
                setError(false);
              }
            };
          }
          return (
            <div
              style={{ backgroundImage: `url(${MainUrl + url})` }}
              className={classes.containerBackGround}
            ></div>
          );
        }
        case UploadFileType.video: {
          return (
            <Box width={width} height={height} sx={{ position: "relative" }}>
              <video
                width={width}
                height={height}
                src={`${MainUrl + url}`}
                className={classes.containerBackGround}
              ></video>
              <div className={classes.playCircle}>
                <Icons.PlayCircle />
              </div>
            </Box>
          );
        }
        case UploadFileType.audio: {
          return (
            <Box
              width={width}
              height={height}
              sx={{ position: "relative" }}
              style={{ backgroundImage: `url(${musicImg})` }}
              className={classes.containerBackGround}
            />
          );
        }
        case UploadFileType.file: {
          return (
            <div
              style={{ backgroundImage: `url(${docImg})` }}
              className={classes.containerBackGround}
            ></div>
          );
        }
        default:
          break;
      }
    };
  switch (fileType) {
    case UploadFileType.image:
      image = galleryAdd;
      description = `( ${tv("bestSize")} 720 * ${ratio ? 720 / ratio : 1920} )`;
      // description = `( ${tv("bestSize")} 720 * ${ratio ? 720 / ratio : 1920}px )`;
      height = 240;
      width = 135;
      break;
    case UploadFileType.video:
      image = playAdd;
      description = `( ${tv("bestRatio")} 16:9 )`;
      break;
    case UploadFileType.audio:
      image = musicSquareAdd;
      description = `( ${tv("bestQuality")} 192 kbps )`;
      break;
    case UploadFileType.file:
      image = folderAdd;
      height = 100;
      description = ` `;
      width = 200;
      break;
    default:
      break;
  }
  if (propDescription) {
    description = propDescription;
  }

  if (propHeight) {
    height = propHeight;
  }
  if (propWidth) {
    width = propWidth;
  }
  const openUploadModal = () => {
    dispatch(showModal({ open: "FilesModal", type: fileType, id }));
  };

  const deleteFile = () => {
    callBack("");
  };
  const { id: fileId, url: fileUrl } = useSelector((s) => s.file);
  useEffect(() => {
    if (id && fileId === id) {
      callBack(fileUrl);
      dispatch(setFile({}));
    }
    //eslint-disable-next-line
  }, [fileUrl, fileId, id]);
  const boxSize = !fullWidth
    ? { height, width }
    : {
      height: "100%",
    };
  return (
    <>
      {url?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            ...boxSize,
          }}
          className={className}
        >
          <Box
            className={`${classes.areaWithImage}${error ? " " + classes.error : ""
              }`}
            sx={boxSize}
          >
            <a style={{ width: "100%", height: "100%" }} download target="_blank" rel="noreferrer" href={MainUrl + url}>

              {preview()}
            </a>
            <div
              className={`${classes.fileTools} ${isRtl ? classes.rtlFileTools : ""}`}
            >
              <div
                className={`${classes.deleteFile} ${isRtl ? classes.rtlDeleteFile : ""
                  }`}
                onClick={deleteFile}
              ></div>
            </div>
          </Box>
          {error && (
            <div className={classes.errorText}>{etv("imageRatio")}</div>
          )}
        </Box>
      ) : (
        <Box
          className={`${classes.area}${className ? " " + className : ""}`}
          sx={!fullWidth ? { height, width } : {}}
          onClick={openUploadModal}
        >
          <div
            className={[
              classes.imageWrapper,
              (height === 135 || height === 100) && classes.imageWrapperRow,
            ].join(" ")}
          >
            <img alt="" src={image} className={classes.areaLogo} />
            <p className={classes.areaTitle}>
              {title ? title : tv("dragFile")}
            </p>
          </div>
          {description && (
            <div>
              <p className={classes.areaPlaceholder}>{description}</p>
            </div>
          )}
        </Box>
      )}
    </>
  );
}

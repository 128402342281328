import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useStyles from "./styles";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

export default function DeleteModul() {
  const classes = useStyles();
  const MySwal = withReactContent(Swal);
  const tv = useCustomTranslate('modal')
  const deleteModul = (onConfirm) => {
    MySwal.fire({
      title: tv('deleteTitle'),
      text: tv('deleteText'),
      icon: "warning",
      cancelButtonText:tv('deleteCancel'),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: tv('deleteConfirm'),
      customClass: {
        container: classes.swal,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      }
    });
  };
  return { deleteModul };
}

import BoxUi from "../../components/BaseElement/BoxUi";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import InputUi from "../../components/BaseElement/InputUi/InputUi";
import ButtonUi from "../../components/BaseElement/ButtonUi/ButtonUi";
import LoginDescription from "../../components/LoginDescription";
import useAuth from "../../customHooks/hookRepositories/useAuth";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePassword";
import Icons from "../../assets/icons/Icons";
import UseCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";

export default function ForgetPassword(props) {
  var classes = useStyles();
  const { validation } = useSelector((state) => state.alerts);
  var [loginValue, setLoginValue] = useState("");
  var [params, setParams] = useState({});
  const [isSend, setIsSend] = useState(false);
  const { forgetPassword, isValid } = useAuth();
  const [searchParams] = useSearchParams();
  const tv = UseCustomTranslate("forgetPassword");
  useEffect(() => {
    setParams({
      code: searchParams.get("code"),
      email: searchParams.get("email"),
      phoneNumber: searchParams.get("phoneNumber"),
    });
    // eslint-disable-next-line
  }, []);

  const recoverHandler = async () => {
    // if (+loginValue) {
    //   if (isValid({ phoneNumber: loginValue })) {
    //     await forgetPassword({
    //       type: "phoneNumber",
    //       body: {
    //         phoneNumber: loginValue,
    //       },
    //     }).then((e) => {
    //       if (e) {
    //         setIsSend(true);
    //       }
    //     });
    //   }
    // } else {
    if (isValid({ email: loginValue })) {
      await forgetPassword({
        type: "email",
        body: {
          email: loginValue,
        },
      }).then((e) => {
        if (e) {
          setIsSend(true);
        }
      });
    }
    // }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      recoverHandler();
    }
  };
  const error =
    validation && validation?.phoneNumber
      ? validation?.phoneNumber
      : validation?.email;
  return (
    <BoxUi login>
      {params.code ? (
        <ChangePassword {...params} tv={tv} />
      ) : isSend ? (
        <LoginDescription
          title={tv("checkMail")}
          description={tv("checkMailDescription")}
        />
      ) : (
        <React.Fragment>
          <LoginDescription
            title={tv("forgetYourPassword")}
            description={tv("forgetPasswordDescription")}
          />
          <InputUi
            id="Username"
            value={loginValue}
            onChange={(e) => setLoginValue(e.target.value)}
            placeholder={tv("email")}
            type="email"
            error={!!error}
            helperText={error}
            icon={<Icons.User />}
            inputProps={{
              onKeyPress: handleKeyPress,
            }}
          />
          <ButtonUi disabled={loginValue.length === 0} onClick={recoverHandler}>
            <Icons.PasswordCheck className={classes.CheckIcon} />{" "}
            {tv("recoverPassword")}
          </ButtonUi>
        </React.Fragment>
      )}
    </BoxUi>
  );
}

const Limits = {
  tabels: {
    user: { main: 10 },
    exhibitions: {
      exhibitions: 8,
      salons: 6,
      booths: 8,
    },
    files: {
      main: 9,
    },
    comments: {
      main: 5,
    },
    events: 7,
    analysis: 10,
  },
  characters: {
    exhibitions: 200,
    booths: 200,
  },
};
export default Limits;

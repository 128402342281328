import { createSlice } from "@reduxjs/toolkit";
import { sendNotification } from "../customHooks/hookRepositories/useChat";
import { MainUrl } from "../services/Api";

const chatSlice = createSlice({
  name: "chat",
  initialState: { chatList: [], messages: undefined },
  reducers: {
    setChat(state, action) {
      return { ...state, ...action.payload };
    },
    setNewMessage(state, action) {
      const { chatList, messages } = state;
      const newMLastM = action.payload.data;
      if (newMLastM.user && messages?._id !== newMLastM.chatId) {
        const { fullName, avatar } = newMLastM.user;
        sendNotification({
          title: fullName,
          message: newMLastM.message,
          icon: avatar ? `${MainUrl}${avatar}` : "/assets/image/no_photo.png",
        //   clickCallback: async () => {
        //     try {
        //       let res = await fetchGet( `${BaseUrl}/admins/booth/${id}`);
        //       if (res.status === ResponseStatus.Success) {
        //         return res.data;
        //       } else {
        //       }
        //     } catch (e) {
        //       console.log("error", e);
        //     }
        //   },
        });
      }
      if (newMLastM.boothId === chatList?.booth?._id) {
        let newM = chatList.rooms.filter((x) => x._id === newMLastM.chatId)[0];
        if (newM) {
          newM.adminUnreadMessage = messages?._id !== newM._id;
          newM.lastMessage = {
            ...newM.lastMessage,
            body: newMLastM.message,
            createdAt: newMLastM.createdAt,
            admin: newMLastM.admin,
            user: newMLastM.user,
          };
        } else {
          newM = {
            lastMessage: {
              body: newMLastM.message,
              createdAt: newMLastM.createdAt,
              admin: newMLastM.admin,
              user: newMLastM.user,
            },
            type: "private",
            adminUnreadMessage: true,
            userUnreadMessage: false,
            creator: newMLastM.user,
            createdAt: newMLastM.createdAt,
            _id: newMLastM.chatId,
          };
        }

        const newChatList = {
          ...chatList,
          rooms: [newM, ...chatList.rooms.filter((x) => x._id !== newM._id)],
        };
        let newMessage = messages;
        if (messages && messages._id === newM._id) {
          newMessage = {
            ...messages,
            messages: [newM.lastMessage, ...messages.messages],
          };
        }
        return void ((state.chatList = newChatList),
        (state.messages = newMessage));
      }
    },
  },
});

export const { setChat, setNewMessage } = chatSlice.actions;
export default chatSlice.reducer;

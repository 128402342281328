import { Box } from "@mui/material";
import React from "react";
import EventsUi from "../../../../components/BaseElement/EventsUi/EventsUi";

export default function EventsView({ events }) {
  // var classes = useStyles();
  return (
    <Box sx={{ display: "flex", gap: 1.5, flexGrow: 1, flexWrap: "wrap" }}>
      {events && events.map((item, i) => <EventsUi {...item} key={i} />)}
    </Box>
  );
}

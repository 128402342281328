import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { showAlert } from "../../redux/AlertsSlice";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import useDelete from "../useDelete";
import useGet from "../useGet";
import usePost from "../usePost";
import usePut from "../usePut";
import useAuth from "./useAuth";
import useGetConstant from "./useGetConstant";
const useLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchGet = useGet();
  const post = usePost();
  const del = useDelete();
  const put = usePut();
  const getOneUrl = (locale) => `${BaseUrl}/admins/languages/${locale}`;
  const { errorHandler } = useAuth();
  const getLocaleLanguage = async (props) => {
    console.log("getLocaleLanguage");
    const res = await axios
      .get("https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708")
      .then((res) => {
        return res.data.country_code;
      })
      .catch((err) => {
        return false;
      });
    return res;
  };
  const getLanguage = async (props) => {
    const { locale } = props;
    try {
      let res = await fetchGet(getOneUrl(locale));
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const addUrl = `${BaseUrl}/admins/languages`;
  const addLanguage = async (props) => {
    const { body } = props;
    try {
      let res = await post(addUrl, body);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `language added`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const updateLanguage = async (props) => {
    const { body, id } = props;
    try {
      let res = await put(addUrl + "/" + id, body);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `language edited`, type: "success" },
          }),
        );
        navigate("../");
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const { getLanguages } = useGetConstant();
  const deleteLanguage = async (id) => {
    try {
      let res = await del(addUrl + "/" + id);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `language deleted`, type: "success" },
          }),
        );
        getLanguages();
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const updateStatus = async (id) => {
    try {
      let res = await put(addUrl + "/" + id + "/status");
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: { message: `language status chenched`, type: "success" },
          }),
        );
        getLanguages();
      } else {
        errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return {
    getLanguage,
    addLanguage,
    updateLanguage,
    deleteLanguage,
    updateStatus,
    getLocaleLanguage,
  };
};

export default useLanguage;

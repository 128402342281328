import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useExhibitions from "../../../../customHooks/hookRepositories/useExhibitions";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import RouteEnum from "../../../../navigation/RouteEnum";
import BoothCard from "../BoothCard";
import FiltersEnum from "../../../../configs/FiltersEnum";
import ListLayout from "../../../../components/Layouts/ListLayout/ListLayout";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function BoothsList() {
  const { exhibitionId, salonId } = useParams();
  const { type } = useSelector((s) => s.roles);
  const dispatch = useDispatch();
  const { getAll } = useExhibitions();
  const tv = useCustomTranslate("forms");
  const tvPages = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const linkName = "booths";
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Exhibitions",
        title: { name: tvPages("booths") },
        leftElements: [
          { type: "AdvanceSearch" },
          {
            type: "ButtonUi",
            name: `${tv("new")} ${tvPages("booth")}`,
            Link: `${RouteEnum.Booths(exhibitionId, salonId)}/add`,
          },
        ],
        rightElements: [
          {
            type: "Excel",
            name: `exhibitions/${exhibitionId}/salons/${salonId}/booths`,
          },
        ],
        breadcrumbs:
          type === "organizers"
            ? [
                { name: tvCrumb("home"), href: "/" },
                {
                  name: tvPages("salons"),
                  href: RouteEnum.Exhibition,
                },
                { name: tvPages("booths") },
              ]
            : [
                { name: tvCrumb("home"), href: "/" },
                { name: tvPages("exhibitions"), href: RouteEnum.Exhibitions },
                {
                  name: tvPages("salons"),
                  href: RouteEnum.Salons(exhibitionId),
                },
                { name: tvPages("booths") },
              ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, exhibitionId, salonId, tv]);
  useEffect(() => {
    getAll({ name: linkName, exhibitionId, salonId });
    // eslint-disable-next-line
  }, [exhibitionId, salonId]);
  const {
    booths: { itemsList, paginator: { currentPage, pageCount } = {} },
  } = useSelector((s) => s.exhibitions);
  const [tabelData, setTabelData] = useState([]);
  useEffect(() => {
    if (itemsList) {
      const tabelData = itemsList.map(
        ({
          _id,
          logo,
          name,
          userViewedCount,
          isActive,
          description,
          boothDesign,
          adminUnreadMessage,
        }) => {
          return {
            id: _id,
            adminUnreadMessage,
            logo,
            userViewedCount,
            isActive,
            description,
            boothName: name,
            boothDesign: boothDesign?.index,
          };
        },
      );
      setTabelData(tabelData);
    }
  }, [itemsList]);
  const [query, setQuery] = useState({
    is_active: "None",
  });
  const onQueryCheng = (e, type) => {
    const newQ = { ...query };
    newQ[type] = e.target ? e.target.value : e;
    setQuery({ ...newQ });
    getAll({ name: linkName, exhibitionId, salonId, query: newQ });
  };
  const TabelHeader = {
    sort: {
      type: "sort",
      children: FiltersEnum.Sort,
    },
    filter: [
      {
        type: "is_active",
        children: FiltersEnum.Active,
      },
    ],
  };

  const onPageChange = (e, page) => {
    getAll({
      name: linkName,
      exhibitionId,
      salonId,
      query: { ...query, page },
    });
  };
  return (
    <ListLayout
      pagination={{
        count: pageCount,
        page: currentPage ? currentPage : 1,
        onChange: onPageChange,
      }}
      TabelHeader={{
        ...TabelHeader,
        onChange: onQueryCheng,
        value: query,
      }}
      hasData={tabelData?.length > 0}
    >
      {/* <Grid item xs={3} sx={{ display: "flex" }}>
            <div className={classes.cardWrapper}>
              <Link className={classes.addIconWrapper} to="add">
                <Icons.Add className={classes.addIcon} />
                <div className={classes.addTitle}>Add New Booth</div>
              </Link>
            </div>
          </Grid> */}
      {tabelData.map((x) => (
        <Grid key={x.id} item lg={3} sm={6} xs={12} sx={{ display: "flex" }}>
          <BoothCard {...x} query={query} />
        </Grid>
      ))}
    </ListLayout>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UseCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import { showModal } from "../../../redux/ModalSlice";
import Modal from "../../BaseElement/Modal/Modal";
import useStyles from "./styles";

export default function TipsModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.modal);
  const handleClose = () => {
    dispatch(showModal({ open: false }));
  };
  const tv = UseCustomTranslate("tips");
  return (
    <Modal open={open === "TipsModal"} onClose={handleClose}>
      <div className={classes.wrapper}>
        <h3>{tv("tipsTitle")}</h3>
        {tv("tipsDescription")}
      </div>
    </Modal>
  );
}

import { MenuItem, Select } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import SearchUi from "../searchUi/searchUi";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

export default function TabelHeaderUi({
  sort,
  filter,
  value,
  onChange,
  type,
  modal = false,
  component,
}) {
  const classes = useStyles();
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  return (
    <>
      <div className={[classes.search, classes.searchRes].join(" ")}>
        <SearchUi
          className={modal ? classes.modalSearch : ""}
          onChange={(v) => onChange(v, "q")}
          value={value?.q}
        />
      </div>
      <div className={classes.TabelHeaderUiWrapper}>
        <div className={classes.leftSide}>
          {sort && (
            <div className={classes.sort}>
              <div className={classes.title}>{tv("sortBy")}:</div>
              <div>
                <Select
                  {...sort}
                  classes={{
                    select: modal ? classes.selectModal : classes.select,
                  }}
                  className={classes.selectWrapper}
                  onChange={(e) => onChange(e.target.value, "sort")}
                  children={undefined}
                  value={value[sort.type] ? value[sort.type] : "date-desc"}
                >
                  {sort.children.map(({ name, value }) => (
                    <MenuItem value={value} key={value}>
                      {tvCrumb(value)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          )}
          {filter && (
            <div className={classes.filter}>
              <div className={classes.title}>{tv("filterBy")}:</div>
              {filter.map((x, i) => (
                <div key={i}>
                  <Select
                    {...x}
                    api={undefined}
                    classes={{
                      select: modal ? classes.selectModal : classes.select,
                      outlined: classes.selectOutlined,
                    }}
                    className={classes.selectWrapper}
                    onChange={(e) => onChange(e.target.value, x.type)}
                    value={value[x.type] !== undefined ? value[x.type] : "None"}
                  >
                    {x.children.map(({ value, name }) => (
                      <MenuItem value={value} key={value}>
                        {x.api && value !== "None" ? name : tv(name)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ))}
            </div>
          )}
          {type && (
            <div className={classes.filter}>
              <div className={classes.title}>{tv("type")}:</div>
              {type.map((x, i) => (
                <div key={i}>
                  <Select
                    {...x}
                    classes={{
                      select: modal ? classes.selectModal : classes.select,
                      outlined: classes.selectOutlined,
                    }}
                    className={classes.selectWrapper}
                    onChange={(e) => onChange(e.target.value, x.type)}
                    value={value[x.type] !== undefined ? value[x.type] : "None"}
                  >
                    {x.children.map(({ value, name }) => (
                      <MenuItem value={value} key={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ))}
            </div>
          )}
          {component}
        </div>
        <div className={[classes.search, classes.searchDesk].join(" ")}>
          <SearchUi
            className={modal ? classes.modalSearch : ""}
            onChange={(v) => onChange(v, "q")}
            value={value?.q}
          />
        </div>
      </div>
    </>
  );
}

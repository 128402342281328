import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  button: {
    borderRadius: "30px !important",
    color: `${theme.palette.buttonColor.main} !important`,
    padding: "11.75px 22px !important",
    fontSize: "16px !important",
    margin: `${theme.spacing(3)} 0px !important`,
  },
}));

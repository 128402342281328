import React, { useEffect, useState } from "react";
import AddForm from "../../../../components/AddForm/AddForm";
import { useDispatch, useSelector } from "react-redux";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";
import useSettings from "../../../../customHooks/hookRepositories/useSettings";

export default function ExhibitorSettings(props) {
  const [formData, setFormData] = useState({});
  const { updateSettings } = useSettings();
  const dispatch = useDispatch();
  const tv = useCustomTranslate("pages");
  const tvForms = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");

  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Settings",
        title: { name: tv("settings") },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tv("settings") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  const { info } = useSelector((s) => s.auth);
  useEffect(() => {
    if (info) {
      const {
        email,
        phoneNumber,
        name,
        family,
        extraInfo: {
          telephoneNumber,
          address,
          companyActivity,
          companyName,
          postalCode,
          websiteUrl,
        } = {},
      } = info;
      setFormData({
        email,
        phoneNumber,
        name,
        family,
        telephoneNumber,
        address,
        companyActivity,
        companyName,
        postalCode,
        websiteUrl,
      });
    }
  }, [info]);
  const inputHandler = (value, type) => {
    const newValue = { ...formData };
    newValue[type] = value;
    setFormData(newValue);
  };
  const { isValid, getValidUrl } = useAuth();
  const submitHandler = (error) => {
    if (
      !isValid({
        email: formData.email,
        password: formData.newPassword && formData.newPassword,
        confirmPassword: formData.confirmPassword && formData.confirmPassword,
      }) ||
      error
    ) {
      return;
    }
    updateSettings("exhibitors", {
      ...formData,
      websiteUrl: getValidUrl(formData.websiteUrl),
    });
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "name",
            title: tvForms("name"),
            required: true,
            input: {
              placeholder: tvForms("namePlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "family",
            title: tvForms("lastName"),
            required: true,
            input: {
              placeholder: tvForms("lastNamePlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "email",
            title: tvForms("email"),
            required: true,
            input: {
              placeholder: tvForms("emailPlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "telephoneNumber",
            title: tvForms("phoneNumber"),
            required: true,
            input: {
              dir: "ltr",
              type: "tel",
              placeholder: tvForms("phoneNumberPlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "phoneNumber",
            title: tvForms("mobileNumber"),
            required: true,
            input: {
              dir: "ltr",
              type: "tel",
              placeholder: tvForms("mobileNumberPlaceholder"),
              autoComplete: "no",
            },
          },
          { width: [0, 6] },
          {
            width: [2, 10],
            valueId: "address",
            title: tvForms("address"),
            fullWidth: true,
            required: true,
            input: {
              placeholder: tvForms("addressPlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "postalCode",
            title: tvForms("zipCode"),
            required: true,
            input: {
              placeholder: tvForms("zipCodePlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "websiteUrl",
            title: tvForms("website"),
            input: {
              placeholder: tvForms("websitePlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "companyName",
            title: tvForms("company"),
            required: true,
            input: {
              placeholder: tvForms("companyPlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [0, 6],
          },
          {
            width: [2, 10],
            valueId: "companyActivity",
            title: tvForms("activityDesc"),
            fullWidth: true,
            input: {
              placeholder: tvForms("activityDescPlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "oldPassword",
            title: tvForms("oldPassword"),
            input: {
              type: "password",
              autoComplete: "no",
              placeholder: tvForms("oldPasswordPlaceholder"),
            },
          },
          {
            width: [0, 6],
          },
          {
            width: [2, 4],
            valueId: "newPassword",
            title: tvForms("password"),
            required: !!formData.oldPassword,
            input: {
              type: "password",
              placeholder: tvForms("passwordPlaceholder"),
              autoComplete: "no",
            },
          },
          {
            width: [2, 4],
            valueId: "confirmPassword",
            title: tvForms("confirmPassword"),
            required: !!formData.oldPassword,
            input: {
              type: "password",
              autoComplete: "no",
              placeholder: tvForms("confirmPasswordPlaceholder"),
            },
          },
        ],
      },
    },
  ];

  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title={tv("settings")}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
      />
    </div>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  modalWrapper: {
    display: "flex",
    flexGrow: 1,
    "&::before": {
      content: "''",
      borderRight: `2px dashed ${theme.palette.background.main}`,
      height: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      "&::before": {
        display: "none",
      },
    },
  },
  descriptionWrapper: {
    display: "flex",
    flex: 1,
    padding: 35,
    borderRight: `2px dashed ${theme.palette.background.main}`,
    flexDirection: "column",
    gap: 20,
  },
  descriptionWrapperRtl: {
    borderLeft: `2px dashed ${theme.palette.background.main}`,
    borderRight: 0,
  },
  payWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "20px 10px",
  },
  close: {
    cursor: "pointer",

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    width: 290,
    alignItems: "center",
    overflow: "hidden",
    height: 180,
    justifyContent: "center",
    "&>img": {
      width: "100%",
      height: "auto",
    },
  },
  description: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  des: {
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 0px",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.background.main}`,
    },
  },
  desTitle: {
    color: theme.palette.MessagesBoothBtnColor.contrastText,
  },
  desValue: {
    fontWeight: 600,
    color: theme.palette.pageTitle.main,
  },
  nameWrapper: {
    paddingTop: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {
    fontSize: 15,
  },
  nameValue: {
    color: theme.palette.pageTitle.main,
    fontSize: 18,
    fontWeight: 700,
  },
  priceWrapper: {
    alignItems: "center",
    padding: "25px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  currencySymbol: {
    fontSize: 20,
  },
  price: {
    fontWeight: 700,
    fontSize: 30,
  },
  paymentWrapper: {
    display: "flex",
    padding: "20px 0",
    gap: 20,
  },
  payment: {
    cursor: "pointer",
    display: "flex",
    border: `2px solid ${theme.palette.headerLine.main}`,
    width: 80,
    height: 40,
    borderRadius: 8,
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    "&[active='true']": {
      border: `1px solid ${theme.palette.primary.main}40`,
      boxShadow: `0px 2px 20px ${theme.palette.primary.main}40`,
      opacity: 1,
    },
    "& > img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  button: {
    paddingTop: 10,
    display: "flex",
    flexGrow: 1,
    "&>a": {
      display: "flex",
      flexGrow: 1,
    },
  },
}));

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.tableBgColor.main,
    flexGrow: 1,
    padding: "0px 0px",
    borderRadius: 20,
    position: "relative",
    maxWidth: 235,
    height: 270,
    overflow: "hidden",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
      height: "auto",
      minHeight: 265,
    },
  },
  resNone: {
    [theme.breakpoints.down("lg")]: {
      display: "none!important",
    },
  },
  addIconWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  addIcon: {
    width: 48,
    height: 48,
    "& > path": {
      stroke: theme.palette.description.main,
    },
  },
  addTitle: {
    color: theme.palette.description.main,
    fontSize: 14,
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    height: "162px",
    "& > img": {
      flexGrow: 1,
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      height: "auto",
    },
  },
  infoContainer: {
    padding: "15px 20px",
  },
  describeWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-between",
  },
  describe: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  describeTitle: {
    fontSize: 14,
    color: theme.palette.description.main,
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "start",
    fontWeight: 600,
    fontSize: 16,
  },
  menu: {
    "& .MuiPaper-rounded": {
      borderRadius: 8,
    },
    "& ul": {
      width: 120,
      paddingTop: 0,
      paddingBottom: 0,
      "& li": {
        height: 30,
        paddingTop: 0,
        paddingBottom: 0,
        color: "#667085",
        gap: 10,
        fontSize: 13,
      },
    },
  },
  edit: {
    "& > svg": {
      width: 16,
      color: "transparent !important",
      height: 16,
      // "& > path": {
      //   stroke: "#000 !important",
      //   fill: "transparent !important",
      // },
    },
  },
  delete: {
    color: "#E31B54!important",
    "& > svg": {
      width: 16,
      height: 16,
    },
  },
  divider: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
}));

import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function RecaptchaUi(props) {
  return (
    <GoogleReCaptchaProvider
      {...props}
      reCaptchaKey={process.env.REACT_APP_SITE_KEY}
    />
  );
}

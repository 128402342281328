import { createSlice } from "@reduxjs/toolkit";

const ExhibitionsSlice = createSlice({
  name: "Exhibitions",
  initialState: {
    exhibitions: {},
    salons: {},
    booths: {},
  },
  reducers: {
    setExhibitions(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setExhibitions } = ExhibitionsSlice.actions;
export default ExhibitionsSlice.reducer;

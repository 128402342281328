import React from "react";
import useStyles from "./styles";
import logo from "../../assets/images/Hitex.png";

export default function Logo({ width, height }) {
  var classes = useStyles({
    width: width ? width : "142px",
    height: height ? height : "72px",
  });
  return (
    <div className={classes.logo}>
      <img alt="" src={logo} />
    </div>
  );
}

import { makeStyles } from "@mui/styles";
import trashSvg from "../../../../assets/icons/trash-light.svg";

export default makeStyles((theme) => ({
  mainContainer: {
    borderBottomColor: theme.palette.background.light,
    borderRadius: "30px !important",
    backgroundColor: theme.palette.inputBackground.main,
  },
  containerTitle: {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "start",
    fontWeight: 600,
    fontSize: 16,
  },
  containerPlaceholder: {
    fontSize: 14,
    color: theme.palette.description.main,
  },
  area: {
    flex: 1,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    padding: 8,
    borderRadius: 20,
    border: "2px dashed rgba(152, 162, 179, 0.4)",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    gap: 25,
  },
  areaWithImage: {
    flex: 1,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    borderRadius: 20,
    background: "#FFFFFF",
    border: "2px solid rgba(152, 162, 179, 0.4)",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    gap: 25,
    position: "relative",
    overflow: "hidden",
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  errorText: {
    position: "absolute",
    bottom: "-40px",
    textAlign: "center",
    color: theme.palette.error.main,
    fontWeight: "bold",
    width: "100%",
    fontSize: "12px",
    padding: "0 10px",
  },
  containerBackGround: {
    display: "flex",
    flexGrow: "1",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    alignItems: "flex-start",
  },
  fileTools: {
    display: "flex",
    flexGrow: "1",
    width: "fit-content",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    top: 0,
    right: 0
  },
  rtlFileTools: {
    right: 'unset',
    left: 0
  },
  deleteFile: {
    width: 40,
    height: 40,
    background: "#E31B54",
    backgroundImage: `url(${trashSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "25px",
    borderRadius: "0px 20px 0px 10px",
    backgroundPosition: "center",
  },
  rtlDeleteFile: {
    borderRadius: "20px 0px 10px 0px",
  },
  areaTitle: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#98A2B3",
  },
  playCircle: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  areaPlaceholder: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#475467",
    margin: 0,
  },
  focusedStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    "& > img": {
      width: 45,
      height: 45,
    },
  },
  imageWrapperRow: {
    flexDirection: "row",
    textAlign: "start",
  },
}));

import React, { useState } from "react";
import useStyles from "../styles";
import IconButton from "@mui/material/IconButton";
import Icons from "../../../assets/icons/Icons";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, Menu, MenuItem } from "@mui/material";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import DeleteModul from "../../../components/Modals/DeleteModul/DeleteModul";
import useDate from "../../../customHooks/hookRepositories/useDate";

export default function EventCard({
  _id,
  name,
  speakerName,
  link,
  dateDescription,
  banner,
  isActive,
  editCallBack,
  onDelete,
}) {
  var classes = useStyles();
  const [open, setOpen] = useState(null);
  const getDate = useDate();
  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const { deleteModul } = DeleteModul();
  const deleteHandler = async () => {
    deleteModul(async () => {
      onDelete && onDelete(_id);
    });
    handleClose();
  };
  const tv = useCustomTranslate("pages");
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.logo}>
        <img src={banner} alt="logo" />
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.name}>{name}</div>
        <div className={classes.describeWrapper}>
          <div className={classes.describe}>
            <div className={classes.describeTitle}>{speakerName}</div>
          </div>
          <div className={classes.describe}>
            <div className={classes.describeTitle}>
              {getDate(dateDescription, "YYYY-MM-DD HH:mm:ss").format(
                "MMMM DD - HH:mm",
              )}
            </div>
            <IconButton
              className={classes.more}
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              className={classes.menu}
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={open}
              open={!!open}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Link to={"edit/" + _id}>
                <MenuItem className={classes.edit}>
                  <Icons.Edit2S />
                  {tv("edit")}
                </MenuItem>
              </Link>
              <Divider className={classes.divider} />
              <MenuItem className={classes.delete} onClick={deleteHandler}>
                <Icons.Trash />
                {tv("delete")}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Button } from "@mui/material";
import React from "react";
import useStyles from "./styles";

export default function ButtonUi(props) {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={[props.className,classes.button].join(" ")}
      variant="contained"
      color="primary"
      fullWidth
    >
      {props.children}
    </Button>
  );
}

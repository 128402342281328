import { InputAdornment, MenuItem, Select } from "@mui/material";
import { changeLanguage } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import useGetConstant from "../../../../customHooks/hookRepositories/useGetConstant";
import { useSelector } from "react-redux";
import useLanguage from "../../../../customHooks/hookRepositories/useLanguage";

export default function LanguageSelect(props) {
  var classes = useStyles();
  const { languages } = useSelector((s) => s.constants);
  const { dir } = useSelector((s) => s.dir);
  const { getLanguages } = useGetConstant();
  const { getLocaleLanguage } = useLanguage();
  useEffect(() => {
    getLanguages();
    // eslint-disable-next-line
  }, []);
  const lunguageHandler = (e) => {
    const value = e.target.value;
    changeLanguage(value);
  };
  const {
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    const getLanguage = async () => {
      if (language) {
        if (language === "en-US") {
          const locale = await getLocaleLanguage();
          if (locale === "IR") {
            changeLanguage("fa");
          } else {
            changeLanguage("en");
          }
        }
      }
    };
    getLanguage();
    // eslint-disable-next-line
  }, [language]);
  return (
    <Select
      value={languages.length ? language : ""}
      onChange={lunguageHandler}
      className={classes.select}
      size="small"
      startAdornment={
        dir === "ltr" && (
          <InputAdornment
            classes={{ root: classes.Adornment }}
            position="start"
          >
            {language.toUpperCase()}
          </InputAdornment>
        )
      }
      endAdornment={
        dir === "rtl" && (
          <InputAdornment
            classes={{ root: classes.Adornment }}
            position="start"
          >
            {language.toUpperCase()}
          </InputAdornment>
        )
      }
    >
      {languages &&
        languages
          .filter((x) => x.isActive)
          .map(({ title, locale: value }) => (
            <MenuItem key={value} value={value}>
              {title}
            </MenuItem>
          ))}
    </Select>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddForm from "../../../../components/AddForm/AddForm";
import { setToolbar } from "../../../../redux/ToolbarSlice";
// import useStyles from "./styles";
import moment from "moment";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";
import useUsers from "../../../../customHooks/hookRepositories/useUsers";
import { useParams } from "react-router";
import useGetConstant from "../../../../customHooks/hookRepositories/useGetConstant";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import RouteEnum from "../../../../navigation/RouteEnum";

export default function EditOrganizer(props) {
  const tv = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");
  // var classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Organizers",
        title: { name: "Edit Organizer" },
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("users") },
          { name: tv("organizer"), href: RouteEnum.user.Organizers },
          { name: tv("edit") + " " + tv("organizer") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  const [formData, setFormData] = useState({
    expireTime: "23:59:00",
    exhibitionId: "None",
  });

  const { getExhibitions } = useGetConstant();
  const [exhibitions, setExhibitions] = useState([]);
  const { id } = useParams();
  const { getOne, updateOne } = useUsers();
  useEffect(() => {
    const setData = async () => {
      await getOne({ type: "Organizers", id }).then(
        ({
          name,
          family,
          email,
          username,
          phoneNumber,
          exhibitionName,
          extraInfo: { websiteUrl, exhibitionId, expireDate },
        }) => {
          getExhibitions().then((data) => {
            setExhibitions([
              { name: exhibitionName, value: exhibitionId },
              ...exhibitions,
              ...data,
            ]);
          });
          setFormData({
            expireTime: "23:59:00",
            name,
            family,
            username,
            email,
            phoneNumber,
            websiteUrl,
            exhibitionId,
            expireDate: moment(expireDate).format("YYYY-MM-DD"),
          });
        },
      );
    };
    setData();
    // eslint-disable-next-line
  }, [id]);

  const inputHandler = (value, type) => {
    const newValue = {};
    newValue[type] = value;
    setFormData({ ...formData, ...newValue });
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "name",
            title: tv("firstName"),
            required: true,
            input: {
              placeholder: tv("firstNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "family",
            title: tv("lastName"),
            required: true,
            input: {
              placeholder: tv("lastNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "username",
            title: tv("username"),
            required: true,
            input: {
              placeholder: tv("usernamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "email",
            title: tv("email"),
            required: true,
            input: {
              placeholder: tv("emailPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "phoneNumber",
            title: tv("phoneNumber"),
            input: {
              dir: "ltr",
              type: "tel",
              placeholder: tv("phoneNumberPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "websiteUrl",
            title: tv("website"),
            input: {
              autoComplete: "do-not-autofill",
              placeholder: tv("websitePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "password",
            title: tv("password"),
            input: {
              type: "password",
              placeholder: tv("passwordPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "confirmPassword",
            title: tv("confirmPassword"),
            input: {
              type: "password",
              placeholder: tv("confirmPasswordPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "exhibitionId",
            title: tv("exhibition"),
            required: true,
            select: {
              children: [
                { name: tv("selectExhibition"), value: "None" },
              ].concat(exhibitions.filter((x) => !x.inUse)),
            },
          },
          {
            width: [2, 4],
            valueId: "expireDate",
            title: tv("expire"),
            required: true,
            datePicker: {
              placeholder: "2022/05/01",
            },
          },
        ],
      },
    },
  ];

  const { isValid, getValidUrl } = useAuth();
  const submitHandler = (error) => {
    if (
      !isValid({
        email: formData.email,
        password: formData.password && formData.password,
        confirmPassword: formData.confirmPassword && formData.confirmPassword,
        date: { value: formData.expireDate, valueId: "expireDate" },
        username: formData.username,
      }) ||
      error
    ) {
      return;
    }
    updateOne({
      type: "Organizers",
      body: { ...formData, websiteUrl: getValidUrl(formData.websiteUrl) },
      id,
    });
  };
  return (
    <div>
      <AddForm
        form={form}
        title={tv("organizer")}
        value={formData}
        onInputChange={inputHandler}
        onSubmit={submitHandler}
      />
    </div>
  );
}

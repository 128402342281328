// import React from "react";
import axios from "axios";
import { /*BaseUrl*/ ResponseStatus } from "../services/Api";
import { useDispatch, useSelector } from "react-redux";
import { setProgressBar } from "../redux/ProgressBarSlice";
import { showLoader } from "../redux/LoadingSlice";
const usePut = () => {
  const { token } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  async function apiPut(
    url,
    body = {},
    jwt = "",
    header = {},
    isFile = false,
    noLoader,
  ) {
    if (!isFile) {
      if (!noLoader) {
        dispatch(
          showLoader({
            visible: true,
            text: "",
          }),
        );
      }
    }
    return new Promise((res) => {
      axios
        .put(url, body, {
          headers: {
            ...header,
            authorization: token,
          },
          timeout: 99999999999999999999,
          onUploadProgress: (progressEvent) =>
            dispatch(
              setProgressBar(
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
              ),
            ),
        })
        .then(function (response) {
          if (!noLoader) {
            dispatch(
              showLoader({
                visible: false,
                text: "",
              }),
            );
          }
          dispatch(setProgressBar(0));
          res(
            Object.assign(
              { status: ResponseStatus.Success },
              { data: response?.data?.data },
            ),
          );
        })
        .catch(function (error) {
          if (!noLoader) {
            dispatch(
              showLoader({
                visible: false,
                text: "",
              }),
            );
          }
          dispatch(setProgressBar(0));
          res(
            Object.assign(
              { status: ResponseStatus.Failed },
              error.response?.data ?? { message: error.message },
            ),
          );
        });
    });
  }

  return apiPut;
};
export default usePut;

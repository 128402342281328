import { createSlice } from "@reduxjs/toolkit";

const toolbarSlice = createSlice({
  name: "toolbar",
  initialState: {
    id: "",
    title: "",
    leftElements: [],
    rightElements: [],
  },
  reducers: {
    setToolbar(state, action) {
      return action.payload ;
    },
  },
});

export const { setToolbar } = toolbarSlice.actions;
export default toolbarSlice.reducer;

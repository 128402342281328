import React from "react";
import useStyles from "./styles";
import HeaderBtn from "../HeaderBtn";
import { ReactComponent as Excel } from "./Excel.svg";
import useUsers from "../../../../customHooks/hookRepositories/useUsers";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function ExcelBtn({ name }) {
  const tv = useCustomTranslate("header");
  var classes = useStyles();
  const { getExcel } = useUsers();
  const excelHandler = () => {
    getExcel({ name: name.toLowerCase() });
  };
  return (
    <HeaderBtn icon={<Excel />} onClick={excelHandler}>
      <div className={classes.exportText}>{tv("excelExport")}</div>
    </HeaderBtn>
  );
}

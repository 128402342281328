import { Pagination } from "@mui/material";

import React from "react";
import useStyles from "./styles";
import {useSelector} from "react-redux";

export default function PaginationUi(props) {
  var classes = useStyles();
  const { dir } = useSelector((s) => s);
  const rtlClass = dir.dir === 'rtl' ? classes.rtl : ''
  return (
    <Pagination
      {...props}
      shape="rounded"
      className={[classes.pagination,rtlClass].join(' ')}
      classes={{ root: classes.paginationRoot }}
      color="primary"
    />
  );
}

import {Grid} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import PaginationUi from "../../BaseElement/PaginationUi/PaginationUi";
import TabelHeaderUi from "../../BaseElement/TabelHeaderUi/TabelHeaderUi";
import useStyles from "./styles";

export default function ListLayout(props) {
    const {children, pagination, TabelHeader, hasData} = props;
    const classes = useStyles();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
            }}
        >
            {TabelHeader && (
                <div
                    className={[
                        classes.headerWrapper,
                        TabelHeader.modal && classes.TabelHeaderUiModal,
                    ].join(" ")}
                >
                    <TabelHeaderUi {...TabelHeader} />
                </div>
            )}
            <Box
                sx={{
                    height: props.height,
                }}
                className={classes.listWrapper}
                flexDirection={hasData ? "column" : "row"}
            >
                <Grid container spacing={2}>
                    {hasData ? children : <div className={classes.noData}>No data</div>}
                </Grid>
            </Box>
            {pagination?.count > 1 && (
                <div
                    className={[
                        classes.paginationWrapper,
                        TabelHeader.modal && classes.paginationModal,
                    ].join(" ")}
                >
                    <PaginationUi {...pagination} />
                </div>
            )}
        </Box>
    );
}

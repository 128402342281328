import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    marginBottom: 15,
    borderBottom: "1px solid rgba(152, 162, 179, 0.4)",
  },
  upload: {
    height: 400,
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    color: "rgba(71, 84, 103, 0.8)",
    fontWeight: 600,
  },
  tableHeader: {
    marginBottom: 16,
  },
  pagination: {
    marginTop: 50,
  },
  catItem: {
    border: "1px solid #E4E7EC",
  },
  itemCard: {
    border: "2px solid #E4E7EC",
  },
}));

import { Box } from "@mui/material";
import React from "react";
import useStyles from "./styles";

export default function HeaderBtn(props) {
  var classes = useStyles();
  const { className, icon } = props;
  return (
    <Box sx={{ flexGrow: 0 }}>
      <div
        {...props}
        icon={null}
        className={[
          classes.HeaderBtn,
          className,
          icon && classes.haveIcon,
        ].join(" ")}
      >
        {icon}
        {props.children}
      </div>
    </Box>
  );
}

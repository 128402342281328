import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useExhibitions from "../../../../../../customHooks/hookRepositories/useExhibitions";
import { setToolbar } from "../../../../../../redux/ToolbarSlice";
import FiltersEnum from "../../../../../../configs/FiltersEnum";
import ListLayout from "../../../../../../components/Layouts/ListLayout/ListLayout";
import BoothCard from "../../../../../Booths/components/BoothCard";
import RouteEnum from "../../../../../../navigation/RouteEnum";
import useStyles from "./styles";
import Icons from "../../../../../../assets/icons/Icons";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import useCustomTranslate from "../../../../../../customHooks/hookRepositories/useCustomTranslate";

export default function MyBoothsList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAll } = useExhibitions();
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "MyBooths",
        title: { name: tv("myBooth") },
        leftElements: [
          { type: "AdvanceSearch" },
          {
            type: "ButtonUi",
            name: tv("buyNewBooth"),
            Link: `${RouteEnum.MyBooths}/buy`,
          },
        ],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tv("myBooth") },
        ],
        // rightElements: [{ type: "Excel", name: `exhibitions/${exhibitionId}/salons/${salonId}/booths` }],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  useEffect(() => {
    getAll({ name: "booths", byName: true });
    // eslint-disable-next-line
  }, []);
  const {
    booths: { itemsList, paginator: { currentPage, pageCount } = {} },
  } = useSelector((s) => s.exhibitions);
  const [tabelData, setTabelData] = useState([]);
  useEffect(() => {
    if (itemsList) {
      const tabelData = itemsList.map(
        ({
          _id,
          logo,
          name,
          userViewedCount,
          isActive,
          description,
          exhibition,
          salon,
          adminUnreadMessage,
          boothDesign,
        }) => ({
          id: _id,
          logo,
          boothDesign: boothDesign?.index,
          adminUnreadMessage,
          userViewedCount,
          isActive,
          description,
          boothName: name,
          exhibition,
          salon,
        }),
      );
      setTabelData(tabelData);
    }
  }, [itemsList]);
  const [query, setQuery] = useState({
    is_active: "None",
  });
  const onQueryCheng = (e, type) => {
    const newQ = { ...query };
    newQ[type] = e.target ? e.target.value : e;
    setQuery({ ...newQ });
    getAll({ name: "booths", byName: true, query: newQ });
  };
  const TabelHeader = {
    sort: {
      type: "sort",
      children: FiltersEnum.Sort,
    },
    filter: [
      {
        type: "is_active",
        children: FiltersEnum.Active,
      },
    ],
  };

  const onPageChange = (e, page) => {
    getAll({ name: "booths", byName: true, query: { ...query, page } });
  };
  return (
    <ListLayout
      pagination={{
        count: pageCount,
        page: currentPage ? currentPage : 1,
        onChange: onPageChange,
      }}
      TabelHeader={{
        ...TabelHeader,
        onChange: onQueryCheng,
        value: query,
      }}
      hasData={true}
    >
      {tabelData?.length > 0 ? (
        tabelData.map((x) => (
          <Grid key={x.id} item lg={3} sm={6} xs={12} sx={{ display: "flex" }}>
            <BoothCard {...x} activeBtn query={query} />
          </Grid>
        ))
      ) : (
        <Link className={classes.noDataWrapper} to="buy">
          <Box sx={{ pb: "12px" }}>
            <Icons.ShopAdd />
          </Box>
          <div className={classes.title}>{tv("ClickAddBooth")}</div>
          <div className={classes.description}>{tv("ClickAddBoothOr")}</div>
        </Link>
      )}
    </ListLayout>
  );
}

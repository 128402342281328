import {makeStyles} from "@mui/styles";
import ChatBG from "../../../../../../assets/svg/Chat-BG.svg";

export default makeStyles((theme) => ({
    cardWrapper: {
        position: "sticky",
        top: 120,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 143px)",
        padding: 0,
        backgroundImage: `url(${ChatBG})`,
        boxShadow: "0px 4px 10px -1px rgba(98, 95, 86, 0.05)",
        backgroundColor: `${theme.palette.description.main}1A`,

        [theme.breakpoints.down("lg")]: {
            position: "relative",
            top: 0,
            width: '100%'
        }
    },
    header: {
        backgroundColor: `${theme.palette.boxUiBackground.main}`,
        borderBottom: `1px soled ${theme.palette.description.main}26`,
        padding: "12px 30px",
        borderRadius: "15px 15px 0 0",
        display: "flex",
        gap: 8,
        [theme.breakpoints.down("lg")]: {
            paddingRight: 5,
            paddingLeft: 5
        }
    },
    profileWrapper: {
        display: "flex",
    },
    arrowBack: {
        display: 'none !important',
        [theme.breakpoints.down("lg")]: {

            display: 'block !important',
            transform: 'rotate(90deg)',
            "& svg": {
                width: 16,
                height: 16,
            }
        }
    },
    arrowFront: {
        display: 'none !important',
        [theme.breakpoints.down("lg")]: {

            display: 'block !important',
            transform: 'rotate(-90deg)',
            "& svg": {
                width: 16,
                height: 16,
            }
        }
    },
    profile: {
        width: 50,
        height: 50,
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        borderRadius: 100,
        "& img": {
            maxWidth: "100%",
            maxHeight: "100%",
        },
    },
    name: {
        display: "flex",
        alignItems: "center",
    },
    body: {
        display: " flex",
        flexShrink: 1,
    },
    messagesWrapper: {
        flexGrow: 1,
        height: "calc(100vh - 336px)",
        display: "flex",
    },
    messages: {
        borderBottom: `1px solid ${theme.palette.description.main}26`,
        padding: "12px 30px",
        display: "flex",
        flexDirection: "column-reverse",
        gap: 15,
        minHeight: "100%",
    },
    adminMessages: {
        flexDirection: "row-reverse",
    },
    message: {
        display: "flex",
    },
    admin: {
        display: "flex",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 10,
        padding: "12px 14px 8px",
        flex: 1,
        flexDirection: "column",
        fontSize: 13,
        "& > div:first-child": {
            fontWeight: 600,
        },
        "& > div:last-child": {
            textAlign: "end",
            fontSize: 12,
            color: `${theme.palette.primary.contrastText}B2`,
        },
    },
    user: {
        display: "flex",
        backgroundColor: theme.palette.boxUiBackground.main,
        borderRadius: 10,
        padding: "12px 14px 8px",
        flex: 1,
        flexDirection: "column",
        "& > div:first-child": {
            color: theme.palette.primary.main,
        },
        "& > div:last-child": {
            textAlign: "end",
            fontSize: 12,
            color: `${theme.palette.addBoothBtnColor.main}B2`,
        },
    },
    footer: {
        backgroundColor: `${theme.palette.boxUiBackground.main}`,
        padding: "12px 30px",
        borderRadius: "0 0 15px 15px",
        display: "flex",
        gap: 8,
        alignItems: "flex-end",
    },
    input: {
        display: "flex",
        flexGrow: 1,
        "& > div": {
            padding: "13px 4px",
        },
    },
    disabled: {
        opacity: 0.5,
    },
}));

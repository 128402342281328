const FiltersEnum = {
  Sort: [
    { value: "date-desc", name: "Date - DESC" },
    { value: "date-asc", name: "Date - ASC" },
    { value: "name-asc", name: "Name - A to Z" },
    { value: "name-desc", name: "Name - Z to A" },
  ],
  Active: [
    { value: "None", name: "status" },
    { value: 1, name: "active" },
    { value: 0, name: "notActive" },
  ],
};
export default FiltersEnum;

import { Button, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import RouteEnum from "../../../navigation/RouteEnum";
import useStyles from "../styles";
import Icons from "../../../assets/icons/Icons";
import useExhibitions from "../../../customHooks/hookRepositories/useExhibitions";
import { MainUrl } from "../../../services/Api";
import { useTheme } from "@emotion/react";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import { useSelector } from "react-redux";
import DeleteModul from "../../../components/Modals/DeleteModul/DeleteModul";

export default function ExhibitionsCard({
  _id,
  logo,
  userViewedCount,
  isActive,
  exhibitionName,
  query,
  dashboard,
  onStatus,
  onDelete,
}) {
  var classes = useStyles();
  const { updateStatus, deleteOne } = useExhibitions();
  const { type } = useSelector((s) => s.roles);
  const statusHandler = async () => {
    await updateStatus({ type: "exhibitions", id: _id, query });
    onStatus && onStatus();
  };
  const { deleteModul } = DeleteModul();
  const deleteHandler = async () => {
    await deleteModul(async () => {
      await deleteOne({ type: "exhibitions", id: _id, query });
      onDelete && onDelete();
    });
  };
  const tv = useCustomTranslate("pages");
  const theme = useTheme();
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.overImage}></div>
      <div className={classes.logo}>
        <img
          style={!isActive ? { filter: " grayscale(100%)" } : {}}
          src={logo ? `${MainUrl}${logo}` : "/assets/image/no_photo.png"}
          alt="logo"
        />
      </div>
      <div className={classes.cardBody}>
        <div className={classes.editBtn}>
          <Link to={`${dashboard ? "Exhibitions/" : ""}${RouteEnum.Edit(_id)}`}>
            <IconButton>
              <Icons.Edit2 />
            </IconButton>
          </Link>
        </div>
        <div
          className={classes.name}
          style={!isActive ? { color: theme.palette.description.main } : {}}
        >
          {exhibitionName}
        </div>
        <div className={classes.describeWrapper}>
          <div className={classes.describe}>
            <div className={classes.describeTitle}>{tv("views")}:</div>
            <div
              style={!isActive ? { color: theme.palette.description.main } : {}}
            >
              {userViewedCount.toLocaleString()}
            </div>
          </div>
          <div className={classes.describe}>
            <div className={classes.describeTitle}>{tv("status")}:</div>
            {isActive ? (
              <div className={classes.active}>
                <div className={classes.activeDot}></div>
                <div>{tv("active")}</div>
              </div>
            ) : (
              <div className={classes.active}>
                <div className={classes.notActiveDot}></div>
                <div style={{ color: theme.palette.description.main }}>
                  {tv("notActive")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.btnWrapper}>
          <Link
            to={`${dashboard ? "" : "../../"}${RouteEnum.Salons(_id)}`}
            style={!isActive ? { pointerEvents: "none" } : {}}
            className={classes.btnLink}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={!isActive}
              classes={{ disabled: classes.disabledBtn }}
            >
              {tv("openExhibition")}
            </Button>
          </Link>
          <Button
            sx={{
              flex: 1,
            }}
            color={isActive ? "description" : "primary"}
            onClick={statusHandler}
          >
            {isActive ? tv("deactivate") : tv("activate")}
          </Button>
          {type === "admins" && (
            <Button onClick={deleteHandler} color="error">
              {tv("delete")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import AddForm from "../../../../components/AddForm/AddForm";
import AddFile from "../../../../components/AddForm/components/AddFile/AddFile";
import AddIcon from "../../../../components/AddForm/components/AddIcon/AddIcon";
import BoothDesignSelector from "../../../../components/AddForm/components/BoothDesign/BoothDesignSelector";
import SwitchUi from "../../../../components/BaseElement/SwitchUi/SwitchUi";
import useExhibitions from "../../../../customHooks/hookRepositories/useExhibitions";
import useSalons from "../../../../customHooks/hookRepositories/useSalons";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import { UploadFileType } from "../../../../configs/FormEnum";
import useStyles from "./styles";
import { MainUrl } from "../../../../services/Api";
import RouteEnum from "../../../../navigation/RouteEnum";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import getSymbolFromCurrency from "currency-symbol-map";
import { Button, Grid } from "@mui/material";
import { showModal } from "../../../../redux/ModalSlice";
import AddUploadUi from "../../../../components/AddForm/components/AddUploadUi/AddUploadUi";
import { Box } from "@mui/system";
import Limits from "../../../../configs/Limits";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";

export default function BoothAdd() {
  const [designs, setDesigns] = useState([]);
  const tvForms = useCustomTranslate("forms");
  const tvPages = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const { type } = useSelector((s) => s.roles);

  const classes = useStyles();
  const { exhibitionId, salonId, id } = useParams();
  const [formData, setFormData] = useState({
    isActive: true,
    exhibitionId,
    salonId,
    stands: [],
    tvs: [],
    photoBoards: [],
    catalogues: [],
    brochures: [],
  });
  const [currentDesign, setCurrentDesign] = useState();
  const [boothDesignError, setBoothDesignError] = useState();

  const dispatch = useDispatch();
  const { addOne, getOne, update } = useExhibitions();
  useEffect(() => {
    let breadcrumbs = [
      { name: tvCrumb("home"), href: "/" },
      { name: tvPages("exhibitions"), href: RouteEnum.Exhibitions },
      { name: tvPages("salons"), href: RouteEnum.Salons(exhibitionId) },
      {
        name: tvPages("booths"),
        href: RouteEnum.Booths(exhibitionId, salonId),
      },
      {
        name: id
          ? `${tvForms("edit")} ${tvPages("booth")}`
          : `${tvForms("new")} ${tvPages("booth")}`,
      },
    ];
    switch (type) {
      case "organizers":
        breadcrumbs = [
          { name: tvCrumb("home"), href: "/" },
          { name: tvPages("salons"), href: RouteEnum.Exhibition },
          {
            name: tvPages("booths"),
            href: RouteEnum.Booths(exhibitionId, salonId),
          },
          {
            name: id
              ? `${tvForms("edit")} ${tvPages("booth")}`
              : `${tvForms("new")} ${tvPages("booth")}`,
          },
        ];
        break;
      case "exhibitors":
        breadcrumbs = [
          { name: tvCrumb("home"), href: "/" },
          { name: tvPages("myBooth"), href: RouteEnum.MyBooths },
          {
            name: id
              ? `${tvForms("edit")} ${tvPages("booth")}`
              : `${tvForms("new")} ${tvPages("booth")}`,
          },
        ];
        break;
      default:
        break;
    }
    dispatch(
      setToolbar({
        id: "Exhibitions",
        title: {
          name: id
            ? `${tvForms("edit")} ${tvPages("booth")}`
            : `${tvForms("new")} ${tvPages("booth")}`,
        },
        breadcrumbs,
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, exhibitionId, salonId, id, type, tvForms]);
  useEffect(() => {
    const setData = async () => {
      const res = await getOne({ name: "booths", id });
      setFormData({
        stands: [],
        tvs: [],
        photoBoards: [],
        brochures: [],
        catalogues: formData.catalogues,
        ...res,
      });
      setCurrentDesign(res.boothDesignId);
    };
    if (id) {
      setData();
    }
    // eslint-disable-next-line
  }, [exhibitionId, salonId]);
  const { getAvailableBoothDesigns } = useSalons();
  const [corrency, setCorrency] = useState([]);

  useEffect(() => {
    const getBooths = async () => {
      let res = await getAvailableBoothDesigns(salonId);
      const ex = await getOne({ name: "exhibitions", id: exhibitionId });
      setCorrency(ex.currency.currencyName);
      setDesigns(res);
    };
    getBooths();
    // eslint-disable-next-line
  }, []);
  const { getValidUrl } = useAuth();
  const submitHandler = (error) => {
    setBoothDesignError(!formData.boothDesignId);
    if (error || !formData.boothDesignId) {
      return;
    }
    const { catalogues, phoneNumber, whatsappUrl, websiteUrl } = formData;
    const newCatalogues = [];
    catalogues.forEach(({ front, back }) => {
      if (front || back) {
        newCatalogues.push({ front, back });
      }
    });
    const data = {
      type: "booths",
      body: {
        ...formData,
        catalogues: newCatalogues,
        phoneNumber:
          phoneNumber?.code && phoneNumber?.number ? phoneNumber : "",
        whatsappUrl:
          whatsappUrl?.code && whatsappUrl?.number ? whatsappUrl : "",
        websiteUrl: getValidUrl(websiteUrl),
      },
      id,
      exhibitionId,
      salonId,
    };
    if (id) {
      update({ ...data, id });
    } else {
      addOne(data);
    }
  };
  const inputHandler = (value, type) => {
    const newValue = { ...formData };
    newValue[type] = value;
    setFormData(newValue);
  };

  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "logo",
            title: tvForms("logo"),
            component: (
              <AddIcon
                valueId="logo"
                src={formData.logo && `${MainUrl}${formData.logo}`}
                onChange={inputHandler}
                id="BoothLogo"
              />
            ),
          },
          {
            width: [2, 4],
            valueId: "name",
            title: tvForms("boothName"),
            required: true,
            input: {
              placeholder: tvForms("boothNamePlaceholder"),
            },
          },
          type !== "exhibitors"
            ? {
                width: [2, 4],
                valueId: "price",
                title: tvForms("price"),
                required: true,
                input: {
                  dir: "ltr",
                  placeholder: tvForms("pricePlaceholder"),
                  type: "number",
                  className: classes.price,
                  endadornment: getSymbolFromCurrency(corrency),
                },
              }
            : {
                width: [0, 6],
                sx: { display: "hidden" },
                titleSx: { display: "hidden" },
              },
          {
            width: [0, 6],
            sx: type !== "exhibitors" ? { display: "hidden" } : {},
            titleSx: type !== "exhibitors" ? { display: "hidden" } : {},
          },
          {
            width: [2, 8],
            valueId: "boothDesignId",
            title: tvForms("boothDesign"),
            component:
              type !== "exhibitors" ? (
                <BoothDesignSelector
                  value={formData.boothDesignId}
                  error={boothDesignError}
                  children={designs}
                  currentDesign={currentDesign}
                  onChange={inputHandler}
                />
              ) : (
                <div>
                  {
                    designs?.filter((x) => x._id === formData.boothDesignId)[0]
                      ?.title
                  }
                </div>
              ),
          },
          {
            width: [0, 2],
            component: (
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  padding: "8px 20px",
                  borderRadius: 2.5,
                }}
                onClick={() => {
                  dispatch(showModal({ open: "mapViewModal" }));
                }}
              >
                {tvForms("mapView")}
              </Button>
            ),
          },
        ],
      },
    },
    {
      col: {
        direction: "row",
        width: 6,
        fields: [
          {
            width: [4, 8],
            valueId: "websiteUrl",
            title: tvForms("website"),
            input: {
              placeholder: tvForms("websitePlaceholder"),
            },
          },
          {
            width: [4, 8],
            valueId: "videoCallUrl",
            title: tvForms("videoCallLink"),
            input: {
              placeholder: tvForms("videoCallLinkPlaceholder"),
            },
          },
        ],
      },
    },
    {
      col: {
        direction: "row",
        width: 6,
        fields: [
          {
            width: [4, 8],

            sx: { justifyContent: "flex-start" },
            titleSx: { justifyContent: "flex-start !important" },
            valueId: "description",
            title: tvForms("description"),
            input: {
              placeholder: tvForms("descriptionPlaceholder"),
              multiline: true,
              rows: 4,
              inputProps: { maxLength: Limits.characters.booths },
            },
          },
        ],
      },
    },
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "telegramUrl",
            title: tvForms("telegramUrl"),
            input: {
              placeholder: tvForms("telegramUrlPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "instagramUrl",
            title: tvForms("instagramUrl"),
            input: {
              placeholder: tvForms("instagramUrlPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "whatsappUrl",
            title: tvForms("whatsappUrl"),
            input: {
              className: classes.price,
              type: "tel",
              dir: "ltr",
              placeholder: tvForms("whatsappUrlPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "phoneNumber",
            title: tvForms("phoneNumber"),
            input: {
              type: "tel",
              dir: "ltr",
              placeholder: tvForms("phoneNumberPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "isActive",
            title: tvPages("active"),
            component: (
              <SwitchUi
                onChange={(e) => inputHandler(e.target.checked, "isActive")}
                checked={formData.isActive}
              />
            ),
          },
        ],
      },
    },
  ];
  const boothDesign = designs.filter(
    (v) => v._id === formData.boothDesignId,
  )[0];
  const {
    catalogue,
    photoBoard,
    stand,
    tv,
    cataloguePreviews,
    photoBoardPreviews,
    standPreviews,
    tvPreviews,
  } = boothDesign ? boothDesign : {};
  useEffect(() => {
    const catalogues = formData.catalogues;
    if (catalogue && catalogues.length === 0) {
      const cataloguesShow = [];
      for (let i = 0; i < catalogue; i++) {
        cataloguesShow.push({
          front: "",
          back: "",
        });
      }
      inputHandler(cataloguesShow, "catalogues");
    }
    //eslint-disable-next-line
  }, [formData.catalogues, catalogue]);
  useEffect(() => {
    const catalogues = formData.catalogues;
    if (catalogue) {
      const cataloguesShow = [];
      if (catalogues.length > 0) {
        for (let i = 0; i < catalogue; i++) {
          cataloguesShow.push(
            catalogues[i]
              ? catalogues[i]
              : {
                  front: "",
                  back: "",
                },
          );
        }
      }
      inputHandler(cataloguesShow, "catalogues");
    }
    //eslint-disable-next-line
  }, [catalogue]);

  const callBack = ({ value, index, type }) => {
    const oldValue = formData.catalogues;
    if (value) {
      oldValue[index][type] = value;
    } else {
      oldValue[index][type] = "";
    }
    inputHandler(oldValue, "catalogues");
  };
  const afterForm = [
    <AddFile
      key="Introduction Video"
      title={tvForms("introductionVideo")}
      description={tvForms("introductionVideoDesc")}
      placeholder="Select a video for booth tv"
      fileType={UploadFileType.video}
      onChange={inputHandler}
      valueId="tvs"
      value={formData.tvs}
      preview={tvPreviews}
      limit={tv}
      disabled={!boothDesign}
    />,
    <AddFile
      key="Brochures"
      unlimited
      title={tvForms("brochures")}
      description={tvForms("brochuresDesc")}
      placeholder="Select pdf brochure  file"
      fileType={UploadFileType.file}
      onChange={inputHandler}
      valueId="brochures"
      value={formData.brochures}
      disabled={!boothDesign}
    />,
    <AddFile
    imageWarning
      key="Catalogue"
      title={tvForms("catalogue")}
      description={tvForms("catalogueDesc")}
      preview={cataloguePreviews}
      limit={catalogue}
      disabled={!boothDesign}
    >
      <Grid container sx={{ textAlign: "center", gap: 2.5 }}>
        {formData.catalogues.length > 0 &&
          formData.catalogues.map(({ front, back }, index) => (
            <Box item className={classes.catalogue} key={index}>
              <AddUploadUi
                height={120}
                width={160}
                title={tvForms("catalogueFront")}
                description={
                  <>
                    {tvForms("bestSize")} <div>w:1280px, h:720px</div>
                  </>
                }
                className={
                  !front ? classes.catalogueCard : classes.catalogueCardUrlFront
                }
                ratio={16 / 9}
                fileType={UploadFileType.image}
                url={front}
                id={`${index}-front`}
                callBack={(value) => callBack({ value, index, type: "front" })}
              />
              <AddUploadUi
                height={120}
                width={160}
                title={tvForms("catalogueBack")}
                description={
                  <>
                    {tvForms("bestSize")} <div>w:1280px, h:720px</div>
                  </>
                }
                className={
                  !back ? classes.catalogueCard : classes.catalogueCardUrlBack
                }
                ratio={16 / 9}
                fileType={UploadFileType.image}
                url={back}
                id={`${index}-back`}
                callBack={(value) => callBack({ value, index, type: "back" })}
              />
            </Box>
          ))}
      </Grid>
    </AddFile>,
    <AddFile
    imageWarning
      key="Stand Banners"
      ratio={1 / 2}
      uploadProps={{
        description: `( ${tvForms("bestSize")} w:720px, h:1440px )`,
      }}
      title={tvForms("standBanners")}
      description={tvForms("standBannersDesc")}
      placeholder="Select a stand banner image"
      fileType={UploadFileType.image}
      onChange={inputHandler}
      valueId="stands"
      value={formData.stands}
      preview={standPreviews}
      limit={stand}
      disabled={!boothDesign}
    />,
    <AddFile
    imageWarning
      key="Photo Board"
      ratio={9 / 16}
      uploadProps={{
        description: `( ${tvForms("bestSize")} w:720px, h:1280px )`,
      }}
      title={tvForms("photoBoard")}
      description={tvForms("photoBoardDesc")}
      placeholder="Select a stand banner image"
      fileType={UploadFileType.image}
      onChange={inputHandler}
      valueId="photoBoards"
      value={formData.photoBoards}
      preview={photoBoardPreviews}
      limit={photoBoard}
      disabled={!boothDesign}
    />,
  ];

  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title={tvPages("booth")}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
        afterForm={afterForm}
      />
    </div>
  );
}

import Icons from "../../assets/icons/Icons";
import RouteEnum from "../../navigation/RouteEnum";

const admins = {
  type: "admins",
  mainLink: RouteEnum.admin,
  filesTab: [
    { value: "0", label: "allFiles" },
    { value: "admins", label: "uploadByAdmin" },
    { value: "organizers", label: "uploadByOrganizers" },
    { value: "exhibitors", label: "uploadByExhibitors" },
    { value: "me", label: "uploadByMe" },
  ],
  menuList: [
    {
      id: "Dashboard",
      name: "dashboard",
      link: RouteEnum.dashboard,
      icon: <Icons.BubbleSidebar />,
    },
    {
      id: "Users",
      name: "users",
      icon: <Icons.Profile2User />,
      children: [
        {
          id: "Organizers",
          name: "users_organizers",
          link: RouteEnum.user.Organizers,
        },
        {
          id: "Exhibitors",
          name: "users_exhibitors",
          link: RouteEnum.user.Exhibitors,
        },
        {
          id: "Visitors",
          name: "users_visitors",
          link: RouteEnum.user.Visitors
        },
      ],
    },
    {
      id: "Exhibitions",
      name: "exhibitions",
      icon: <Icons.Signpost />,
      link: RouteEnum.Exhibitions,
    },
    {
      id: "Events",
      name: "events",
      icon: <Icons.Calendar2 />,
      link: RouteEnum.Events,
    },
    {
      id: "Comments",
      name: "comments",
      icon: <Icons.Messages2 />,
      link: RouteEnum.Comments,
    },
    {
      id: "Files",
      name: "files",
      icon: <Icons.FolderOpen />,
      link: RouteEnum.Files,
    },
    {
      id: "Analysis",
      name: "analysis",
      icon: <Icons.Chart />,
      link: RouteEnum.Analysis,
    },
    {
      id: "Language",
      name: "language",
      icon: <Icons.Language/> ,
      link: RouteEnum.Language,
    },
  ],
  UserMenu: [
    {
      icon: <Icons.Setting />,
      title: "settings",
      id: "Settings",
      link: `settings`,
    },
    { icon: <Icons.Logout />, title: "logout", id: "Logout" },
  ],
};
export default admins;

import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Icons from "../../../assets/icons/Icons";
import useStyles from "./styles";

export default function TitelUi({ title, description, link }) {
  var classes = useStyles();
  const { t } = useTranslation();
  const isRtl = t("dir") === "rtl";
  return (
    <div className={classes.titleWrapper}>
      {(title || link) && (
        <Typography
          gutterBottom
          className={classes.title}
          sx={{ fontWeight: 600 }}
          component="div"
        >
          {title}
          {link && (
            <Link to={link.href} className={classes.link}>
              {link.title}
              <div className={`${classes.linkIcon} ${isRtl?classes.linkIconRtl:""}`}>
                <Icons.ArrowDown />
              </div>
            </Link>
          )}
        </Typography>
      )}
      {description && (
        <Typography gutterBottom className={classes.description} variant="body2" sx={{ fontSize: ".8rem" }}>
          {description}
        </Typography>
      )}
    </div>
  );
}

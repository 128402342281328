import { Typography } from "@mui/material";
import React from "react";
import useStyles from "./styles";

export default function LoginDescription({ title, description }) {
  var classes = useStyles();
  return (
    <React.Fragment>
      {title && (
        <Typography
          className={classes.title}
          variant="h6"
          gutterBottom
          component="div"
        >
          {title}
        </Typography>
      )}{" "}
      {description && (
        <Typography className={classes.describe} variant="body2">
          {description}
        </Typography>
      )}
    </React.Fragment>
  );
}

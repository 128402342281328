import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  HeaderBtn: {
    height: 35,
    borderRadius: 10,
    backgroundColor: theme.palette.headerBtnBackground.main,
    position: "relative",
    padding: "8px",
    cursor: "pointer",
  },
  haveIcon: {
    paddingLeft: "38px !important",
    "& svg": {
      position: "absolute",
      left: "8px",
      top: "calc(50% - 12px)",
    },
  },
}));

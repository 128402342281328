import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListLayout from "../../../../components/Layouts/ListLayout/ListLayout";
import FiltersEnum from "../../../../configs/FiltersEnum";
import useGetConstant from "../../../../customHooks/hookRepositories/useGetConstant";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import LanguageCard from "./components/LanguageCard/LanguageCard";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
// import useStyles from "./styles";

export default function LanguagesList(props) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { languages } = useSelector((s) => s.constants);
  const tv = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const { getLanguages } = useGetConstant();
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Language",
        title: { name: tv("language") },
        leftElements: [
          { type: "AdvanceSearch" },
          {
            type: "ButtonUi",
            name: `${tv("add")} ${tv("language")}`,
            Link: "Language/add",
          },
        ],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("languages") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch,tv]);
  useEffect(() => {
    getLanguages();
    // eslint-disable-next-line
  }, []);
  const TabelHeader = {
    sort: {
      type: "sort",
      children: FiltersEnum.Sort,
    },
  };
  const [query, setQuery] = useState({ page: 1 });
  const onQueryCheng = (e, type) => {
    const newQ = {};
    newQ[type] = e.target ? e.target.value : e;
    setQuery({ ...query, ...newQ });
  };
  return (
    <ListLayout
      TabelHeader={{
        ...TabelHeader,
        onChange: onQueryCheng,
        value: query,
      }}
      hasData
    >
      {languages &&
        languages.map((x) => (
          <Grid key={x._id} item xs={6} md={4} lg={2.4} sx={{ display: "flex" }}>
            <LanguageCard {...x} />
          </Grid>
        ))}
    </ListLayout>
  );
}

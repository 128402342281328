import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "drawerMenu",
  initialState: false,
  reducers: {
    setDrawerMenu(state, action) {
      return action.payload;
    },
  },
});

export const { setDrawerMenu } = drawerSlice.actions;
export default drawerSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const toolbarSlice = createSlice({
  name: "table",
  initialState: {
    itemsList: [],
    paginator: {}
  },
  reducers: {
    setTable(state, action) {
      return action.payload;
    },
  },
});

export const { setTable } = toolbarSlice.actions;
export default toolbarSlice.reducer;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  box: {
    padding: 20,
    backgroundColor: theme.palette.boxUiBackground.main,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      borderRadius: 25,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    "& > h6": {
      paddingLeft: 10,
    },
  },
  login: {
    width: 420,
    padding: 30,
    borderRadius: 45,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      width: 320,
      borderRadius: 25,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
    "& > h6": {
      paddingLeft: 10,
    },
  },
}));

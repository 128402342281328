import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import AddForm from "../../../../components/AddForm/AddForm";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";
import useExhibitions from "../../../../customHooks/hookRepositories/useExhibitions";
import RouteEnum from "../../../../navigation/RouteEnum";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
// import useStyles from "./styles";

export default function SalonsAdd() {
  // var classes = useStyles();
  const dispatch = useDispatch();
  const { exhibitionId, id } = useParams();
  const [formData, setFormData] = useState({
    exhibitionId: exhibitionId,
    isActive: true,
  });
  const { type } = useSelector((s) => s.roles);
  const { addOne, getOne, update } = useExhibitions();
  const tv = useCustomTranslate("forms");
  const tvPages = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Exhibitions",
        title: { name: `${id ? tv("edit") : tv("new")} ${tvPages("salon")}` },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs:
          type === "organizers"
            ? [
                { name: tvCrumb("home"), href: "/" },
                { name: tvPages("salons"), href: RouteEnum.Exhibition },
                { name: `${id ? tv("edit") : tv("new")} ${tvPages("salon")}` },
              ]
            : [
                { name: tvCrumb("home"), href: "/" },
                { name: tvPages("exhibitions"), href: RouteEnum.Exhibitions },
                {
                  name: tvPages("salons"),
                  href: RouteEnum.Salons(exhibitionId),
                },
                { name: `${id ? tv("edit") : tv("new")} ${tvPages("salon")}` },
              ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, id, tv]);
  useEffect(() => {
    const setData = async () => {
      const { name, isActive } = await getOne({ name: "salons", id });
      setFormData({
        name,
        isActive,
      });
    };
    if (id) {
      setData();
    }
    // eslint-disable-next-line
  }, []);

  const inputHandler = (value, type) => {
    const newValue = {};
    newValue[type] = value;
    setFormData({ ...formData, ...newValue });
  };
  const { isValid } = useAuth();
  const submitHandler = (error) => {
    if (
      !isValid({
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      }) ||
      error
    ) {
      return;
    }
    if (id) {
      update({
        type: "salons",
        body: formData,
        id,
        exhibitionId,
      });
    } else {
      addOne({
        type: "salons",
        body: formData,
      });
    }
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "name",
            title: tv("salonName"),
            required: true,
            input: { placeholder: tv("salonNamePlaceholder") },
          },
          {
            width: [2, 4],
            title: " ",
          },
          {
            width: [2, 4],
            valueId: "isActive",
            title: tv("activation"),
            select: {
              children: [
                { name: tvPages("active"), value: true },
                { name: tvPages("notActive"), value: false },
              ],
            },
          },
          {
            width: [2, 4],
            title: " ",
          },
        ],
      },
    },
  ];

  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title={tvPages("salon")}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
      />
    </div>
  );
}

import Icons from "../../assets/icons/Icons";
import RouteEnum from "../../navigation/RouteEnum";

const Organizers = {
  type: "organizers",
  mainLink: RouteEnum.Organizer,
  filesTab: [
    { value: "0", label: "allFiles" },
    { value: "exhibitors", label: "uploadByExhibitors" },
    { value: "me", label: "uploadByMe" },
  ],
  menuList: [
    {
      id: "Dashboard",
      name: "dashboard",
      link: RouteEnum.dashboard,
      icon: <Icons.BubbleSidebar />,
    },
    {
      id: "Users",
      name: "users",
      icon: <Icons.Profile2User />,
      children: [
        {
          id: "Exhibitors",
          name: "users_exhibitors",
          link: RouteEnum.user.Exhibitors,
        },
        { id: "Visitors", name: "users_visitors", link: RouteEnum.user.Visitors },
      ],
    },
    {
      id: "Exhibition",
      name: "exhibition",
      icon: <Icons.Signpost />,
      link: RouteEnum.Exhibition,
    },
    {
      id: "Events",
      name: "events",
      icon: <Icons.Calendar2 />,
      link: RouteEnum.Events,
    },
    {
      id: "Comments",
      name: "comments",
      icon: <Icons.Messages2 />,
      link: RouteEnum.Comments,
    },
    {
      id: "Files",
      name: "files",
      icon: <Icons.FolderOpen />,
      link: RouteEnum.Files,
    },
    {
      id: "Analysis",
      name: "analysis",
      icon: <Icons.Chart />,
      link: RouteEnum.Analysis,
    },
  ],
  UserMenu: [
    {
      icon: <Icons.Setting />,
      title: "settings",
      id: "Settings",
      link: `settings`,
    },
    { icon: <Icons.Logout />, title: "logout", id: "Logout" },
  ],
};
export default Organizers;

import { Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../redux/ModalSlice";
import Modal from "../../BaseElement/Modal/Modal";
import UploadPreviewUi from "../../BaseElement/UploadPreviewUi/UploadPreviewUi";
import UploadUi from "../../BaseElement/UploadUi/UploadUi";
import TabPanel from "../components/TabPanel";
import useStyles from "./styles";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";


export default function UploadModal(props) {

  //region--------------- states
  var classes = useStyles();
  const [value, setValue] = useState(0);
  const [files, setFiles] = useState();
  const { open } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const tv = useCustomTranslate('modal');
  const handleClose = () => {
    setFiles("");
    dispatch(showModal({ open: false }));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //endregion-------------- handlers
  return (
    <>
      <Modal title={tv('openFile')} open={open === "UploadModal"} onClose={handleClose}>
        <Tabs
          className={classes.root}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#755EFC",
              zIndex: 0,
              borderRadius: 20,
              height: 4,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="full width tabs example"
        >
          <Tab disableRipple label={tv('tabUpload')} />
        </Tabs>
        <TabPanel value={value} index={0} gap={5}>
          {files && files[0] ? (
            <Grid item xs={12} className={classes.upload}>
              <UploadPreviewUi files={files} onCancel={() => setFiles("")} />
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.upload}>
              <UploadUi fileManager onDrop={(v) => setFiles(v)} />
            </Grid>
          )}
        </TabPanel>
      </Modal>
    </>
  );
}

import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";

//icon
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import useGetConstant from "../../../customHooks/hookRepositories/useGetConstant";
import { useSelector } from "react-redux";
import { findFlagUrlByIso2Code } from "country-flags-svg";

export default function InputUi(props) {
  var classes = useStyles();
  const {
    icon: Icon,
    type,
    startadornment,
    endadornment,
    onChange,
    sx,
    InputProps,
    inputsx,
    code,
  } = props;
  const [unhiden, setUnhiden] = useState(false);
  const [phoneCode, setPhoneCode] = useState({ key: "IR", code: "+98" });
  const { getCountryCode } = useGetConstant();
  const { dir } = useSelector((s) => s.dir);
  const isRtl = dir === "rtl";
  const { phoneCodes: PhoneCodesArray } = useSelector((s) => s.constants);
  const isTel = type === "tel";
  useEffect(() => {
    if (isTel && PhoneCodesArray.length === 0) {
      getCountryCode();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (code && code !== phoneCode?.code && PhoneCodesArray.length) {
      console.log({ code, PhoneCodesArray });
      setPhoneCode(PhoneCodesArray.filter((c) => c.code === code)[0]);
    }
    // eslint-disable-next-line
  }, [code, PhoneCodesArray]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        mb: 1,
        position: "relative",
        ...sx,
      }}
    >
      <TextField
        {...props}
        icon={null}
        type={unhiden ? "text" : type}
        fullWidth
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
            notchedOutline: classes.OutlinedInput,
          },
          sx: {
            borderColor: "transparent",
            ...inputsx,
          },
          className: [classes.input, props.className].join(" "),
          startAdornment: (
            <React.Fragment>
              {Icon && (
                <InputAdornment position={isRtl ? "end" : "start"}>
                  <div
                    className={[
                      classes.startAdornment,
                      isRtl && classes.startAdornmentLtr,
                    ].join(" ")}
                  >
                    {Icon}
                  </div>
                </InputAdornment>
              )}
              {isTel && (
                <InputAdornment position={isRtl ? "end" : "start"}>
                  <div
                    className={[
                      classes.tel,
                      classes.startAdornment,
                      isRtl && classes.startAdornmentLtr,
                    ].join(" ")}
                  >
                    <div>{phoneCode?.key}</div>
                    <Autocomplete
                      disableClearable
                      filterOptions={(op, { inputValue }) => {
                        return inputValue !== ""
                          ? op.filter(
                            ({ key, code, name }) =>
                              code
                                ?.toLowerCase()
                                .indexOf(inputValue?.toLowerCase()) !== -1 ||
                              key
                                ?.toLowerCase()
                                .indexOf(inputValue?.toLowerCase()) !== -1 ||
                              name
                                ?.toLowerCase()
                                .indexOf(inputValue?.toLowerCase()) !== -1,
                          )
                          : op;
                      }}
                      isOptionEqualToValue={(op, val) => op.code === val.code}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.key}>
                          <span>
                            <img
                              alt=""
                              className={classes.countrImg}
                              src={findFlagUrlByIso2Code(option.key)}
                            />
                          </span>
                          {option.code}
                        </Box>
                      )}
                      fullWidth
                      classes={{
                        root: classes.phoneCodeList,
                        listbox: classes.listbox,
                        popper: classes.popper,
                        option: classes.option,
                      }}
                      options={PhoneCodesArray}
                      getOptionLabel={(option) =>
                        option ? option?.code : option
                      }
                      value={phoneCode}
                      onChange={(e, countryCode) => {
                        onChange &&
                          onChange(
                            { target: { value: props?.value } },
                            countryCode,
                          );
                        setPhoneCode(countryCode);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            // label="Choose a country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </InputAdornment>
              )}
              {startadornment}
            </React.Fragment>
          ),
          endAdornment: (
            <React.Fragment>
              {type === "password" && (
                <InputAdornment position="end">
                  <div
                    className={classes.visibility}
                    onClick={() => setUnhiden(!unhiden)}
                  >
                    {unhiden ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </div>
                </InputAdornment>
              )}
              {endadornment}
            </React.Fragment>
          ),
          ...InputProps,
        }}
        onChange={(e) => onChange && onChange(isTel ? { target: { value: e.target.value.replace(/^0+/, '') } } : e, phoneCode)}
      />
    </Box>
  );
}

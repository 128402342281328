import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useExhibitions from "../../../../customHooks/hookRepositories/useExhibitions";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import SalonsCard from "../SalonsCard";
import { useParams } from "react-router-dom";
import FiltersEnum from "../../../../configs/FiltersEnum";
import RouteEnum from "../../../../navigation/RouteEnum";
import ListLayout from "../../../../components/Layouts/ListLayout/ListLayout";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function SalonsList() {
  const linkName = "salons";
  const { getAll } = useExhibitions();
  const { info } = useSelector((s) => s.auth);
  const { type } = useSelector((s) => s.roles);
  const IsOrg = type === "organizers";
  const params = useParams();
  const { exhibitionId } = IsOrg ? (info ? info.extraInfo : {}) : params;
  const dispatch = useDispatch();
  const tv = useCustomTranslate("forms");
  const tvPages = useCustomTranslate("pages");
  const tvCrumb = useCustomTranslate("breadcrumb");
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Exhibitions",
        title: { name: tvPages("salons") },
        leftElements: !IsOrg && [
          { type: "AdvanceSearch" },
          {
            type: "ButtonUi",
            name: `${tv("new")} ${tvPages("salon")}`,
            Link: RouteEnum.Salons(exhibitionId) + "/add",
          },
        ],
        rightElements: [
          { type: "Excel", name: `exhibitions/${exhibitionId}/salons` },
        ],
        breadcrumbs: IsOrg
          ? [{ name: "Home", href: "/" }, { name: tvPages("salons") }]
          : [
              { name: tvCrumb("home"), href: "/" },
              { name: tv("exhibitions"), href: RouteEnum.Exhibitions },
              { name: `${tv("new")} ${tvPages("salon")}` },
            ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, exhibitionId, tv]);
  useEffect(() => {
    getAll({ name: linkName, exhibitionId });
    // eslint-disable-next-line
  }, []);

  const {
    salons: { itemsList, paginator: { currentPage, pageCount } = {} },
  } = useSelector((s) => s.exhibitions);

  const [tabelData, setTabelData] = useState([]);
  useEffect(() => {
    if (itemsList) {
      const tabelData = itemsList.map(
        ({ _id, userViewedCount, isActive, name, boothsCount }) => ({
          id: _id,
          userViewedCount,
          isActive,
          salonsName: name,
          Booths: boothsCount,
        }),
      );
      setTabelData(tabelData);
    }
  }, [itemsList]);

  const [query, setQuery] = useState({});
  const onQueryCheng = (e, type) => {
    const newQ = { ...query };
    newQ[type] = e.target ? e.target.value : e;
    setQuery({ ...newQ });
    getAll({ name: linkName, exhibitionId, query: newQ });
  };
  const TabelHeader = {
    sort: {
      type: "sort",
      children: FiltersEnum.Sort,
    },
    filter: [
      {
        type: "is_active",
        children: FiltersEnum.Active,
      },
    ],
  };
  const onPageChange = (e, page) => {
    getAll({ name: linkName, exhibitionId, query:{...query, page} });
  };
  return (
    <ListLayout
      pagination={{
        count: pageCount,
        page: currentPage ? currentPage : 1,
        onChange: onPageChange,
      }}
      TabelHeader={{
        ...TabelHeader,
        onChange: onQueryCheng,
        value: query,
      }}
      hasData={tabelData?.length > 0}
    >
      {/* <Grid item xs={4} sx={{ display: "flex" }}>
            <div className={classes.cardWrapper}>
              <Link className={classes.addIconWrapper} to="add">
                <Icons.Add className={classes.addIcon} />
                <div className={classes.addTitle}>Add New Salon</div>
              </Link>
            </div>
          </Grid> */}
      {tabelData.length > 0 &&
        tabelData.map((x) => (
          <Grid key={x.id} item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
            <SalonsCard {...x} query={query} IsOrg={IsOrg} />
          </Grid>
        ))}
    </ListLayout>
  );
}

import Icons from "../../assets/icons/Icons";
import RouteEnum from "../../navigation/RouteEnum";

const exhibitors = {
  type: "exhibitors",
  mainLink: RouteEnum.exhibitor,
  filesTab: [
    { value: "0", label: "allFiles" },
    { value: "me", label: "uploadByMe" },
  ],
  menuList: [
    {
      id: "Dashboard",
      name: "dashboard",
      link: RouteEnum.dashboard,
      icon: <Icons.BubbleSidebar />,
    },
    {
      id: "MyBooths",
      name: "myBooths",
      icon: <Icons.Signpost />,
      link: RouteEnum.MyBooths,
    },
    {
      id: "Comments",
      name: "comments",
      icon: <Icons.Messages2 />,
      link: RouteEnum.Comments,
    },
    {
      id: "Files",
      name: "files",
      icon: <Icons.FolderOpen />,
      link: RouteEnum.Files,
    },
  ],
  UserMenu: [
    {
      icon: <Icons.Setting />,
      title: "settings",
      id: "Settings",
      link: `settings`,
    },
    {
      icon: <Icons.InfoCircle />,
      title: "howToUse",
      id: "HowToUse",
    },
    { icon: <Icons.Logout />, title: "logout", id: "Logout" },
  ],
};
export default exhibitors;

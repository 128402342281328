import { useDispatch, useSelector } from "react-redux";
import { BaseUrl, MainUrl, ResponseStatus } from "../../services/Api";
import useGet from "../useGet";
import io from "socket.io-client";
import { setChat, setNewMessage } from "../../redux/ChatSlice";
import usePut from "../usePut";

let socket;
const useChat = () => {
  const dispatch = useDispatch();
  const fetchGet = useGet();
  const put = usePut();
  const startWs = (token) => {
    socket = io(MainUrl.replace("http", "ws"));
    
    socket.on("socketId", async (e) => {
      socket.emit("ready", { token, role: "admin" });
    });
    
    socket.on("privateNewMessage", (e) => {
      dispatch(setNewMessage({ data: e }));
    });
    
    socket.on("newChatroom", (e) => {
      socket.emit("ready", { token, role: "admin" });
    });
  };
  const chatUrl = (id) => `${BaseUrl}/admins/chats/${id}`;
  const getAllChats = async (id) => {
    try {
      let res = await fetchGet(chatUrl(id));
      if (res.status === ResponseStatus.Success) {
        dispatch(setChat({ chatList: res.data, messages: null }));
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const { chatList } = useSelector((s) => s.chat);
  const getChatroom = async (id) => {
    try {
      let res = await fetchGet(chatUrl(`${id}/private`));
      if (res.status === ResponseStatus.Success) {
        dispatch(
          setChat({
            chatList: {
              ...chatList,
              rooms: chatList.rooms.map((x) =>
                x._id === id ? { ...x, adminUnreadMessage: false } : x,
              ),
            },
            messages: res.data,
          }),
        );
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("error", e);
      return false;
    }
  };

  const sendMessage = async (id, message) => {
    try {
      let res = await put(
        chatUrl(`${id}/private/send-message`),
        { message },
        "",
        {},
        false,
        true,
      );
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return { getChatroom, getAllChats, startWs, sendMessage };
};

export function socketDisconnect() {
  socket?.disconnect();
}

export function sendNotification(data) {
  if (!data) {
    return false;
  }
  var title = !data.title ? "Notification" : data.title;
  var clickCallback = data.clickCallback;
  var message = !data.message ? "null" : data.message;
  var icon = !data.icon ? "/assets/image/no_photo.png" : data.icon;
  var sendNotification = () => {
    var notification = new Notification(title, {
      icon: icon,
      body: message,
    });
    notification.onclick = () => {
      clickCallback && clickCallback();
      notification.close();
    };
  };

  if (!window.Notification) {
    return false;
  } else {
    if (Notification.permission === "default") {
      Notification.requestPermission((p) => {
        if (p !== "denied") {
          sendNotification();
        }
      });
    } else {
      sendNotification();
    }
  }
}

export default useChat;

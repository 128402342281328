import React, { useEffect, useState } from "react";
import AddForm from "../../components/AddForm/AddForm";
import AddFile from "../../components/AddForm/components/AddFile/AddFile";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setToolbar } from "../../redux/ToolbarSlice";
import InputUi from "../../components/BaseElement/InputUi/InputUi";
import { UploadFileType } from "../../configs/FormEnum";
import useCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import { Collapse } from "@mui/material";
import useAuth from "../../customHooks/hookRepositories/useAuth";
import useSettings from "../../customHooks/hookRepositories/useSettings";

export default function Settings(props) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    sponsors: [],
  });
  const tv = useCustomTranslate("pages");
  const tvForms = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Settings",
        title: { name: tv("settings") },
        leftElements: [{ type: "AdvanceSearch" }],
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tv("settings") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  const { info } = useSelector((s) => s.auth);
  useEffect(() => {
    if (info) {
      const {
        email,
        phoneNumber,
        name,
        family,
        sponsors: { value: sponsors = [] } = {},
        visitorsWelcomeMessage: {
          value: visitorsWelcomeMessage,
          isActive: visitorsWelcomeMessageActivation,
        } = {},
        exhibitorsWelcomeMessage: {
          value: exhibitorsWelcomeMessage,
          isActive: exhibitorsWelcomeMessageActivation,
        } = {},
      } = info;
      setFormData({
        ...formData,
        email,
        phoneNumber,
        name,
        family,
        sponsors,
        visitorsWelcomeMessageActivation,
        visitorsWelcomeMessage,
        exhibitorsWelcomeMessage,
        exhibitorsWelcomeMessageActivation,
      });
    }
    // eslint-disable-next-line
  }, [info]);

  const inputHandler = (value, type) => {
    const newValue = { ...formData };
    newValue[type] = value;
    setFormData(newValue);
  };
  const { updateSettings } = useSettings();
  const { isValid } = useAuth();
  const submitHandler = (error) => {
    if (
      !isValid({
        email: formData.email,
        password: formData.password && formData.password,
        confirmPassword: formData.confirmPassword && formData.confirmPassword,
      }) ||
      error
    ) {
      return;
    }
    updateSettings("admins", formData);
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "name",
            title: tvForms("name"),
            // required: true,
            input: {
              placeholder: tvForms("namePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "family",
            title: tvForms("lastName"),
            // required: true,
            input: {
              placeholder: tvForms("lastNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "email",
            title: tvForms("email"),
            required: true,
            input: {
              placeholder: tvForms("emailPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "phoneNumber",
            title: tvForms("phoneNumber"),
            // required: true,
            input: {
              dir: "ltr",
              placeholder: tvForms("phoneNumberPlaceholder"),
            },
          },
        ],
      },
    },
  ];

  const afterForm = [
    <AddFile
      onChange={inputHandler}
      valueId="sponsors"
      uploadProps={{
        width: 150,
        height: 150,
        title: tvForms("selectLogo"),
        description: (
          <>
            {tvForms("bestSize")}
            <div>w:720 px, h:720 px </div>
          </>
        ),
        className: classes.upload,
      }}
      unlimited
      value={formData.sponsors}
      key="Sponsors Logo"
      title={tvForms("sponsorsLogo")}
      description={`( ${tvForms("sponsorsLogoDesc")} )`}
      placeholder={tvForms("sponsorsLogo")}
      fileType={UploadFileType.image}
    />,
    <div key="Notifications" className={classes.notifications}>
      {tv("notifications")}
    </div>,
    <AddFile
      key="information"
      description={tvForms("notificationsDesc")}
      sx={{ mt: "5px" }}
      switchBtn={{
        checked: formData.exhibitorsWelcomeMessageActivation,
        onChange: (e) =>
          inputHandler(e.target.checked, "exhibitorsWelcomeMessageActivation"),
      }}
    >
      <Collapse in={formData.exhibitorsWelcomeMessageActivation}>
        <InputUi
          onChange={(e) =>
            inputHandler(e.target.value, "exhibitorsWelcomeMessage")
          }
          sx={{ m: 0 }}
          inputsx={{ borderRadius: "20px !important", fontSize: 13 }}
          value={formData.exhibitorsWelcomeMessage}
          multiline
          rows={4}
          placeholder={tvForms("notificationTextPlaceholder")}
        />
      </Collapse>
    </AddFile>,
    <AddFile
      key="visitors"
      description={tvForms("welcomeMsg")}
      sx={{ mt: "5px" }}
      switchBtn={{
        checked: formData.visitorsWelcomeMessageActivation,
        onChange: (e) =>
          inputHandler(e.target.checked, "visitorsWelcomeMessageActivation"),
      }}
    >
      <Collapse in={formData.visitorsWelcomeMessageActivation}>
        <InputUi
          onChange={(e) =>
            inputHandler(e.target.value, "visitorsWelcomeMessage")
          }
          sx={{ m: 0 }}
          inputsx={{ borderRadius: "20px !important", fontSize: 13 }}
          value={formData.visitorsWelcomeMessage}
          multiline
          rows={4}
          placeholder={tvForms("notificationTextPlaceholder")}
        />
      </Collapse>
    </AddFile>,
  ];
  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title={tv("settings")}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
        afterForm={afterForm}
      />
    </div>
  );
}

import { Typography } from "@mui/material";
import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import BoxUi from "../../components/BaseElement/BoxUi/BoxUi";
import InputUi from "../../components/BaseElement/InputUi/InputUi";
import useStyles from "./styles";
import ButtonUi from "../../components/BaseElement/ButtonUi";
import Api from "../../services/Api";
import useAuth from "../../customHooks/hookRepositories/useAuth";
import CheckboxUi from "../../components/BaseElement/CheckboxUi/CheckboxUi";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import UseCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import Icons from "../../assets/icons/Icons";
import { showModal } from "../../redux/ModalSlice";

export default function Register(props) {
  const { exhibitorsRegister, isValid } = useAuth();
  const { validation } = useSelector((state) => state.alerts);
  var classes = useStyles();
  const dispatch = useDispatch();
  const [loginValue, setLoginValue] = useState({});
  var [checkboxValue, setCheckboxValue] = useState(false);
  const tv = UseCustomTranslate("register");
  return (
    <BoxUi login>
      <Typography variant="h6" gutterBottom component="div">
        {tv("exhibitorsRegister")}
      </Typography>
      <InputUi
        id="Company Name"
        value={loginValue.companyName ? loginValue.companyName : ""}
        onChange={(e) =>
          setLoginValue({ ...loginValue, companyName: e.target.value })
        }
        placeholder={tv("companyName")}
        type="text"
        icon={<Icons.User />}
      />
      <InputUi
        id="email"
        value={loginValue.email ? loginValue.email : ""}
        onChange={(e) =>
          setLoginValue({ ...loginValue, email: e.target.value })
        }
        placeholder={tv("email")}
        type="email"
        error={!!(validation && validation.email)}
        helperText={validation && validation.email}
        icon={<Icons.Sms />}
      />
      <InputUi
        id="PhoneNumber"
        placeholder={tv("phoneNumber")}
        type="tel"
        dir={"ltr"}
        error={!!(validation && validation["phoneNumber.number"])}
        code={loginValue?.phoneNumber?.code}
        helperText={validation && validation["phoneNumber.number"]}
        value={loginValue?.phoneNumber?.number}
        onChange={(e, { code }) => {
          if (!isNaN(e.target.value)) {
            setLoginValue({
              ...loginValue,
              phoneNumber: { number: e.target.value, code },
            });
          }
        }}
      />
      <InputUi
        icon={<Icons.Key />}
        id="Password"
        placeholder={tv("password")}
        type="password"
        error={!!(validation && validation.password)}
        helperText={validation && validation.password}
        value={loginValue.password ? loginValue.password : ""}
        onChange={(e) =>
          setLoginValue({ ...loginValue, password: e.target.value })
        }
      />
      <InputUi
        icon={<Icons.Key />}
        id="confirmPassword"
        placeholder={tv("confirmPassword")}
        type="password"
        error={!!(validation && validation.confirmPassword)}
        helperText={validation && validation.confirmPassword}
        value={loginValue.confirmPassword ? loginValue.confirmPassword : ""}
        onChange={(e) =>
          setLoginValue({ ...loginValue, confirmPassword: e.target.value })
        }
      />
      <CheckboxUi
        label={
          <div>
            <Trans i18nKey="translations.register.ruleAgree">
              I agree with the
              <span
                onClick={() =>
                  dispatch(
                    showModal({
                      open: "PrivacyModal",
                      justUpload: true,
                    }),
                  )
                }
                className={classes.link}
              >
                Privacy Policy
              </span>
            </Trans>
          </div>
        }
        onChange={(e) => setCheckboxValue(e.target.checked)}
        checked={checkboxValue}
      />
      <ButtonUi
        disabled={
          !loginValue.companyName ||
          !loginValue.password ||
          !loginValue.phoneNumber ||
          !loginValue.email ||
          !checkboxValue
        }
        onClick={() => {
          if (isValid(loginValue)) {
            exhibitorsRegister({
              sourceUrl: Api.login,
              body: loginValue,
            });
          }
        }}
      >
        {tv("signUp")}
      </ButtonUi>
    </BoxUi>
  );
}

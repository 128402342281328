import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  nameCell: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: 13,
    color: "#101828",
  },
  email: {
    color: theme.palette.description.main,
    fontSize: 12,
  },
  exhibition: {
    color: theme.palette.primary.main,
  },
  dateTime: {
    color: theme.palette.description.main,
    fontSize: 13,
  },
  sort: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    marginBottom: 10,
  },
  title: {
    color: "#475467CC",
  },
  autocomplete: {
    borderRadius: "12px !important",
    backgroundColor: `${theme.palette.inputBackground.light} !important`,
    maxWidth: 200,
    "&>div>div": {
      padding: "7px 36px 7px 15px !important",
      "&>input": {
        padding: "0 !important",
      },
    },
  },
}));

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  pagination: {
    margin: "auto",
    width: "fit-content",
  },
  paginationRoot: {
    "& button": {
      backgroundColor: theme.palette.tableBgColor.main,
      minWidth: 40,
      height: 40,
    },
  },
  rtl:{
    "& .MuiPaginationItem-previousNext":{
      transform:'rotate(180deg)'
    }
  }
}));

import { IconButton } from "@mui/material";
import React from "react";
import Icons from "../../../../assets/icons/Icons";
// import useStyles from "../styles";
export default function DeletBtn({ onDelete, id }) {
  // var classes = useStyles();
  const handleClickOpen = (e) => {
    e.stopPropagation();
    onDelete(id);
  };
  return (
    <IconButton onClick={handleClickOpen} size="small">
      <Icons.Trash />
    </IconButton>
  );
}

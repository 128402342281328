const isEnvDev = process.env.NODE_ENV === "development";
// export const MainUrl = isEnvDev
//   ? "http://193.39.9.176:8000"
//   : "https://api.hitex.events";
// export const BaseUrl = isEnvDev
//   ? "http://193.39.9.176:8000/api/v1"
//   : "https://api.hitex.events/api/v1";
export const MainUrl = "https://api.hitex.events";
export const BaseUrl = "https://api.hitex.events/api/v1";

const Api = {
  //Global
  uploadFile: "uploadFile",
  deleteFile: "deleteFile",
  update: "update",
  getList: "getList",
  getById: "getById",
  deleteItem: "deleteItem",
  deleteItemChild: "deleteItemChild",
  addItem: "addItem",
  addItemChild: "addItemChild",
  editItem: "editItem",
  //auth
  login: "login",
  register: {
    exhibitors: "exhibitorsRegister",
  },
};

export default Api;

export const ResponseStatus = {
  Success: "Success",
  Failed: "Failed",
};

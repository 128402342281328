import { Menu, MenuItem, Typography, Box, ListItemIcon } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useStyles from "./styles";
import HeaderBtn from "../HeaderBtn/HeaderBtn";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../../../redux/AuthSlice";
// import DarkModeBtn from "../DarkModeBtn/DarkModeBtn";
import Icons from "../../../../assets/icons/Icons";
import { Link } from "react-router-dom";
import { showModal } from "../../../../redux/ModalSlice";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";

export default function UserMenu(props) {
  const tv = useCustomTranslate("panelMenu");
  var classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const { info: { username, extraInfo, name, family } = {} } = useSelector(
    (s) => s.auth,
  );
  const { UserMenu } = useSelector((s) => s.roles);
  const closeHandler = () => {
    setOpen(null);
  };
  const openHandler = (e) => {
    setOpen(e.currentTarget);
  };
  const menuHandler = (type) => {
    switch (type) {
      case "Logout":
        dispatch(signOut());
        break;
      case "HowToUse":
        dispatch(showModal({ open: "TipsModal" }));
        break;
      default:
        break;
    }
    closeHandler();
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <HeaderBtn
        className={classes.menuBtn}
        icon={<Icons.User />}
        onClick={openHandler}
      >
        <div className={classes.nameWrapper}>
          {extraInfo && extraInfo.companyName
            ? extraInfo.companyName
            : name
            ? name + " " + family
            : username}
          <ArrowDropDownIcon className={classes.icon} />
        </div>
      </HeaderBtn>
      <Menu
        open={Boolean(open)}
        anchorEl={open}
        onClose={closeHandler}
        classes={{
          paper: classes.menu,
        }}
      >
        {UserMenu &&
          UserMenu.map(({ title, icon, link, id }) => (
            <Link
              to={link ? link : "/"}
              key={title}
              onClick={(e) => !link && e.preventDefault()}
            >
              <MenuItem onClick={() => menuHandler(id)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <Typography textAlign="center">{tv(title)}</Typography>
              </MenuItem>
            </Link>
          ))}
        {/* <DarkModeBtn/> */}
      </Menu>
    </Box>
  );
}

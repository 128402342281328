import React, { useState } from "react";
import { Link } from "react-router-dom";
// styles
import useStyles from "./styles";
// logo
import Icons from "../../assets/icons/Icons";
// context
import useAuth from "../../customHooks/hookRepositories/useAuth";
import Logo from "../../components/Logo/Logo";
import InputUi from "../../components/BaseElement/InputUi";
import ButtonUi from "../../components/BaseElement/ButtonUi";
import BoxUi from "../../components/BaseElement/BoxUi";
import CheckboxUi from "../../components/BaseElement/CheckboxUi";
import RouteEnum from "../../navigation/RouteEnum";
import useCustomTranslate from "../../customHooks/hookRepositories/useCustomTranslate";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

function Login(props) {
  var classes = useStyles();
  // global
  //var userDispatch = useUserDispatch();
  const { login, errorHandler } = useAuth();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { validation } = useSelector((state) => state.alerts);
  // local
  //   var [isLoading, setIsLoading] = useState(false);
  //   var [error, setError] = useState(null);
  //   var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [checkboxValue, setCheckboxValue] = useState(true);
  const tv = useCustomTranslate("login");
  const submitHandler = () => {
    // if (isValid({ email: loginValue })) {
    login({
      body: {
        adminLogin: loginValue,
        adminPassword: passwordValue,
      },
      rMe: checkboxValue,
    });
    setRefreshReCaptcha((r) => !r);
    // }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler();
    }
  };
  // const handleVerify = (e) => {
  // };
  return (
    <>
      {/* <Typography variant="h5">پنل سلام ریسپانسیو</Typography> */}
      <BoxUi className={classes.form} login>
        <div className={classes.logoWrapper}>
          <Logo />
        </div>
        <InputUi
          id="email"
          value={loginValue}
          onChange={(e) => setLoginValue(e.target.value)}
          placeholder={tv("userName")}
          type="email"
          error={!!(validation && validation.email)}
          helperText={validation && validation.email}
          icon={<Icons.User />}
        />
        <InputUi
          id="password"
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
          placeholder={tv("password")}
          type="password"
          icon={<Icons.Key />}
          inputProps={{
            onKeyPress: handleKeyPress,
          }}
        />
        <GoogleReCaptcha
          // onVerify={handleVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
        <CheckboxUi
          label={tv("rememberMe")}
          onChange={(e) => setCheckboxValue(e.target.checked)}
          checked={checkboxValue}
        />
        <div className={classes.formButtons}>
          <ButtonUi
            disabled={loginValue.length === 0 || passwordValue.length === 0}
            onClick={submitHandler}
          >
            <Icons.Login className={classes.loginIcon} /> {tv("login")}
          </ButtonUi>
          {/* )} */}
        </div>
        <div className={classes.forget}>
          {tv("forgetPassword")}?{" "}
          <Link
            to={RouteEnum.ForgotPassword}
            onClick={() => errorHandler()}
            className={classes.forgotLink}
          >
            {tv("click")}
          </Link>
        </div>
      </BoxUi>
      <BoxUi className={classes.boothRegisterWrapper} login>
        <div className={classes.boothRegister}>
          <div>{tv("registerBoothDescription")}</div>
          <div>
            <Link to={RouteEnum.Register} className={classes.registerBtnLink}>
              <Button
                sx={{
                  margin: "0 !important",
                }}
                className={classes.registerBtn}
                onClick={() => errorHandler()}
                variant="contained"
                color="primary"
                fullWidth
              >
                {tv("register")}
              </Button>
            </Link>
          </div>
        </div>
      </BoxUi>
    </>
  );
}

export default Login;

import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
    chatWrapper: {
        display: "flex",
        flexDirection: "row",
        gap: 15,

        [theme.breakpoints.down("lg")]: {
            flexDirection: 'column'
        }
    },
    chatSidebar: {
        flex: 1,
        position: "sticky",
        top: 120,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 143px)",
        padding: 0,
        boxShadow: "0px 4px 10px -1px rgba(98, 95, 86, 0.05)",

        [theme.breakpoints.down("lg")]: {
            position: 'relative',
            top: 0,
        }
    },
    activeSideBar: {
        [theme.breakpoints.down("lg")]: {
            display: 'none'
        }
    },
    chatBody: {
        [theme.breakpoints.down("lg")]: {
            maxWidth:'100%',
            display: 'none'
        }
    },
    activeChatBody: {
        [theme.breakpoints.down("lg")]: {
            display: 'flex'
        }
    },
    section: {
        padding: "0 20px",
        borderBottom: `1px solid ${theme.palette.description.main}26`,
        display: "flex",
        flexDirection: "column",
    },
    search: {
        margin: "15px 0px !important",
        backgroundColor: theme.palette.inputBackground.main,
        flexDirection: "row !important",
        "& > div": {
            flexGrow: 1,
        },
        "& input": {
            backgroundColor: theme.palette.inputBackground.main,
        },
    },
    breadcrumbs: {
        padding: "10px 0px",
        color: theme.palette.description.main,
        display: "flex",
        gap: 8,
    },
}));

import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import useStyles from "./styles";
import imageUplaodImg from "../../../assets/icons/image-uplaod.png";
import fileImageUpload from "../../../assets/images/fileImageUpload.png";
import { UploadFileType } from "../../../configs/FormEnum";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";
import useAuth from "../../../customHooks/hookRepositories/useAuth";

export default function UploadUi(props) {
  var classes = useStyles();
  const { onDrop: onDropProp, fileManager, type } = props;
  const [types, setTypes] = useState({});
  const [maxSize, setMaxSize] = useState({});
  const [supportText, setSupportText] = useState({});
  const tv = useCustomTranslate("forms");
  const tvError = useCustomTranslate("error");
  const maxSizes = {
    video: 104857600,
    //100mg
    image: 10485760,
    file: 10485760,
  };
  useEffect(() => {
    let types = {};
    let maxSize;
    let supportText = "JPG, PNG, PDF, MP3, MP4";
    switch (type) {
      case UploadFileType.image:
        types["image/png"] = [".png", ".jpg"];
        supportText = "PNG, JPG";
        maxSize = maxSizes.image;
        break;
      case UploadFileType.video:
        types["video/mp4"] = [".mp4"];
        types["video/mpeg"] = [".mpeg"];
        types["video/webm"] = [".webm"];
        supportText = "MP4";
        maxSize = maxSizes.video;
        break;
      case UploadFileType.audio:
        types["audio/mpeg"] = [".mp3", ".ogg", ".wav"];
        supportText = "MP3";
        maxSize = maxSizes.video;
        break;
      case UploadFileType.file:
        types["application/pdf"] = [".pdf"];
        supportText = "PDF";
        // maxSize = maxSizes.file;
        break;
      default:
        break;
    }
    setMaxSize(maxSize);
    setTypes(types);
    setSupportText(supportText);
  }, [type, maxSizes.image, maxSizes.video]);
  const { errorHandler } = useAuth();
  const { getRootProps, getInputProps } = useDropzone({
    accept: types && types,
    onDrop: (files, fileRejections) => {
      fileRejections[0]?.errors.forEach(({ code }) => {
        errorHandler({ message: tvError(code) });
      });
      files?.forEach((x) => {
        let error;
        switch (x.type) {
          case "video/mp4":
          case "video/mpeg":
          case "video/webm":
          case "audio/mpeg":
          case "audio/ogg":
          case "audio/wav":
            if (x.size >= maxSizes.video) {
              error = "file-too-large";
            }
            break;
          case "image/png":
          case "image/jpeg":
          case "application/pdf":
            if (x.size >= maxSizes.image) {
              error = "file-too-large";
            }
            const path = x.path.split(".");
            if (path[path.length - 1] === "jpeg") {
              error = "file-invalid-type";
            }
            break;
          default:
            error = "file-invalid-type";
            break;
        }
        if (error) {
          errorHandler({ message: tvError(error) });
          return;
        }
        onDropProp(files);
      });
    },
    maxSize: maxSize && maxSize,
  });

  // style = useMemo(() => ({
  //   ...classes.area,
  //   ...(isFocused ? classes.focusedStyle : {}),
  //   ...(isDragAccept ? classes.acceptStyle : {}),
  //   ...(isDragReject ? classes.rejectStyle : {})
  // }), [
  //   isFocused,
  //   isDragAccept,
  //   isDragReject
  // ]);

  return (
    <div
      className={fileManager ? classes.fileArea : classes.area}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <img
        alt=""
        src={fileManager ? fileImageUpload : imageUplaodImg}
        className={classes.areaLogo}
      />
      {fileManager ? (
        <>
          <p className={classes.fileAreaTitle}>{tv("dragFile")}</p>
          <p className={classes.fileAreaPlaceholder}>
            {`(${tv("supportedFileTypes")}: ${supportText})`}
          </p>
        </>
      ) : (
        <>
          <p className={classes.areaTitle}>{tv("dragFile")}</p>
          <p className={classes.areaPlaceholder}>
            ( {tv("bestSize")} w:800px, h:600px )
          </p>
        </>
      )}
    </div>
  );
}

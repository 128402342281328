import axios from "axios";
import { ResponseStatus } from "../services/Api";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../redux/LoadingSlice";

const useDelete = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((s) => s.auth);
  async function apiDelete(url, body = {}, jwt = "", header = {}) {
    dispatch(
      showLoader({
        visible: true,
        text: "",
      }),
    );
    return new Promise((res) => {
      axios
        .delete(url, {
          headers: {
            Authorization: token,
          },
          data: body,
          timeout: 99999999999999999999,
        })
        .then(function (response) {
          dispatch(
            showLoader({
              visible: false,
              text: "",
            }),
          );
          res(
            Object.assign(
              { status: ResponseStatus.Success },
              {
                data: response?.data?.data,
              },
            ),
          );
        })
        .catch(function (error) {
          dispatch(
            showLoader({
              visible: false,
              text: "",
            }),
          );
          res(
            Object.assign(
              { status: ResponseStatus.Failed },
              error.response?.data ?? { message: error.message },
            ),
          );
        });
    });
  }

  return apiDelete;
};
export default useDelete;

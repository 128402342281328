import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../redux/AlertsSlice";
import { BaseUrl, ResponseStatus } from "../../services/Api";
import usePost from "../usePost";
import { showModal } from "../../redux/ModalSlice";
import useGet from "../useGet";
import useDelete from "../useDelete";
import Limits from "../../configs/Limits";
import { setFile } from "../../redux/SelectedFileSlice";
import useAuth from "./useAuth";
import usePut from "../usePut";

const useFiles = () => {
  const dispatch = useDispatch();
  const post = usePost();
  const get = useGet();
  const put = usePut();
  const del = useDelete();

  const { errorHandler } = useAuth();
  const { type: fileType, id } = useSelector((state) => state.modal);

  const addOne = async ({ type, body }) => {
    try {
      const data = new FormData();
      data.append("description", body.description);
      data.append("file", body.file);
      let res = await post(`${BaseUrl}/admins/${type}`, data);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          setFile({
            url: res.data.filePath,
            type: fileType,
            id,
          }),
        );
        dispatch(
          showAlert({
            notify: { message: `${type} added`, type: "success" },
          }),
        );
        dispatch(showModal({ open: false }));
      } else {
        // errorHandler(res);
      }
      return res;
    } catch (e) {
      console.log("error", e);
    }
  };

  const deleteOne = async (id) => {
    try {
      let res = await del(`${BaseUrl}/admins/files/${id}`);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: {
              message: `file deleted successfully`,
              type: "success",
            },
          }),
        );
      } else {
        errorHandler(res);
      }
      return res;
    } catch (e) {
      console.log("error", e);
    }
  };

  const update = async ({ id, body }) => {
    try {
      const data = new FormData();
      data.append("description", body.description);
      data.append("file", body.file);
      let res = await put(`${BaseUrl}/admins/files/${id}`, data);
      if (res.status === ResponseStatus.Success) {
        dispatch(
          showAlert({
            notify: {
              message: `file edited successfully`,
              type: "success",
            },
          }),
        );
      } else {
        errorHandler(res);
      }
      return res;
    } catch (e) {
      console.log("error", e);
    }
  };

  const filesUrl = ({
    page = 1,
    limit = Limits.tabels.files.main,
    uploaded_by = "",
    file_type = "",
    sort = "",
    q = "",
  }) => {
    let url = `${BaseUrl}/admins/files?page=${page}&limit=${limit}`;
    if (uploaded_by.length > 0) {
      url += "&uploaded_by=" + uploaded_by;
    }
    if (file_type.length > 0) {
      url += "&file_type=" + file_type;
    }
    if (sort.length > 0) {
      url += "&sort=" + sort;
    }
    if (q.length > 0) {
      url += "&q=" + q;
    }
    return url;
  };
  const getAll = async (query) => {
    try {
      let res = await get(filesUrl(query));
      if (res.status === ResponseStatus.Success) {
        return res.data;
      } else {
        // errorHandler(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return { addOne, getAll, deleteOne, update };
};

export default useFiles;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  describe: {
    color: "#98A2B3",
    marginBottom: "20px !important",
  },
  CheckIcon: {
    margin: "0 5px",
  },
}));

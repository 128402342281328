import React from "react";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import Icons from "../../../../assets/icons/Icons";
import { Button } from "@mui/material";

export default function AddUserBtn({ name, onClick, link, icon, className }) {
  var classes = useStyles();
  return (
    <Link to={link ? link : "/"} onClick={(e) => !link && e.preventDefault()}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        className={[classes.addUser, className].join(" ")}
        onClick={onClick}
      >
        {icon ? icon : <Icons.Add />}{" "}
        <div className={classes.title}> {name} </div>
      </Button>
    </Link>
  );
}

import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
    root:{
        backgroundColor:'#fff',
        borderRadius:12,
        marginBottom:15,
        padding:4,
        [theme.breakpoints.down("lg")]: {
            "& .MuiTabs-flexContainer": {
                justifyContent: 'start',
                overflow:'visible!important',
                width:'100px'
            },
            "& .MuiTabs-scroller": {
                overflow:'scroll!important',
            }
        },
    },
    tabBtn:{
        minWidth:'0!important',
    }
}));

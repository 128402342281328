import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./configs/i18n";
import { create } from "jss";
import { StylesProvider, jssPreset } from "@mui/styles";
import { Provider } from "react-redux";
import rtl from "jss-rtl";
import { store } from "./redux/store/configureStore";
import RecaptchaUi from "./components/BaseElement/RecaptchaUi/RecaptchaUi";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
ReactDOM.render(
  <Provider store={store}>
    <StylesProvider jss={jss}>
      <RecaptchaUi>
        <App />
      </RecaptchaUi>
    </StylesProvider>
  </Provider>,
  document.getElementById("root"),
);
serviceWorker.unregister();

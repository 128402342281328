import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.tableBgColor.main,
    flexGrow: 1,
    borderRadius: 20,
    height: 400,
    position: "relative",
    gap: 10,
    maxWidth: 247,
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%!important",
    },
  },
  overImage: {
    height: 150,
    // visibility: "hidden",
    zIndex: 10,
    position: "relative",
    "&:hover + div": {
      height: 400,
      borderRadius: 20,
    },
  },
  logo: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    width: "100%",
    flexGrow: 1,
    border: "1px solid #98A2B366",
    borderRadius: "20px 20px 0 0",
    overflow: "hidden",
    cursor: "pointer",
    height: 150,
    zIndex: 5,
    padding: 15,
    backgroundColor: theme.palette.boxUiBackground.main,
    transition: "all .3s ease-out",
    "& > img": {
      margin: "auto",
      width: "100%",
      height: 370,
      borderRadius: 20,
    },
  },
  active: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
  },
  cardBody: {
    display: "flex",
    gap: 15,
    padding: "0 20px 15px",
    position: "relative",
    flexDirection: "column",
  },
  describeWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: 5,
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
    },
  },
  describe: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      gap: 5,
      justifyContent: "start",
    },
  },
  describeTitle: {
    fontSize: 14,
    color: theme.palette.description.main,
  },
  activeDot: {
    borderRadius: "100%",
    backgroundColor: theme.palette.success.main,
    width: 10,
    height: 10,
  },
  notActiveDot: {
    borderRadius: "100%",
    backgroundColor: theme.palette.error.main,
    width: 10,
    height: 10,
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 16,
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row-reverse",
      justifyContent: "start",
      gap: 10,
      "& .MuiButton-textDescription": {
        paddingRight: "0!important",
        paddingLeft: "0!important",
      },
    },
  },
  btnLink: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  disabledBtn: {
    backgroundColor: `${theme.palette.description.main} !important`,
    color: `${theme.palette.description.contrastText} !important`,
  },
  editBtn: {
    position: "absolute",
    top: -5,
    right: 0,
    left: 0,
    padding: "0 5px",
    width: "100%",
    justifyContent: "start",
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "end",
    },
  },
}));

import defaultTheme from "./default";
import darkTheme from "./dark";
import Kalameh from "../assets/fonts/KalamehWeb-Regular.woff2";
import Poppins from "../assets/fonts/poppins-regular.woff";

import { createTheme } from "@mui/material/styles";
// const VazirFont = {
//   fontFamily: "Vazir",
//   fontStyle: "normal",
//   src: `url(${Vazir})`,
// };
const overrides = {
  typography: {
    fontFamily: ["Poppins", "Kalameh"].join(","),
    textAlign: "start !important",
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 480,
      md: 767,
      lg: 991,
      xl: 1200,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 10,
          gap: 4,
        },
        startIcon: {
          margin: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "transparent",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textAlign: "start !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Kalameh';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${Kalameh}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }

      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src:  url(${Poppins}) format('woff');
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      body::-webkit-scrollbar {
        display: none;
      },
    `,
    },
  },
};

const themes = {
  default: createTheme({ ...defaultTheme, ...overrides }),
  dark: createTheme({ ...darkTheme, ...overrides }),
};

export default themes;

// import React from "react";

import axios from "axios";
import { ResponseStatus } from "../services/Api";
import { setProgressBar } from "../redux/ProgressBarSlice";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../redux/LoadingSlice";
const usePost = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((s) => s.auth);

  async function fetchPost(
    url,
    body = {},
    jwt = "",
    header = {},
    isFile = false,
  ) {
    if (!isFile) {
      dispatch(
        showLoader({
          visible: true,
          text: "",
        }),
      );
    }
    return new Promise((res) => {
      axios
        .post(url, body, {
          headers: Object.assign(
            token?.length > 0 ? { authorization: token } : {},
            header,
          ),
          onUploadProgress: (progressEvent) =>
            dispatch(
              setProgressBar(
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
              ),
            ),
          timeout: 99999999999999999999,
        })
        .then((response) => {
          dispatch(
            showLoader({
              visible: false,
              text: "",
            }),
          );

          dispatch(setProgressBar(0));
          res(
            Object.assign(
              { status: ResponseStatus.Success },
              { data: response?.data?.data },
            ),
          );
          res(response);
        })
        .catch((error) => {
          dispatch(
            showLoader({
              visible: false,
              text: "",
            }),
          );
          dispatch(setProgressBar(0));

          res(
            Object.assign(
              { status: ResponseStatus.Failed },
              error.response?.data ?? { message: error.message },
            ),
          );
        });
    });
  }

  return fetchPost;
};
export default usePost;

import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import useStyles from "./styles";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import DeletBtn from "./components/DeletBtn";
import HeaderBtn from "../../Layout/components/HeaderBtn/HeaderBtn";
import Icons from "../../../assets/icons/Icons";
import PaginationUi from "../PaginationUi/PaginationUi";
import { Link } from "react-router-dom";
import RouteEnum from "../../../navigation/RouteEnum";
import SearchUi from "../searchUi/searchUi";
import DeleteModul from "../../Modals/DeleteModul/DeleteModul";
import { useTranslation } from "react-i18next";

export default function TableUi({
  onPageChange,
  rows,
  columns: oldColumns,
  onDelete,
  onChange,
  disableEdit,
}) {
  var classes = useStyles();
  const { paginator } = useSelector((s) => s.table);
  const { currentPage, pageCount } = paginator;
  const [selectionModel, setSelectionModel] = useState([]);
  const isSelected = selectionModel.length > 0;
  const { deleteModul } = DeleteModul();
  const { t } = useTranslation();
  const isRtl = t("dir") === "rtl";
  const deleteHandler = (id) => {
    deleteModul(() => {
      onDelete(id);
    });
  };
  const columns = [
    ...oldColumns.map((x) => ({ ...x, sortable: false })),
    {
      field: "action",
      renderHeader: () => {
        const searchHandler = (s) => {
          setSelectionModel([]);
          onChange && onChange(s);
        };
        return (
          <SearchUi
            className={isRtl ? classes.searchRtl : classes.search}
            onChange={searchHandler}
          />
        );
      },
      sortable: false,
      flex: 2.2,
      renderCell: (params) => {
        return (
          <div className={classes.cellBtnWrapper}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.cellBtn}
            >
              {!disableEdit && (
                <Link to={RouteEnum.Edit(params.id)}>
                  <IconButton size="small">
                    <Icons.Edit />
                  </IconButton>
                </Link>
              )}
              <DeletBtn {...params} onDelete={deleteHandler} />
            </Grid>
          </div>
        );
      },
    },
  ];

  const onSortModelChange = (model) => {};
  return (
    <div>
      <div className={classes.tabelWrapper}>
        {isSelected && (
          <div className={`${classes.selectedMenu} ${isRtl ? classes.selectedMenuRtl :""}`}>
            <HeaderBtn
              onClick={() => deleteHandler(selectionModel)}
              icon={<Icons.Trash />}
              className={classes.selectedDelete}
            >
              Delete
            </HeaderBtn>
            <div className={classes.selectedNum}>
              {selectionModel.length} items selected
            </div>
          </div>
        )}
        <DataGrid
          rows={rows ? rows : []}
          disableSelectionOnClick
          disableColumnMenu
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          page={currentPage - 1}
          checkboxSelection
          onSortModelChange={onSortModelChange}
          // paginationMode="server"
          pagination
          classes={{
            root: classes.root,
            footerContainer: classes.footerContainer,
            columnHeaderTitleContainer:
              isSelected && classes.columnHeaderTitleContainer,
            columnHeaderTitleContainerContent: classes.content,
            columnHeaderTitle: isSelected && classes.columnHeaderTitle,
            columnHeaders: isSelected
              ? classes.headerDisable
              : classes.columnHeaders,
            columnHeader: classes.columnHeader,
            virtualScroller: classes.virtualScroller,
            row: classes.row,
            cell: classes.cell,
            columnSeparator: classes.columnSeparator,
          }}
          hideFooterPagination
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
        />
      </div>
      {pageCount > 1 && (
        <div className={classes.paginationWrapper}>
          <PaginationUi
            count={pageCount}
            page={currentPage ? currentPage : 1}
            onChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

import React from "react";
import { MainUrl } from "../../../services/Api";
import useStyles from "./styles";
import useDate from "../../../customHooks/hookRepositories/useDate";

export default function EventsUi({ name, speakerName, date, banner, time }) {
  var classes = useStyles();
  const getDate  = useDate();
  return (
    <div className={classes.eventsWrapper}>
      <div className={classes.eventsImage}>
        <img
          src={banner ? `${MainUrl}${banner}` : "/assets/image/no_photo.png"}
          alt=""
        />
      </div>
      <div className={classes.eventsBody}>
        <div className={classes.eventsTitle}>{name}</div>
        <div className={classes.eventsName}>{speakerName}</div>
        <div className={classes.eventsDate}>{`${getDate(
          date + " " + time,
        ).format("MMM DD - HH:mm")}`}</div>
      </div>
    </div>
  );
}

import React from "react";
import { Route, Routes } from "react-router";
import NotFound from "../NotFound/NotFound";
import AddVisitor from "./components/AddVisitor/AddVisitor";
import EditVisitor from "./components/EditVisitor/EditVisitor";
import VisitorList from "./components/VisitorList/VisitorList";

export default function Visitors(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<VisitorList />} />
      <Route path={"add"} element={<AddVisitor />} />
      <Route path={"edit/:id"} element={<EditVisitor />} />
    </Routes>
  );
}

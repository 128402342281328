import React from "react";
// import useStyles from "./styles";
import ExhibitionsList from "./components/ExhibitionsList/ExhibitionsList";
import { Route, Routes } from "react-router";
import ExhibitionsAdd from "./components/ExhibitionsAdd/ExhibitionsAdd";
import NotFound from "../NotFound/NotFound";

export default function Exhibitions(props) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={"/"} element={<ExhibitionsList />} />
      <Route path={"add"} element={<ExhibitionsAdd />} />
      <Route path={"edit/:id"} element={<ExhibitionsAdd />} />
    </Routes>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddForm from "../../../../components/AddForm/AddForm";
import { setToolbar } from "../../../../redux/ToolbarSlice";
// import useStyles from "./styles";
import moment from "moment";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";
import useUsers from "../../../../customHooks/hookRepositories/useUsers";
import useGetConstant from "../../../../customHooks/hookRepositories/useGetConstant";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import RouteEnum from "../../../../navigation/RouteEnum";

export default function AddOrganizer(props) {
  const tv = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");
  // var classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Organizers",
        title: { name: tv("new") + " " + tv("organizer") },
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("users") },
          { name: tv("organizer"), href: RouteEnum.user.Organizers },
          { name: tv("new") + " " + tv("organizer") },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  const today = moment(new Date()).format("YYYY-MM-DD");
  const [formData, setFormData] = useState({
    expireTime: "23:59:00",
    expireDate: today,
    exhibitionId: "None",
  });
  const { getExhibitions } = useGetConstant();
  useEffect(() => {
    getExhibitions();
    // eslint-disable-next-line
  }, []);
  const { exhibitions } = useSelector((s) => s.constants);
  const inputHandler = (value, type) => {
    const newValue = {};
    newValue[type] = value;
    setFormData({ ...formData, ...newValue });
  };
  const required = true;
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            width: [2, 4],
            valueId: "name",
            title: tv("firstName"),
            required,
            input: {
              placeholder: tv("firstNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "family",
            title: tv("lastName"),
            required,
            input: {
              placeholder: tv("lastNamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "username",
            title: tv("username"),
            required,
            input: {
              placeholder: tv("usernamePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "email",
            title: tv("email"),
            required,
            input: {
              placeholder: tv("emailPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "phoneNumber",
            title: tv("phoneNumber"),
            input: {
              dir: "ltr",
              type: "tel",
              placeholder: tv("phoneNumberPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "websiteUrl",
            title: tv("website"),
            input: {
              autoComplete: "new-password",
              placeholder: tv("websitePlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "password",
            title: tv("password"),
            required,
            input: {
              autoComplete: "new-password",
              type: "password",
              placeholder: tv("passwordPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "confirmPassword",
            title: tv("confirmPassword"),
            required,
            input: {
              autoComplete: "new-password",
              type: "password",
              placeholder: tv("confirmPasswordPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "exhibitionId",
            title: tv("exhibition"),
            required,
            select: {
              children: [
                { name: tv("selectExhibition"), value: "None" },
              ].concat(exhibitions.filter((x) => !x.inUse)),
            },
          },
          {
            width: [2, 4],
            valueId: "expireDate",
            title: tv("expire"),
            required,
            datePicker: {
              placeholder: "2022/05/01",
            },
          },
        ],
      },
    },
  ];
  const { isValid, getValidUrl } = useAuth();
  const { addUser } = useUsers();
  const submitHandler = (error) => {
    if (
      !isValid({
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        date: { value: formData.expireDate, valueId: "expireDate" },
        username: formData.username,
      }) ||
      error
    ) {
      return;
    }
    addUser({
      type: "Organizers",
      body: {
        ...formData,
        exhibitionId:
          formData.exhibitionId !== "None" ? formData.exhibitionId : undefined,
        websiteUrl: getValidUrl(formData.websiteUrl),
      },
    });
  };
  return (
    <div>
      <AddForm
        form={form}
        title={tv("organizer")}
        value={formData}
        onInputChange={inputHandler}
        onSubmit={submitHandler}
      />
    </div>
  );
}

const primary = "#755EFC";
// const secondary = "#FF5C93";
// const warning = "#FFC260";
// const success = "#3CD4A0";
// const info = "#9013FE";

const defaultTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: primary,
      light: primary,
      dark: primary,
    },
    // secondary: {
    //   main: '#000',
    //   light: '#000',
    //   dark: '#000'
    // },
    warning: {
      main: "#000",
      light: "#000",
      dark: "#000",
    },
    success: {
      main: "#000",
      light: "#000",
      dark: "#000",
    },
    info: {
      main: "#000",
      light: "#000",
      dark: "#000",
    },
    background: {
      main: "#121212",
      light: "#121212",
      dark: "#121212",
    },
    boxUiBackground: {
      main: "#383737",
    },
    inputColor: {
      main: "#000",
      light: "#000",
      dark: "#fff",
    },
    inputBackground: {
      main: "#F2F4F7",
      light: "#F2F4F7",
      dark: "#F2F4F7",
    },
    description: {
      main: "#98A2B3",
      light: "#F2F4F7",
      dark: "#F2F4F7",
    },
    buttonColor: {
      main: "#fff",
      light: "#fff",
      dark: "#343434",
    },
    headerBtnBackground: {
      main: "#282828",
      light: "#282828",
      dark: "#282828",
    },
    headerBtnColor: {
      main: "#000",
      light: "#000",
      dark: "#fff",
    },
    pageTitle: {
      main: "#344054",
      light: "#344054",
      dark: "#fff",
    },
    headerLine: {
      main: "#98A2B3",
      light: "#98A2B3",
      dark: "#fff",
    },
    tableBgColor: {
      main: "#fff",
      light: "#fff",
      dark: "#343434",
    },
    tableColor: {
      main: "#000",
      light: "#000",
      dark: "#fff",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 480,
      md: 767,
      lg: 991,
      xl: 1200,
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "3rem",
    },
    fontFamily: "Vazir",
  },
};

export default defaultTheme;

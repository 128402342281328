import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import AddForm from "../../../../components/AddForm/AddForm";
import useAuth from "../../../../customHooks/hookRepositories/useAuth";
import useUsers from "../../../../customHooks/hookRepositories/useUsers";
import { showAlert } from "../../../../redux/AlertsSlice";
import { setToolbar } from "../../../../redux/ToolbarSlice";
import useCustomTranslate from "../../../../customHooks/hookRepositories/useCustomTranslate";
import RouteEnum from "../../../../navigation/RouteEnum";
// import useStyles from "./styles";

export default function EditVisitor(props) {
  // var classes = useStyles();
  const dispatch = useDispatch();
  const tv = useCustomTranslate("forms");
  const tvCrumb = useCustomTranslate("breadcrumb");

  useEffect(() => {
    dispatch(
      setToolbar({
        id: "Visitors",
        title: { name: `${tv("edit")} ${tv("visitor")}` },
        breadcrumbs: [
          { name: tvCrumb("home"), href: "/" },
          { name: tvCrumb("users") },
          { name: tv("visitor"), href: RouteEnum.user.Visitors },
          { name: `${tv("edit")} ${tv("visitor")}` },
        ],
      }),
    );
    // eslint-disable-next-line
  }, [dispatch, tv]);
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const { getOne, updateOne } = useUsers();

  useEffect(() => {
    getOne({ type: "visitors", id }).then(
      ({ fullName, username, email, phoneNumber }) => {
        setFormData({
          fullName,
          username,
          email,
          phoneNumber,
        });
      },
    );
    toast.dismiss();
    dispatch(showAlert({}));
    // eslint-disable-next-line
  }, [id]);
  const inputHandler = (value, type) => {
    const newValue = {};
    newValue[type] = value;
    setFormData({ ...formData, ...newValue });
  };
  const { isValid } = useAuth();
  const submitHandler = (error) => {
    if (
      !isValid({
        email: formData.email,
        password: formData.password && formData.password,
        confirmPassword: formData.password && formData.confirmPassword,
      }) ||
      error
    ) {
      return;
    }

    updateOne({ type: "visitors", id, body: formData });
  };
  const form = [
    {
      col: {
        direction: "row",
        width: 12,
        fields: [
          {
            required: true,
            width: [2, 4],
            valueId: "fullName",
            title: tv("fullName"),
            input: { placeholder: tv("fullNamePlaceholder") },
          },
          {
            required: true,
            width: [2, 4],
            valueId: "username",
            title: tv("username"),
            input: { placeholder: tv("usernamePlaceholder") },
          },
          {
            required: true,
            width: [2, 4],
            valueId: "email",
            title: tv("email"),
            input: { placeholder: tv("emailPlaceholder") },
          },
          {
            required: true,
            width: [2, 4],
            valueId: "phoneNumber",
            title: tv("phoneNumber"),
            input: {
              dir:'ltr',
              placeholder: tv("phoneNumberPlaceholder")
            },
          },
          {
            width: [2, 4],
            valueId: "password",
            title: tv("password"),
            input: {
              type: "password",
              placeholder: tv("passwordPlaceholder"),
            },
          },
          {
            width: [2, 4],
            valueId: "confirmPassword",
            title: tv("confirmPassword"),
            input: {
              type: "password",
              placeholder: tv("confirmPasswordPlaceholder"),
            },
          },
        ],
      },
    },
  ];
  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title={tv("visitor")}
        onSubmit={submitHandler}
        onInputChange={inputHandler}
      />
    </div>
  );
}

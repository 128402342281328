import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import useCustomTranslate from "../../../../../../../../customHooks/hookRepositories/useCustomTranslate";
import useStyles from "./styles";

export default function LocationCard(props) {
  const {
    boothDesign,
    isBought,
    isReserved,
    isActive,
    name,
    price,
    _id,
    big,
    onClick,
    currency,
  } = props;
  const theme = useTheme();
  const isBoothCreated = boothDesign._id;
  const isReady = isReserved || isBought || !isActive;
  const tv = useCustomTranslate("pages");
  const classes = useStyles();
  const clickHandler = () => {
    !isReady && isBoothCreated && onClick(_id);
  };
  return (
    <Box
      onClick={clickHandler}
      sx={{
        backgroundColor: isBoothCreated
          ? isReady
            ? theme.palette.addBoothBtnColor.main
            : theme.palette.primary.main
          : theme.palette.background.main,
        cursor: !isReady && isBoothCreated && "pointer",
        color: isBoothCreated ? "#fff" : "#00000070",
      }}
      className={`${classes.cardWrapper} ${big ? classes.bigCard : ""}`}
    >
      <div className={classes.cardTitle}>
        {tv("booth")} {boothDesign.index}
      </div>
      <Box className={classes.cardDescription}>
        {isBoothCreated &&
          (isReady ? (
            name
          ) : (
            <>
              {currency !== "IRR" && (
                <span className={classes.currencySymbol}>
                  {getSymbolFromCurrency(currency)}
                </span>
              )}{" "}
              <span className={classes.price}>
                {price ? price.toLocaleString() : tv("free")}
              </span>
              <span className={classes.mobilePrice}>
                {price ? nFormatter(price) : tv("free")}
              </span>
            </>
          ))}
      </Box>
      {!isReady && currency === "IRR" && (
        <Box className={classes.irrCurrencySymbol}>
          {getSymbolFromCurrency(currency)}
        </Box>
      )}
    </Box>
  );
}

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? `${(num / item.value).toFixed(digits).replace(rx, "$1")} ${item.symbol}`
    : "0";
}

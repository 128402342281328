const primary = "#755EFC";
// const secondary = "#FF5C93";
const warning = "#FFC260";
// const success = "#3CD4A0";
// const info = "#9013FE";

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      // light: primary,
      // dark: primary,
      contrastText: "#ffffff"
    },
    // secondary: {
    //   main: '#000',
    //   light: '#000',
    //   dark: '#000'
    // },
    success: {
      main: "#32D583",
      contrastText: "#FFF",
    },
    warning: {
      main: warning
    },
    error: {
      main: "#E31B54",
    },
    addBoothBtnColor: {
      main: "#475467",
      dark: "#39404a",
      light: "#fff",
      contrastText: "#fff",
    },
    MessagesBoothBtnColor: {
      main: "#E4E7EC99",
      dark: "#bfc8d799",
      light: "#fff",
      contrastText: "#475467CC",
    },
    background: {
      main: "#E4E7EC",
      light: "#E4E7EC",
      dark: "#E4E7EC",
    },
    boxUiBackground: {
      main: "#fff",
    },
    inputColor: {
      main: "#000",
      light: "#000",
      dark: "#fff",
    },
    inputBackground: {
      main: "#F2F4F7",
      light: "#fff",
      dark: "#F2F4F7",
    },
    description: {
      main: "#98A2B3",
      light: "#F2F4F7",
      dark: "#F2F4F7",
      contrastText: "#fff",
    },
    buttonColor: {
      main: "#fff",
      light: "#fff",
      dark: "#343434",
    },
    headerBtnBackground: {
      main: "#fff",
      light: "#fff",
      dark: "#343434",
    },
    headerBtnColor: {
      main: "#000",
      light: "#000",
      dark: "#fff",
    },
    pageTitle: {
      main: "#344054",
      light: "#344054",
      dark: "#fff",
    },
    headerLine: {
      main: "#98A2B3",
      light: "#98A2B3",
      dark: "#fff",
    },
    tableBgColor: {
      main: "#fff",
      light: "#fff",
      dark: "#343434",
    },
    tableColor: {
      main: "#000",
      light: "#000",
      dark: "#fff",
    },
    ReplyBg: {
      main: "#F8F8F8",
      light: "#000",
      dark: "#fff",
    },
    borderColor: {
      main: "rgba(152, 162, 179, 0.3)",
      light: "rgba(152, 162, 179, 0.3)",
      dark: "rgba(152, 162, 179, 0.3)",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiTypography: {
      root: {},
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      // fontSize: "1.3rem",
    },
  },
  dir: localStorage.getItem("i18nextLng"),
};

export default defaultTheme;

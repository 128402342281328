import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  addFormWrapper: {
    marginTop: 20,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    gap: 30,
    "&[disabled]": {
      pointerEvents: "none",
      opacity: 0.5,
    },
  },
  header: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    fontSize: "14px !important",
    display: "flex",
    gap: 5,
    alignItems: "center",
  },
  limit: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  description: {
    fontSize: "12px !important",
    color: theme.palette.description.main,
  },
  filesWrapper: {
    textAlign: "center",
    gap: 20,
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  Switch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 30,
    fontSize: 14,
    color: theme.palette.description.main,
  },
  ImageTypeWarningWrapper: {
    fontSize: 12,
    border: `1px solid ${theme.palette.warning.main}`,
    background: `${theme.palette.warning.main}1a`,
    color: theme.palette.warning.main,
    alignItems: 'center',
    padding: 5,
    borderRadius: 10,
    display: 'flex',
    gap: 10,
    width: '100%',
    marginBottom: 20,
    marginTop: -20
  },
  ImageTypeWarning: {
    fontSize: 12,
    alignItems: 'center',
    padding: 5,
    borderRadius: 10,
    display: 'flex',
    gap: 10,
    width: '100%'
  }
}));

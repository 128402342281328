import { Box } from "@mui/material";
import moment from "moment";
import React from "react";
import UseCustomTranslate from "../../../../../../customHooks/hookRepositories/useCustomTranslate";
import { MainUrl } from "../../../../../../services/Api";
import useStyles from "./styles";

export default function ChatCard({ creator, lastMessage, onClick, adminUnreadMessage }) {
const tv = UseCustomTranslate("pages");
  const { avatar, fullName } = creator ? creator : { fullName: tv("deletedAccount") };
  const { createdAt, body } = lastMessage ? lastMessage : {}
  const classes = useStyles();
  return (
    <Box className={classes.cardWrapper} onClick={onClick} >
      <div className={classes.profileWrapper}>
        <div className={classes.profile}  >
          <img className={classes.img} alt="" src={avatar ? `${MainUrl}${avatar}` : "/assets/image/no_photo.png"} />
        </div>
        {adminUnreadMessage && <div className={classes.unread} ></div>}
      </div>
      <div className={classes.cardBody}>
        <div className={classes.header}>
          <div className={classes.name} >{fullName}</div>
          <div className={classes.date} >{moment(createdAt).format("HH:mm")}</div>
        </div>
        <div className={classes.massage}>{body}</div>
      </div>
    </Box>
  );
}

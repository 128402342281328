import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  layout: {
    backgroundColor: theme.palette.background.main,
    display: "flex",
    height: "100vh",
    overflow: "auto",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  root: {
    display: "flex !important",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "1328px !important",
    },
  },
  container: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    gap: 15,
  },
  sidebarContainer: {
    minWidth: 230,
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  mainContainer: {
    display: "flex",
    maxWidth: 1035,
    flexDirection: "column",
    flexGrow: 1,
    paddingBottom: 20,
  },
  bodyContainer: {
    // marginTop:86
  },
}));

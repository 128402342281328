import { Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFiles from "../../../customHooks/hookRepositories/useFiles";
import { showModal } from "../../../redux/ModalSlice";
import { ResponseStatus } from "../../../services/Api";
import Modal from "../../BaseElement/Modal/Modal";
import UploadPreviewUi from "../../BaseElement/UploadPreviewUi/UploadPreviewUi";
import TabPanel from "../components/TabPanel";
import useStyles from "./styles";

export default function EditModal(props) {
  //region--------------- states
  var classes = useStyles();
  const [value, setValue] = useState(0);
  const { open, lastModal, file, id } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (lastModal) {
      dispatch(showModal(lastModal));
    } else {
      dispatch(showModal({ open: false }));
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onCancel = () => {
    handleClose();
  };
  const { update } = useFiles();

  const onSubmit = (body) => {
    update({ id, body }).then((res) => {
      if (res.status === ResponseStatus.Success) {
        handleClose();
      }
    });
  };
  //endregion-------------- handlers
  return (
    <>
      <Modal
        title={"Open a file"}
        open={open === "EditModal"}
        onClose={handleClose}
      >
        <Tabs
          className={classes.root}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#755EFC",
              zIndex: 0,
              borderRadius: 20,
              height: 4,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="full width tabs example"
        >
          <Tab disableRipple label="Upload" />
        </Tabs>
        <TabPanel value={value} index={0} gap={5}>
          <Grid item xs={12} className={classes.upload}>
            <UploadPreviewUi
              preview={file}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          </Grid>
        </TabPanel>
      </Modal>
    </>
  );
}

import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router";
import RouteEnum from "../../navigation/RouteEnum";
import PerfectScrollbarUi from "../BaseElement/PerfectScrollbarUi/PerfectScrollbarUi";
import BoothBuyModal from "../Modals/BoothBuyModal/BoothBuyModal";
import BuyResponseModal from "../Modals/BuyResponseModal/BuyResponseModal";
import EditModal from "../Modals/EditModal/EditModal";
import FilesModal from "../Modals/FilesModal/FilesModal";
import TipsModal from "../Modals/TipsModal/TipsModal";
import UploadModal from "../Modals/UploadModal/UploadModal";
import Header from "./components/Header";
// import Footer from "./components/Footer";
import PanelMenu from "./components/PanelMenu/PanelMenu";
import { setRole } from "../../redux/RolesSlice";
import useStyles from "./styles";
import PreviewModal from "../Modals/PreviewModal/PreviewModal";
import MapViewModal from "../Modals/MapViewModal/MapViewModal";

export default function Layout({ children }) {
  var classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!("Notification" in window)) {
    } else {
      Notification.requestPermission();
    }
  }, []);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(RouteEnum.Login);
      dispatch(setRole({}));
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);
  return (
    <div className={classes.layout}>
      <PerfectScrollbarUi>
        <Container maxWidth="xl" classes={{ root: classes.root }}>
          <FilesModal />
          <UploadModal />
          <PreviewModal />
          <EditModal />
          <MapViewModal />
          <BuyResponseModal />
          <TipsModal />
          <BoothBuyModal />
          <Box className={classes.container}>
            <Box item className={classes.sidebarContainer}>
              <PanelMenu />
            </Box>
            <Box item className={classes.mainContainer}>
              <Header />
              <Box className={classes.bodyContainer}>
                <Outlet />
                {children}
              </Box>
            </Box>
          </Box>
          {/* <Footer/> */}
        </Container>
      </PerfectScrollbarUi>
    </div>
  );
}

import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.tableBgColor.main,
    flexGrow: 1,
    padding: "15px 20px",
    borderRadius: 20,
    position: "relative",
    gap: 15,
    maxWidth: 247,
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    fontSize: 40,
    overflow: "hidden",
    "& > img": {
      maxWidth: 40,
      maxHeight: 40,
      margin: "auto",
    },
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 16,
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
}));

import React, { useEffect, useState } from "react";
import AddForm from "../../../components/AddForm";
import { UploadFileType } from "../../../configs/FormEnum";
import * as moment from "moment";
import useGetConstant from "../../../customHooks/hookRepositories/useGetConstant";
import useAuth from "../../../customHooks/hookRepositories/useAuth";
import { useSelector } from "react-redux";
import useCustomTranslate from "../../../customHooks/hookRepositories/useCustomTranslate";

export default function BaseCrudEvent({ callback, data }) {
  const [formData, setFormData] = useState(data);
  const { getExhibitions } = useGetConstant();
  const { exhibitions } = useSelector((s) => s.constants);
  const tv = useCustomTranslate("forms");

  useEffect(() => {
    getExhibitions();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const form = [
    {
      col: {
        direction: "row",
        width: 6,
        fields: [
          {
            width: [4, 8],
            flexDirection: "row",
            valueId: "name",
            title: tv("eventName"),
            required: true,
            input: {
              placeholder: tv("eventNamePlaceholder"),
            },
          },
          {
            width: [4, 8],
            flexDirection: "row",
            valueId: "speakerName",
            title: tv("speakerName"),
            required: true,
            input: {
              placeholder: tv("speakerNamePlaceholder"),
            },
          },
          {
            width: [4, 8],
            flexDirection: "row",
            valueId: "link",
            title: tv("eventLink"),
            required: true,
            input: {
              placeholder: tv("eventLinkPlaceholder"),
            },
          },
          {
            width: [4, 8],
            flexDirection: "row",
            valueId: "dateUi",
            title: tv("date"),
            required: true,
            dateTimePicker: {
              placeholder: tv("datePlaceholder"),
            },
          },
          {
            width: [4, 8],
            flexDirection: "row",
            valueId: "exhibitionId",
            title: tv("exhibition"),
            required: true,
            select: {
              children: [{ name: tv("selectExhibition"), value: "0" }].concat(
                exhibitions,
              ),
            },
          },
        ],
      },
    },
    {
      col: {
        direction: "column",
        width: 6,
        fields: [
          {
            width: [2, 10],
            flexDirection: "column",
            valueId: "banner",
            title: tv("eventBanner"),
            subTitle: tv("eventBannerDesc"),
            required: true,
            uploader: {
              fullWidth: true,
              fileType: UploadFileType.image,
              placeholder: tv("dragFile"),
              description: `( ${tv("bestRatio")} w:800px, h:600px )`,
            },
          },
        ],
      },
    },
  ];

  const inputHandler = (val, type) => {
    const newValue = {};
    newValue[type] = val;
    if (type === "dateUi") {
      newValue["date"] = moment(val).format("YYYY-MM-DD");
      newValue["time"] = moment(val).format("HH:mm:ss");
    }
    setFormData({ ...formData, ...newValue });
  };
  const { isValid, getValidUrl } = useAuth();

  return (
    <div>
      <AddForm
        value={formData}
        form={form}
        title={tv("event")}
        onSubmit={(error) => {
          if (
            !isValid({
              date: { value: formData.dateUi, valueId: "dateUi" },
            }) ||
            error
          ) {
            return;
          }
    callback({
            ...formData,
            link: getValidUrl(formData.link),
          });
        }}
        onInputChange={inputHandler}
      />
    </div>
  );
}
